import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Bootstrap Vue
//import BootstrapVue from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
//import 'bootstrap/dist/css/bootstrap.css'
//import 'bootstrap-vue/dist/bootstrap-vue.css'


//import './styles/bootstrap.css'
import './styles/custom.css';


///ICONOS
import { library } from "@fortawesome/fontawesome-svg-core";

import { faBars, faHome, faWindowMaximize, faUsers, faCogs, faExternalLinkAlt, faCog, faChartLine, faPlus, faSignOutAlt, faVial, faUserCog, faBriefcase, faEdit, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFacebook, faStackOverflow, faGithub } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faTwitter, faFacebook, faStackOverflow, faGithub, faBars, faHome, faWindowMaximize, faUsers, faCogs, faExternalLinkAlt, faCog, faChartLine, faPlus, faSignOutAlt, faVial, faUserCog, faBriefcase, faEdit, faArrowLeft);
///ICONOS

const app = createApp(App);


app.component("font-awesome-icon", FontAwesomeIcon)//Metemos los iconos
// Make BootstrapVue available throughout your project
//app.use(BootstrapVue)
//app.use(IconsPlugin)

app.use(store).use(router).mount('#app')
