<template>
  <div v-show="datos.usuario_aceptado">
    <div class="cargando_div" v-show="datos.valido == 'proceso' && datos.plataforma == 'paypal'">
      <div>
        <img style="width: 300px; margin-top: 16px; margin-bottom: 8px;" src="../assets/payments/paypal-logo.svg">
      </div>
      <div style="text-align: center;margin-top: 60px" class="texto_info">
        <b>Procesando el pago</b>
        <br><br>
        Espere un momento...
      </div>
    </div>

    <div class="div_mensaje_informativo" v-bind:class="{ div_mensaje_informativo_oscuro: datos.modo_oscuro }" v-show="datos.valido == 'valido'">
      <b>¡Enhorabuena!</b>
      <br>
      <br>
      El pedido <b>{{datos.id_pedido}}</b> se ha realizado con éxito.
      <br>
      <br>
      La licencia puede tardar hasta 5 minutos en aparecer como activa. En caso de tener alguna licencia activa en este momento, aparecerá en la lista de licencias pendientes.
      <div style="margin-top: 40px;">
        <router-link :to="'/planconfig/'+datos.id_plan"><btn-primario texto="Continuar"></btn-primario></router-link>
      </div>
    </div>

    <div class="div_mensaje_informativo" v-bind:class="{ div_mensaje_informativo_oscuro: datos.modo_oscuro }" v-show="datos.valido == 'error'">
      <b>¡Error al procesar el pago!</b>
      <br>
      <br>
      El pedido <b>{{datos.id_pedido}}</b> no se ha podido procesar.
      <br>
      <br>
      Vuélvelo a intentar y si los errores persisten, ponte en contacto con nosotros a través de formulario de contacto.
      <div style="margin-top: 40px;">
        <router-link :to="'/planconfig/'+datos.id_plan"><btn-primario texto="Intentar de nuevo"></btn-primario></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import BtnPrimario from '../components/BtnPrimario'

export default {
  name: 'Pagos',
  components: {
    BtnPrimario
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    //Datos de inicio
    datos.id_plan = route.params.id;
    datos.id_pedido = route.params.pedido
    datos.plataforma = route.params.plataforma
    datos.valido = route.params.valido

    datos.token = route.query.token;
    datos.payerID = route.query.PayerID;


    console.log('id_plan', datos.id_plan);
    console.log('id_pedido', datos.id_pedido);
    console.log('plataforma', datos.plataforma);
    console.log('valido', datos.valido);
    console.log('token', datos.token);
    console.log('payerID', datos.payerID);

    datos.interval = null;
    datos.mostrar_menu = true;
    datos.mostrar_items_menu = false;


    datos.proyectos_caidos = [];
    datos.mostrar_cargando = true;


    datos.menu_padre_seleccioando = 'Confcuenta';




    onMounted(function (){
      store.dispatch("get_all_store",datos.id_plan); //Cargamos lo necesario
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    async procesar_pago() {
      return;

      if (this.datos.valido != 'proceso'){
        //TODO: MOSTRAR LO QUE SEA
        console.log('PAGADO!!');
        return false;
      }

      console.log('PAYPAL REVISADO PAGADO!!');

      let datos_enviar = {
        id_plan: this.datos.id_plan,
        id_pedido: this.datos.id_pedido,
        plataforma: this.datos.plataforma,
        valido: this.datos.valido,
        token: this.datos.token,
        payerID: this.datos.payerID,
      };
      console.log('PAGAR', datos_enviar)
      //return false;
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/paypal/procesar-pago/`, requestOptions);
      if (response.status != 200 && response.status != 201) {
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      console.log('RESPUWSTA', datos);
      if (datos.code != '00') {
        console.log(response);
        console.log(datos);
      } else {
        console.log('recibido', datos.data);
        let info_apago = datos.data;

        /*if (metodo_pago == 'paypal'){
        return false;
        }*/

        if (info_apago.url == 'reload'){
          location.reload();
        } else {
          window.location = info_apago.url;
        }


        //https://app.failder.com/pagos/21/paypal/valido/STZOWZURVU?token=41V55756969312633&PayerID=32D53MHUTSCMN
      }
    }
  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    //this.check_token();
    //console.log('Datos', this.datos);
    //this.get_resumen_plan();
    //this.get_proyectos_caidos();
    this.procesar_pago();
  },
}
</script>
<style lang="scss" scoped>
@import '../styles/variables.css';
.cargando_div{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 400px;
  /*height: 600px;*/
  max-width: 100%;
  max-height: 600px;
  /*background-color: red;*/
  text-align: center;
  background-color: var(--fondo-menu-claro-theme);
  border-style: solid;
  border-width: 5px;
  border-color: var(--primary-color-1);
  padding: 60px;
}
.cargando_div .texto_info{
  color: black;
}

.div_mensaje_informativo{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 400px;
  /*height: 600px;*/
  max-width: 100%;
  max-height: 800px;
  padding: 60px;
  text-align: center;
  background-color: var(--fondo-menu-claro-theme) !important;
}
.div_mensaje_informativo_oscuro{
  background-color: var(--fondo-menu-dark-theme) !important;
}
@media screen and (min-width: 992px){
  .cargando_div{
    left: calc(50% + 150px);
  }
  .div_mensaje_informativo{
    left: calc(50% + 150px);
  }
}
@media screen and (max-width: 991px){
  .cargando_div img{
    max-width: 100%;
  }
}
</style>
