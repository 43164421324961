<template>
  <div class="cartel_info">
    <router-link :to="url">
    <div class="sub_cartel_info" v-bind:class="{ sub_cartel_info_oscuro: datos.modo_oscuro }">
      <div class="contenedor">
        <div class="titulo">
          {{ titulo }}
        </div>
        <div class="contenedor_info">
          <div class="info">
            {{ mensaje }}
          </div>
        </div>
      </div>
      <div class="barra_color" :style="'background-color: '+ color"></div>
    </div>
    </router-link>
  </div>

</template>

<script>
import {useStore} from "vuex"
import {useRoute} from "vue-router";
import {computed, onMounted} from "vue";
export default {
  name: 'CartelDeInformacionLink',
  props: {
    titulo: String,
    mensaje: String,
    color: String,
    url: String
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    return {
      variables: variables,
      datos: datos,
    }
  }
}
</script>

<style scoped lang="scss">
.cartel_info{

  padding: 8px;


  /*-webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);*/

}
.sub_cartel_info{
  padding: 8px;
  margin: 16px;
  border-color: var(--primary-color-1);
  background-color: var(--fondo-menu-claro-theme);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-between;
  align-items: stretch;
  border-radius: 4px;

  border-width: 1px;
  border-style: solid;
  max-height: 100%;
  height: -webkit-fill-available;
}
.sub_cartel_info_oscuro{
  background-color: var(--fondo-menu-dark-theme)!important;
}
.cartel_info .barra_color{
  width: 6px;
  background-color: #59f37b;
  margin-left: 20px;
  border-radius: 50px;
}
.cartel_info .titulo{
  font-weight: bold;
}
.cartel_info .info{

}
.cartel_info .contenedor{
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
}
.contenedor .contenedor_info{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: space-around;
  height: 100%;
}

@media screen and (min-width: 992px){
  .cartel_info{
    width: 33%;
  }
}
@media screen and (min-width: 1300px){
  .cartel_info{
    width: 25%;
  }
}
@media screen and (min-width: 1600px){
  .cartel_info{
    width: 20%;
  }
}
@media screen and (max-width: 991px){
  .cartel_info{
    width: 50%;
  }
}
@media screen and (max-width: 660px){
  .cartel_info{
    width: 100%;
  }
}
</style>
