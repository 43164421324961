<template>
  <router-link :to="'/dashboard/'+ id">
    <div class="item_cuenta" v-bind:class="{ item_cuenta_oscuro: datos.modo_oscuro }">
      <div>
        {{ nombre }}
      </div>
      <div>
        {{ proyectos_activos }} proyectos
      </div>
    </div>
  </router-link>
</template>



<script>
import {useStore} from "vuex"
import BtnPrimario from '../components/BtnPrimario'
import {useRoute} from "vue-router";
import {computed, onMounted} from "vue";
export default {
  name: 'ItemCuentaCliente',
  components:{
    BtnPrimario
  },
  props: {
    nombre: String,
    id: Number,
    proyectos_activos: Number,
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
  },

  /**
   * {
"id": 1,
"nombre_interno": "A9edEa",
"nombre": "Atenea Systems",
"tipo_licencia_activa": 4,
"sms_total": 100,
"sms_disponibles": 100,
"proyectos_total": 30,
"proyectos_disponibles": 30,
"fecha_caducidad": "2021-08-17T22:00:00.000Z",
"fecha_modificado": "2021-08-06T14:29:06.000Z",
"fecha_creado": "2021-08-06T14:29:06.000Z",
"estado": 1
}
   * */
}
</script>

<style scoped lang="scss">
.item_cuenta{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
}
.item_cuenta:hover{
  background-color: var(--color-texto-principal-dark-theme);
}
.item_cuenta_oscuro:hover{
  background-color: var(--color-texto-principal-claro)!important;
}
</style>
