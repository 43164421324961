<template>
  <div v-show="datos.usuario_aceptado">
    <div class="barra_superior" v-bind:class="{ barra_superior_oscuro: datos.modo_oscuro }">
      <div>
        <div v-show="!datos.modo_oscuro"><img alt="Failder" src="../assets/LOGO_Blue-Black.png"> </div>
        <div v-show="datos.modo_oscuro"><img alt="Failder" src="../assets/LOGO_White.png"> </div>
      </div>
      <div>
        {{ datos.usuario.email }}
      </div>
    </div>
  </div>
  <div v-show="datos.usuario_aceptado" class="contenedor_cuentas">
    <div v-show="datos.mostrar_cargando" class="cargando_div">
      <div>
        <img style="width: 60px; margin-top: 16px; margin-bottom: 8px;" src="../assets/loading.svg">
      </div>
      <div>
        Cargando...
      </div>
    </div>
    <div class="panel" v-bind:class="{ panel_oscuro: datos.modo_oscuro }" v-show="!datos.mostrar_cargando">
        <div style="    font-size: 20px;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px;
    text-align: left;
    font-weight: bold;
}">
          Usa Failder como...
        </div>
      <ItemCuentaCliente v-for="item in datos.plans"
                         v-bind:nombre="item.info_cuenta.nombre"
                         v-bind:proyectos_activos="item.resumen.proyectos_activos"
                         v-bind:id="item.info_cuenta.id"></ItemCuentaCliente>
      <a href="/createplan" style="width: 100%;">
      <div class="opciones_grandes" v-bind:class="{ opciones_grandes_oscuro: datos.modo_oscuro }">
        Crear cuenta
      </div>
      </a>
      <div class="opciones_grandes" v-bind:class="{ opciones_grandes_oscuro: datos.modo_oscuro }" @click.native="cerrar_sesion()">
        Cambiar de cuenta de Failder
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import PlansComponent from '../components/Plans'
import ItemCuentaCliente from '../components/ItemCuentaCliente'
import BtnPlusFlotante from "../components/BtnPlusFlotante";
import Cargando from "../components/Cargando";

export default {
  name: 'SelectAccount',
  components: {
    PlansComponent,
    BtnPlusFlotante,
    Cargando,
    ItemCuentaCliente
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    //Datos de inicio
    datos.plans = [];
    datos.mostrar_menu = false;
    datos.mostrar_cargando = true;



    onMounted(function (){
      //console.log('Variable global',datos);
      console.log('aa',datos.usuario_aceptado)
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    cerrar_sesion(){
      let thisStore = this.$store;
      localStorage.removeItem('token');
      thisStore.dispatch('goTo', `/`);
      return false;
    },
    async get_plans(){
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/plans/`, requestOptions);
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        let plans = [];
        let plans_temp = datos.data;

        if (!plans_temp){
          let thisStore = this.$store;
          thisStore.dispatch('goTo', `/createplan`);

          return false;
        }

        let cantidad_plans = plans_temp.length;
        for (let a = 0; a < cantidad_plans; a++){
          let item = plans_temp[a];

          if (item.info_tipo_licencia == undefined) item.info_tipo_licencia = {};
          if (item.info_tipo_licencia.nombre == undefined) item.info_tipo_licencia.nombre = '--';

          console.log('Item', item);
          plans.push(item);
        }
        this.datos.mostrar_cargando = false;
        this.datos.plans = plans;
        console.log('Plans', this.datos.plans);
      }

    },
    comprobar_token(){
      console.log('entra');
      let thisStore = this.$store;
      if (localStorage.token == undefined) {
        console.log('entra 2');
        thisStore.dispatch('goTo', `/`);
        return false;
      }
    }
  },
  mounted() {
    this.comprobar_token();
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    //this.check_token();
    console.log('Datos', this.datos);
    this.get_plans();
  },
}
</script>
<style lang="scss" scoped>
@import '../styles/variables.css';
.opciones_grandes{
  text-align: center;
  width: 100%;
  font-size: 22px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top-style: solid;
  border-width: 1px;
  cursor: pointer;
}

.opciones_grandes:hover{
  background-color: var(--color-texto-principal-dark-theme);
}
.opciones_grandes_oscuro:hover{
  background-color: var(--color-texto-principal-claro)!important;
}

.contenedor_cuentas{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  overflow-y: auto;
}
.contenedor_cuentas .panel{
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: space-around;
  align-items: stretch;
  width: 40%;
  background-color: var(--fondo-menu-claro-theme);
  padding-top: 16px;
  /*padding-bottom: 16px;*/
  border-style: solid;
  border-width: 1px;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.75);
}

.contenedor_cuentas .panel_oscuro{
  background-color: var(--fondo-menu-dark-theme)!important;
}
@media screen and (max-width: 991px){
  .contenedor_cuentas .panel{
    width: 70%;
  }
  .contenedor_cuentas{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 50vh;
    max-height: 98vh;
    overflow-y: auto;
    padding-top: 80px;
  }
}
@media screen and (max-width: 660px){
  .contenedor_cuentas .panel{
    width: 90%;
  }
}


.barra_superior{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: var(--fondo-menu-claro-theme);
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
  position: fixed;
  width: 100%;
}
.barra_superior img{
  height: 46px;
}
.barra_superior_oscuro{
  background-color: var(--fondo-menu-dark-theme)!important;
}
@media screen and (max-width: 660px){
  .barra_superior img{
    height: 30px;
  }
}

.contenedor_carteles{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
}
.cartel_info{
  margin: 16px;
  padding: 16px;
  background-color: #FFF;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: stretch;
  border-radius: 4px;
  border-color: var(--primary-color-1);
  border-width: 1px;
  border-style: solid;
  /*-webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);*/
}
.cartel_info .barra_color{
  width: 6px;
  background-color: #59f37b;
  margin-left: 20px;
  border-radius: 50px;
}
.cartel_info .titulo{
  font-weight: bold;
}
.cartel_info .info{

}
.cargando_div{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  /*background-color: red;*/
  text-align: center;
}
</style>
