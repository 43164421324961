<template>
  <div v-show="datos.usuario_aceptado" class="contenedor" v-bind:class="{ contenedor_oscuro: datos.modo_oscuro }">
    <div class="asistente">
      <div class="paso1" v-show="datos.paso_asistente == '1'">
        <div class="item_form">
          <div class="titulo">
            ¡Hola!
          </div>
          <div class="explicacion">
            Este es el asistente que te ayudará en todo el proceso de creación de una nueva cuenta.<br>
            Este proceso sólo te llevará un par de minutos.
            <br><br>
            Cuando estés preparado, pulsa en "Siguiente" para empezar.

          </div>
          <!--<div class="input">
            <input type="text" placeholder="https://google.es/" v-model="datos.url_proyecto" v-on:keyup="validate_form" required >
          </div>-->
        </div>
      </div>
      <div class="paso2" v-show="datos.paso_asistente == '2'">
        <div class="item_form">
          <div class="titulo">
            URL a consultar
          </div>
          <div class="explicacion">
            Empezaremos creando el primer proyecto para monitorear.<br>
            En Failder, queremos que seas el primero en saber si tu sitio web ha dejado de funcionar o la navegación no es fluida.<br>
            Para ello necesitamos que escribas el nombre de tu proyecto y la URL de tu sitio web o API REST que quieres monitorear.

          </div>
          <div class="input">
            <input type="text" placeholder="Mi proyecto" v-model="datos.nombre_proyecto" v-on:keyup="validate_form" required >
          </div>
          <div class="input">
            <input type="url" placeholder="https://google.es/" v-model="datos.url_nuevo_plan" v-on:keyup="validate_form" required >
          </div>
        </div>
      </div>
      <div class="paso3" v-show="datos.paso_asistente == '3'">
        <div class="item_form">
          <div class="titulo">
            Comprobando URL
          </div>
          <div class="explicacion">
            Estamos comprobando la url. <br>
            Espera unos segundos...
            <div style="width: 100%;text-align: center;" v-show="datos.mostrar_cargador">
              <img style="width: 30px; margin-top: 16px; margin-bottom: 8px;" src="../assets/loading.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="paso4" v-show="datos.paso_asistente == '4'">
        <div class="item_form">
          <div class="titulo">
            Monitoreo de palabras clave
          </div>
          <div class="explicacion">
            Utilizamos la supervisión de palabras clave para comprobar la presencia o ausencia de texto específico en el cuerpo de la respuesta de la solicitud (normalmente HTML o JSON).
            <br>
            <br>
            En caso de ser un sitio web, te recomendamos que busques palabras que sólo se encuentren en el footer (pie de página). De esta manera, nos aseguramos que estamos cargando todo el HTML hasta ese punto. En el footer es común encontrar palabras únicas como "Mapa web", "Sitio web", "Política de Privacidad" o "Copyright". También puedes utilizar clases usadas en las etiquetas HTML o incluso las propias etiquetas HTML como "&lt;/footer&gt;", "&lt;/body&gt;" o "&lt;/html&gt;".

          </div>
          <div class="input">
            <input type="text" placeholder="</html>" v-model="datos.buscar_proyecto" v-on:keyup="validate_form" required >
          </div>
        </div>
      </div>
      <div class="paso5" v-show="datos.paso_asistente == '5'">
        <div class="item_form">
          <div class="titulo">
            Monitoreo de palabras clave
          </div>
          <div class="explicacion">
            Buscando la palabra clave en la web...
            <div style="width: 100%;text-align: center;" v-show="datos.mostrar_cargador">
              <img style="width: 30px; margin-top: 16px; margin-bottom: 8px;" src="../assets/loading.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="paso6" v-show="datos.paso_asistente == '6'">
        <div class="item_form">
          <div class="titulo">
            Crea tu primer contacto
          </div>
          <div class="explicacion">
            Nuestra misión es que seas el primero en saber si tu sitio web ha dejado de funcionar correctamente. Para ello utilizamos diferentes vías de comunicación como SMS*, Email o Notificaciones Push**. Más adelante, en el apartado de contactos, podrás crear más contactos.
            <br>
            <br>
            * Los SMS no están disponibles para licencias gratuítas.<br>
            ** Las notificaciones push estarán disponibles próximamente en cualquier tipo de licencia.

          </div>
          <div class="input">
            <div>
              Nombre
            </div>
            <div>
              <small>(Será el nombre para identificar el contacto en tu lista de contactos)</small>
            </div>
            <div>
              <input type="text" placeholder="Nombre de usuario" v-model="datos.primer_contacto_nombre" v-on:keyup="validate_form" required >
            </div>
          </div>
          <div class="input">
            <div>
              Teléfono
            </div>
            <div>
              <small>(Para que funcione correctamente el servicio, debes seleccionar el país donde está registrado el número de teléfono.)</small>
            </div>
            <div>
              <select v-model="datos.prefijo_pais_seleccionado" @change="onChange_pais()">
                <option v-for="item in datos.prefijos_paises" :value="item.value">
                  {{ item.text }}
                </option>
              </select>
            </div>
            <div style="    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;">
              <div v-show="(datos.codigo_tel_pais_selecionado > 0)" style="display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    padding-right: 10px;">
                <div>
                  <img style="width: 30px; padding-right: 10px;" :src="'/imagenes/banderas/'+datos.codigo_texto_pais_seleccionado+'.png'" alt="datos.codigo_texto_pais_seleccionado">
                </div>
                <div>
                  +{{datos.codigo_tel_pais_selecionado}}
                </div>
              </div>
              <div style="width: 100%;">
                <input type="tel" placeholder="Teléfono de usuario" v-model="datos.primer_contacto_movil" v-on:keyup="validate_form" required >
              </div>
            </div>
          </div>
          <div class="input">
            <div>
              E-mail
            </div>
            <div>
              <small>(La dirección de correo electrónico indicada deberá ser válida para recibir las notificaciones)</small>
            </div>
            <div>
              <input type="email" placeholder="E-mail de usuario" v-model="datos.primer_contacto_email" v-on:keyup="validate_form" required >
            </div>
          </div>
        </div>
      </div>
      <div class="paso7" v-show="datos.paso_asistente == '7'">
        <div class="item_form">
          <div class="titulo">
            Asistente
          </div>
          <div class="explicacion">
            Ya estamos terminando la configuración. Espera mientras el sistema termina con la configuración...
            <div style="width: 100%;text-align: center;" v-show="datos.mostrar_cargador">
              <img style="width: 30px; margin-top: 16px; margin-bottom: 8px;" src="../assets/loading.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="paso9" v-show="datos.paso_asistente == '9'">
        <div class="item_form">
          <div class="titulo">
            Asistente
          </div>
          <div class="explicacion">
            Escribe un nombre a este proyecto.
          </div>
          <div class="input">
            <input type="text" placeholder="Web de Alberto" v-model="datos.nombre_proyecto" v-on:keyup="validate_form" required >
          </div>
        </div>
      </div>
      <div class="paso10" v-show="datos.paso_asistente == '10'">
        <div class="item_form">
          <div class="titulo">
            Guardando
          </div>
          <div class="explicacion">
            Estamos guardando la información del nuevo proyecto...
            <div style="width: 100%;text-align: center;" v-show="datos.mostrar_cargador">
              <img style="width: 30px; margin-top: 16px; margin-bottom: 8px;" src="../assets/loading.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="alert" v-show="datos.mensaje_error!=''" v-bind:class="{ 'alert-oscuro': datos.modo_oscuro }">
        <!--<span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>-->
        {{datos.mensaje_error}}
      </div>
      <div class="alert alert_verde" v-show="datos.mensaje_correcto!=''" v-bind:class="{ 'alert-oscuro': datos.modo_oscuro }">
        <!--<span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>-->
        {{datos.mensaje_correcto}}
      </div>
      <div class="botonera">
        <btn-primario v-show="datos.paso_asistente > 1 && datos.mostrar_btn_atras" texto="Atrás" @click.native="asistente(-1)"></btn-primario>
        <btn-primario v-show="datos.mostrar_btn_siguiente" texto="Siguiente" @click.native="asistente(1)"></btn-primario>
        <btn-primario v-show="datos.mostrar_btn_finalizar" texto="Finalizar" @click.native="finalizar()"></btn-primario>
        <!--<btn-primario v-show="datos.paso_asistente == 4" texto="Guardar" @click.native="asistente(1)"></btn-primario>-->
        <!--<btn-primario v-show="datos.check_correcto"  texto="Comprobar" @click.native="comprobar_todo()"></btn-primario>-->
        <!--<btn-primario v-show="datos.check_correcto" texto="Guardar" @click.native="siguiente()"></btn-primario>-->
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import BtnPrimario from '../components/BtnPrimario'
import BtnPrimarioNegativo from '../components/BtnPrimarioNegativo'

export default {
  name: 'CreatePlan',
  components: {
    BtnPrimario,
    BtnPrimarioNegativo,
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    //Datos de inicio
    datos.plans = [];
    datos.mostrar_menu = false;
    datos.paso_asistente = 1;
    datos.mostrar_btn_siguiente = true;
    datos.mostrar_cargador = false;
    datos.url_nuevo_plan = 'https://google.es';
    datos.buscar_proyecto = ''
    datos.mensaje_correcto = '';
    datos.mensaje_error = '';
    datos.mostrar_btn_finalizar = false;
    datos.primer_contacto_nombre = '';
    datos.primer_contacto_email = '';
    datos.primer_contacto_movil = '';
    datos.mostrar_btn_atras = true;
    datos.id_nueva_cuenta = '';
    datos.nombre_proyecto = 'Mi primer proyecto'

    datos.prefijos_paises = [];
    datos.prefijos_paises_completo_por_codigo = [];
    datos.prefijo_pais_seleccionado = 0;
    datos.codigo_tel_pais_selecionado = 0;
    datos.codigo_texto_pais_seleccionado = '';

    datos.array_palabras_buscar = ['</html>', '</body>'];

    onMounted(function (){
      //console.log('Variable global',datos);

    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    finalizar(){
      let thisStore = this.$store;
      thisStore.dispatch('goTo',`/dashboard/${this.datos.id_nueva_cuenta}`);
    },
    asistente(sumar_restar){
      if (this.datos.paso_asistente == 6 && sumar_restar > 0){
        this.datos.primer_contacto_movil = this.datos.primer_contacto_movil.trim();
        this.datos.primer_contacto_movil = this.datos.primer_contacto_movil.replace(/\s+/g, "");
        if (this.datos.codigo_tel_pais_selecionado == 0 && this.datos.primer_contacto_movil != ''){
          this.datos.mensaje_error = 'Selecciona el país';
          return false;
        }
      }
      this.datos.mostrar_btn_finalizar = false;
      this.datos.mensaje_correcto = '';
      this.datos.mostrar_btn_siguiente = true
      this.datos.mensaje_error = '';
      this.datos.paso_asistente = this.datos.paso_asistente+sumar_restar;
      if(this.datos.paso_asistente == 3){
        if (this.datos.url_nuevo_plan == ''){
          this.datos.paso_asistente--;
          this.datos.mensaje_error = 'No se ha insertado una url válida';
        } else {
          //this.comprobar_url();
          this.comprobar_url_y_lista_palabras();
        }
      }

      if (this.datos.paso_asistente == 4 && sumar_restar > 0 && this.datos.buscar_proyecto != ''){
        this.datos.paso_asistente++;
        this.datos.paso_asistente++;
        return false;
      }

      if(this.datos.paso_asistente == 5){
        if(this.datos.buscar_proyecto == ''){
          this.datos.mensaje_error = 'No se ha insertado una palabra clave';
        } else {
          this.comprobar_url_y_palabra_clave();
        }
      }
      if(this.datos.paso_asistente == 6){
        if (this.datos.usuario.nombre != '' && this.datos.usuario.nombre != undefined){
          this.datos.primer_contacto_nombre = `${this.datos.usuario.nombre} ${this.datos.usuario.apellidos}`;
          this.datos.primer_contacto_email = `${this.datos.usuario.email}`;
        }
      }

      if(this.datos.paso_asistente == 7){
        this.finalizar_configuracion();
      }
      /*if (this.datos.paso_asistente == 3){
        this.datos.mostrar_btn_siguiente = false;
        this.comprobar_todo();
      } else {
        this.datos.mostrar_btn_siguiente = true;
      }
      if (this.datos.paso_asistente == 4){
        this.datos.mostrar_btn_siguiente = false;
      }
      if (this.datos.paso_asistente == 5){
        this.guardar();
      }*/
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    finalizar_configuracion(){
      this.datos.mostrar_cargador = true;
      this.datos.mostrar_btn_siguiente = false
      this.datos.mostrar_btn_atras = false;
      this.crear_cuenta();
    },
    async crear_proyecto() {
      this.datos.mensaje_correcto = 'Creando primer proyecto...'
      this.datos.mostrar_cargador = true;
      await this.sleep(900);

      let datos_enviar = {
        "url": this.datos.url_nuevo_plan,
        "nombre_proyecto": this.datos.nombre_proyecto,
        "clave": this.datos.buscar_proyecto,
        "id_nueva_cuenta":this.datos.id_nueva_cuenta
      }
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/createplan/crear_primer_proyecto`, requestOptions);
      if (response.status != 200){
        this.datos.mensaje_error = 'ERROR';
        console.log('ERROR', response.status);
        console.log('data', response);
        this.datos.mostrar_btn_atras = true;
        return false;
      }
      const datos = await response.json();
      console.log('datos web', datos);
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        this.datos.mensaje_error = 'Error en el servicio. Inténtalo de nuevo';
        this.datos.mostrar_btn_atras = true;
      } else {
        this.datos.mostrar_btn_atras = false;
        this.datos.mostrar_btn_finalizar = true;
        this.datos.mostrar_cargador = false;
        this.datos.mensaje_correcto = '¡Proceso finalizado!'
      }
    },
    async crear_contacto() {
      this.datos.mensaje_correcto = 'Creando primer contacto...'
      this.datos.mostrar_cargador = true;
      await this.sleep(900);

      let numero_telefono = '';
      if (this.datos.primer_contacto_movil != '' && this.datos.codigo_tel_pais_selecionado > 0){
        numero_telefono = `${this.datos.codigo_tel_pais_selecionado}${this.datos.primer_contacto_movil}`;
      }
      let datos_enviar = {
        "nombre_contacto": this.datos.primer_contacto_nombre,
        "email_contacto": this.datos.primer_contacto_email,
        "movil_contacto": numero_telefono,
        "id_nueva_cuenta":this.datos.id_nueva_cuenta
      }
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/createplan/crear_primer_contacto`, requestOptions);
      if (response.status != 200){
        this.datos.mensaje_error = 'ERROR';
        console.log('ERROR', response.status);
        console.log('data', response);
        this.datos.mostrar_btn_atras = true;
        return false;
      }
      const datos = await response.json();
      console.log('datos web', datos);
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        this.datos.mensaje_error = 'Error en el servicio. Inténtalo de nuevo';
        this.datos.mostrar_btn_atras = true;
      } else {
        this.crear_proyecto();
      }
    },
    async crear_licencia_gratuita() {
      this.datos.mensaje_correcto = 'Creando licencia gratuita...'
      this.datos.mostrar_cargador = true;
      await this.sleep(900);
      let datos_enviar = {
        id_nueva_cuenta: this.datos.id_nueva_cuenta,
      }
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/createplan/crear_licencia_gratuita`, requestOptions);
      this.datos.mostrar_cargador = false;
      if (response.status != 200){
        this.datos.mensaje_error = 'ERROR';
        console.log('ERROR', response.status);
        console.log('data', response);
        this.datos.mostrar_btn_atras = true;
        return false;
      }
      const datos = await response.json();
      console.log('datos web', datos);
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        this.datos.mensaje_error = 'Error en el servicio. Inténtalo de nuevo';
        this.datos.mostrar_btn_atras = true;
      } else {
        this.crear_contacto();
        }

      },
    async crear_cuenta() {
    this.datos.mensaje_correcto = 'Creando cuenta...'
    this.datos.mostrar_cargador = true;
    await this.sleep(900);
    const requestOptions = {
      method: "POST",
      headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
    };
    const response = await fetch(`${this.datos.url_host}/createplan/crear_cuenta`, requestOptions);
    if (response.status != 200){
      this.datos.mensaje_error = 'ERROR';
      console.log('ERROR', response.status);
      console.log('data', response);
      this.datos.mostrar_btn_atras = true;
      return false;
    }
    const datos = await response.json();
    console.log('datos web', datos);
    if (datos.code != '00'){
      console.log(response);
      console.log(datos);
      this.datos.mensaje_error = 'Error en el servicio. Inténtalo de nuevo';
      this.datos.mostrar_btn_atras = true;
    } else {
      const data = datos.data;
      console.log('dataaaaa', data);
      this.datos.id_nueva_cuenta = data;
      this.crear_licencia_gratuita();
    }
  },
    async comprobar_url_y_lista_palabras() {
      this.datos.mostrar_btn_siguiente = false
      this.datos.mostrar_cargador = true;
      await this.sleep(900);
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token, "url":this.datos.url_nuevo_plan},
      };
      const response = await fetch(`${this.datos.url_host}/check/url`, requestOptions);
      this.datos.mostrar_cargador = false;
      if (response.status != 200){
        this.datos.mensaje_error = 'ERROR';
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      console.log('datos web', datos);
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        this.datos.mensaje_error = 'Error en el servicio. Inténtalo de nuevo';
      } else {
        const data = datos.data.resultado;
        //console.log('Result', data);
        if(data.codeError != undefined || data.textError != undefined){
          //TODO: Servidor caido
          let mensaje_error_mostrar = '';
          if (data.codeError != undefined){
            mensaje_error_mostrar += `[Error code ${data.codeError}] `;
          }
          if (data.textError != undefined){
            if(mensaje_error_mostrar == ''){
              mensaje_error_mostrar = 'Error: ';
            }
            mensaje_error_mostrar += `${data.textError}`;
          }
          let res = mensaje_error_mostrar.indexOf('ENOTFOUND');
          if (res > -1){
            mensaje_error_mostrar = `Error al conectar con la URL ${this.datos.url_nuevo_plan}. Comprueba que la URL es correcta.`
          }
          //this.mostrar_mensaje_error(`Error ${data.codeError}, ${data.textError}`);
          //this.datos.check_correcto = false;
          this.datos.mensaje_error = mensaje_error_mostrar;
          this.datos.paso_asistente--;
          this.datos.mostrar_btn_siguiente = true
          return false;
        } else {
          let res = -1;
          let cantidad_palabras_a_buscar = this.datos.array_palabras_buscar.length;

          for (let a = 0; a < cantidad_palabras_a_buscar; a++){
            if (res > -1){
              continue;
            }

            const palabra_buscar = this.datos.array_palabras_buscar[a];

            try{
              res = data.indexOf(palabra_buscar);
            } catch (e){
              //this.borrar_alertas();
              console.log('ERROR CUBEL');
              console.log(e);
              console.log('WEB', this.datos.url_proyecto);
              console.log('Respuesta', datos);
              console.log('Buscando', palabra_buscar);
            }

            if (res > -1){
              this.datos.mensaje_correcto = '¡La URL es válida!'
              this.datos.buscar_proyecto = palabra_buscar;
            }
          }

          if (res == -1){
            this.datos.mensaje_correcto = '¡La URL es válida!'
          }
          this.datos.mostrar_btn_siguiente = true
        }

      }
    },
    async comprobar_url() {
      this.datos.mostrar_cargador = true;
      this.datos.mostrar_btn_siguiente = false
      await this.sleep(900);
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token, "url":this.datos.url_nuevo_plan},
      };
      const response = await fetch(`${this.datos.url_host}/check/url`, requestOptions);
      this.datos.mostrar_cargador = false;
      if (response.status != 200){
        this.datos.mensaje_error = 'ERROR';
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      console.log('datos web', datos);
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        this.datos.mensaje_error = 'Error en el servicio. Inténtalo de nuevo';
      } else {
        const data = datos.data.resultado;
        //console.log('Result', data);
        if(data.codeError != undefined || data.textError != undefined){
          //TODO: Servidor caido
          let mensaje_error_mostrar = '';
          if (data.codeError != undefined){
            mensaje_error_mostrar += `[Error code ${data.codeError}] `;
          }
          if (data.textError != undefined){
            if(mensaje_error_mostrar == ''){
              mensaje_error_mostrar = 'Error: ';
            }
            mensaje_error_mostrar += `${data.textError}`;
          }
          let res = mensaje_error_mostrar.indexOf('ENOTFOUND');
          if (res > -1){
            mensaje_error_mostrar = `Error al conectar con la URL ${this.datos.url_nuevo_plan}. Comprueba que la URL es correcta.`
          }
          //this.mostrar_mensaje_error(`Error ${data.codeError}, ${data.textError}`);
          //this.datos.check_correcto = false;
          this.datos.mensaje_error = mensaje_error_mostrar;
          this.datos.paso_asistente--;
          this.datos.mostrar_btn_siguiente = true
          return false;
        } else {
          this.datos.mensaje_correcto = '¡La URL es válida!'
          //await this.sleep(600)
          //this.datos.paso_asistente++;
          //this.datos.mensaje_correcto = '';
          this.datos.mostrar_btn_siguiente = true;
        }

      }
    },
    async comprobar_url_y_palabra_clave() {
      this.datos.mostrar_btn_siguiente = false
      this.datos.mostrar_cargador = true;
      await this.sleep(900);
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token, "url":this.datos.url_nuevo_plan},
      };
      const response = await fetch(`${this.datos.url_host}/check/url`, requestOptions);
      this.datos.mostrar_cargador = false;
      if (response.status != 200){
        this.datos.mensaje_error = 'ERROR';
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      console.log('datos web', datos);
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        this.datos.mensaje_error = 'Error en el servicio. Inténtalo de nuevo';
      } else {
        const data = datos.data.resultado;
        //console.log('Result', data);
        if(data.codeError != undefined || data.textError != undefined){
          //TODO: Servidor caido
          let mensaje_error_mostrar = '';
          if (data.codeError != undefined){
            mensaje_error_mostrar += `[Error code ${data.codeError}] `;
          }
          if (data.textError != undefined){
            if(mensaje_error_mostrar == ''){
              mensaje_error_mostrar = 'Error: ';
            }
            mensaje_error_mostrar += `${data.textError}`;
          }
          let res = mensaje_error_mostrar.indexOf('ENOTFOUND');
          if (res > -1){
            mensaje_error_mostrar = `Error al conectar con la URL ${this.datos.url_nuevo_plan}. Comprueba que la URL es correcta.`
          }
          //this.mostrar_mensaje_error(`Error ${data.codeError}, ${data.textError}`);
          //this.datos.check_correcto = false;
          this.datos.mensaje_error = mensaje_error_mostrar;
          this.datos.paso_asistente--;
          this.datos.mostrar_btn_siguiente = true
          return false;
        } else {
          let res = -1;
          try{
            res = data.indexOf(this.datos.buscar_proyecto);
          } catch (e){
            //this.borrar_alertas();
            console.log('ERROR CUBEL');
            console.log(e);
            console.log('WEB', this.datos.url_proyecto);
            console.log('Respuesta', datos);
            console.log('Buscando', this.datos.buscar_proyecto);
            //this.mostrar_mensaje_error(`Error, intentalo de nuevo`);
            this.datos.mensaje_error = `Error, intentalo de nuevo`;
            this.datos.paso_asistente--;
            this.datos.mostrar_btn_siguiente = true
            return false;
          }

          if (res > -1){
            this.datos.mensaje_correcto = `¡Palabra clave encontrada!`;
          } else {
            this.datos.mensaje_error = `No se ha encontrado "${this.datos.buscar_proyecto}" en la respuesta.`;
            this.datos.paso_asistente--;
            this.datos.mostrar_btn_siguiente = true
            return false;
          }


          //await this.sleep(600)
          //this.datos.paso_asistente++;
          //this.datos.mensaje_correcto = '';
          this.datos.mostrar_btn_siguiente = true
        }

      }
    },
    onChange_pais(){
      if(this.datos.prefijo_pais_seleccionado > 0){
        const item = this.datos.prefijos_paises_completo_por_codigo[this.datos.prefijo_pais_seleccionado];
        this.datos.codigo_tel_pais_selecionado = item.phonecode;
        this.datos.codigo_texto_pais_seleccionado = item.iso.toLowerCase();
      }
    },
    async get_prefijos_paises() {
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/account/prefijos_lista_paises`, requestOptions);
      this.datos.mostrar_cargador = false;
      if (response.status != 200){
        this.datos.mensaje_error = 'ERROR';
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      console.log('datos web', datos);
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        this.datos.mensaje_error = 'Error en el servicio. Inténtalo de nuevo';
      } else {
        const data = datos.data;
        //console.log('Result', data);
        this.datos.prefijo_pais_seleccionado = 0;
        this.datos.prefijos_paises = [];
        this.datos.prefijos_paises_completo_por_codigo = [];
        this.datos.prefijos_paises.push({value:0,text:'Selecciona el país'});

        const cantidad_datos = data.length;
        for (let a = 0; a < cantidad_datos; a++){
          const item = data[a];
          this.datos.prefijos_paises_completo_por_codigo[item.phonecode] = item;
          //this.datos.prefijos_paises.push({value:item.iso.toLowerCase(),text:item.nicename, codigo_tel: item.phonecode});
          this.datos.prefijos_paises.push({value:item.phonecode,text:item.nicename, codigo_tel: item.phonecode});
        }


      }
    },

  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    this.get_prefijos_paises();
  },
}
</script>
<style lang="scss" scoped>
@import '../styles/variables.css';
.link_registro{
  cursor:pointer;
  color: var(--primary-color-1);
}

.contenedor{
  background-color: var(--fondo-claro-theme);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.asistente{
  background-color: var(--fondo-menu-claro-theme);
  width: 46%;
}
.contenedor_oscuro .asistente{
  background-color: var(--fondo-menu-dark-theme);
}
.asistente .botonera{
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: flex-end;
  align-items: center;
  margin-top: 36px;
  margin-right: 16px;
  margin-bottom: 16px;
}
.contenedor_oscuro{
  background-color: var(--fondo-dark-theme);
}
.contenedor_oscuro .asistente{
  background-color: var(--fondo-menu-dark-theme);
}
@media screen and (max-width: 991px){
  .asistente{
    width: 90%;
  }
}

.item_form{
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 6px;
  padding-top: 16px;
  padding-bottom: 20px;
}
.item_form .titulo{
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}

.item_form .explicacion{
  margin-bottom: 24px;
  text-align: left;
}

@media screen and (max-width: 991px){
  .item_form{
    padding-right: 6px;
    padding-left: 6px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 6px;
  }
}
@media screen and (max-width: 480px){
  .item_form{
    padding-right: 6px;
    padding-left: 6px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 6px;
  }
}


.input{
  text-align: left;
}
</style>
