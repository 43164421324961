<template>
  <div class="btn_class">
    {{texto}}
  </div>

</template>

<script>
export default {
  name: 'BtnPrimario',
  props: {
    texto: String,
  }
}
</script>

<style scoped lang="scss">
.btn_class {
  background-color: var(--negativo-color-1);
  color: #FFF;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  margin:8px;
}
.btn_class:hover{
  background-color: var(--negativo-color-2);
}
</style>
