<template>
  <div class="cargando_div">
     <div>
       <img style="width: 60px; margin-top: 16px; margin-bottom: 8px;" src="../assets/loading.svg">
     </div>
    <div>
      Cargando...
    </div>
  </div>

</template>

<script>
export default {
  name: 'Cargando',
  props: {
    texto: String,
    url: String,
  }
}
</script>

<style scoped lang="scss">
.cargando_div{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  /*background-color: red;*/
  text-align: center;
}
@media screen and (min-width: 992px){
  .cargando_div{
    left: calc(50% + 150px);
  }
}
</style>
