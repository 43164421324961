<template>
  <div v-show="datos.usuario_aceptado" class="contenedor_carteles">
    <div class="item_mini_cartel" v-bind:class="{ item_mini_cartel_oscuro: datos.modo_oscuro }">
      <div class="cajon">
        <div class="titulo">Datos de facturación</div>
        <div class="flex_wrap">
          <div class="form_item">
            <div>
              Empresa
            </div>
            <div>
              <small>Si eres autónomo o particular puedes ignorar este campo.</small>
            </div>
            <div class="input">
              <input type="text" placeholder="Empresa" v-model="datos.datos_facturacion.empresa" v-on:keyup="validate_form" required >
            </div>
          </div>
          <div class="form_item">
            <div>
              NIF
            </div>
            <div>
              <small>Para autónomo o particular (DNI o NIE)</small>
            </div>
            <div class="input">
              <input type="text" placeholder="NIF (DNI o NIE)" v-model="datos.datos_facturacion.nif" v-on:keyup="validate_form" required >
            </div>
          </div>
        </div>
        <div  class="flex_wrap">
          <div class="form_item">
            <div>
              Nombre
            </div>
            <div>
              <small>Rellenar en caso de ser Autónomo o particular.</small>
            </div>
            <div class="input">
              <input type="text" placeholder="Nombre" v-model="datos.datos_facturacion.nombre" v-on:keyup="validate_form" required >
            </div>
          </div>
          <div class="form_item">
            <div>
              Apellidos
            </div>
            <div>
              <small>Rellenar en caso de ser Autónomo o particular.</small>
            </div>
            <div class="input">
              <input type="text" placeholder="Apellidos" v-model="datos.datos_facturacion.apellidos" v-on:keyup="validate_form" required >
            </div>
          </div>
        </div>



        <div class="form_item" style="width: 100%;">
          <div>
            Dirección
          </div>
          <div class="input">
            <input type="text" placeholder="Dirección" v-model="datos.datos_facturacion.direccion" v-on:keyup="validate_form" required >
          </div>
        </div>
        <div class="flex_wrap">
          <div class="form_item">
            <div>
              Código Postal
            </div>
            <div class="input">
              <input type="text" placeholder="Código postal" v-model="datos.datos_facturacion.codigo_postal" v-on:keyup="validate_form" required >
            </div>
          </div>
          <div class="form_item">
            <div>
              Ciudad
            </div>
            <div class="input">
              <input type="text" placeholder="Ciudad" v-model="datos.datos_facturacion.ciudad" v-on:keyup="validate_form" required >
            </div>
          </div>
        </div>
        <div class="flex_wrap">
          <div class="form_item">
            <div>
              Provincia
            </div>
            <div class="input">
              <input type="text" placeholder="Provincia" v-model="datos.datos_facturacion.provincia" v-on:keyup="validate_form" required >
            </div>
          </div>
          <div class="form_item">
            <div>
              País
            </div>
            <div class="input">
              <input type="text" placeholder="País" v-model="datos.datos_facturacion.pais" v-on:keyup="validate_form" required >
            </div>
          </div>
        </div>
        <div class="flex_wrap">
          <div class="form_item">
            <div>
              Tel. Contacto
            </div>
            <div class="input">
              <input type="tel" placeholder="Teléfono de contacto" v-model="datos.datos_facturacion.telefono" v-on:keyup="validate_form" required >
            </div>
          </div>
          <div class="form_item">
            <div>
              Email
            </div>
            <div class="input">
              <input type="email" placeholder="Email" v-model="datos.datos_facturacion.email" v-on:keyup="validate_form" required >
            </div>
          </div>
        </div>
        <div class="div_acciones">
          <btn-primario texto="Guardar" @click.native="update_facturacion()"></btn-primario>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import BtnPrimario from '../components/BtnPrimario'
import BtnPrimarioTransparente from "@/components/BtnPrimarioTransparente";
import sha1 from 'js-sha1';
export default {
  name: 'EditarFacturacion',
  components: {
    BtnPrimario,
    BtnPrimarioTransparente
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    //Datos de inicio
    datos.id_plan = route.params.id;
    datos.mostrar_menu = true;
    datos.mostrar_items_menu = false;


    datos.menu_padre_seleccioando = 'Confcuenta';


    ///ELIMINAR
    datos.temporales = {
      nombre: '',
      apellidos: '',
    }



    onMounted(function (){
      store.dispatch("get_all_store",datos.id_plan); //Cargamos lo necesario
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos

    async update_facturacion() {
      let datos_enviar = this.datos.datos_facturacion;



      let metodo_envio = '';
      if (datos_enviar.id == undefined){
        metodo_envio = 'POST';
      } else {
        metodo_envio = 'PUT';
      }
      const requestOptions = {
        method: metodo_envio,
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/facturacion/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        const thisStore = this.$store;
        thisStore.dispatch('goTo', `/planconfig/${this.datos.id_plan}`);
      }
    },
    async update_account_access() {
      if (this.datos.pass_nuevo1.trim() != this.datos.pass_nuevo2.trim()){
        alert('Las dos contraseñas nuevas no coinciden.');
        return false;
      }

      //Sacamos el SALT;
      let requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      let response = await fetch(`${this.datos.url_host}/account/salt/${this.datos.usuario.email}`, requestOptions);
      if (response.status != 200) {
        /*let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);*/
        console.log('ERROR', response.status);
        console.log(response);
        return false;
      }

      //Respuesta 200
      const respuestaOK = await response.json();
      if(respuestaOK.code != '00'){
        console.log('ERROR', respuestaOK.code);
        console.log(respuestaOK);
        return false;
      }
      this.datos.salt = respuestaOK.data.salt;


      //Comprobamos la contraseña
      let pass_completo = sha1(this.datos.pass_viejo + this.datos.salt) + ':' + this.datos.salt;
      requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      response = await fetch(`${this.datos.url_host}/account/comprobar_pass/${this.datos.usuario.email}/${pass_completo}`, requestOptions);
      if (response.status != 200) {
        /*let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);*/
        console.log('ERROR', response.status);
        console.log(response);
        alert('Las contraseña actual no es correcta.');
        return false;
      }
      let datos = await response.json();
      if(datos.code != '00'){
        console.log('ERROR', datos.code);
        console.log(datos);
        alert('Las contraseña actual no es correcta.');
        return false;
      }

      //Si llega hasta aquí es que la contraseña es correcta, por lo tanto pedimos un salt nuevo.
      requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      response = await fetch(`${this.datos.url_host}/account/newsalt_cambio_pass/`, requestOptions);
      if (response.status != 200) {
        /*let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);*/
        console.log('ERROR', response.status);
        console.log(response);
        return false;
      }
      datos = await response.json();
      if(datos.code != '00'){
        console.log('ERROR', datos.code);
        console.log(datos);
        return false;
      }
      this.datos.nuevo_salt = datos.data.salt;
      console.log('Nuevo salt', this.datos.nuevo_salt);


      let datos_enviar = {
        newpass: sha1(this.datos.pass_nuevo1 + this.datos.nuevo_salt) + ':' + this.datos.nuevo_salt,
        id_usuario: this.datos.usuario.id
      }
      requestOptions = {
        method: "PUT",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      response = await fetch(`${this.datos.url_host}/account/actualizarpass`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        let thisStore = this.$store;
        thisStore.dispatch('goTo', `/login`);
        return false;
      }

      /////////////////////////////////////////
    },
    async comprobar_token_usuario() {
      let thisStore = this.$store;
      if (localStorage.token == undefined) {
        thisStore.dispatch('goTo', `/login`);
        return false;
      }

      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/account/checktoken`, requestOptions);
      if (response.status != 200) {
        let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);
        return false;
      }

      //Respuesta 200
      const respuestaOK = await response.json();
      console.log('Token valido');
      console.log(respuestaOK);
      if(respuestaOK.code == '00'){

        let nombre = respuestaOK.data.nombre.trim();
        let apellidos = respuestaOK.data.apellidos.trim();


        this.datos.temporales.nombre = nombre;
        this.datos.temporales.apellidos = apellidos;
        return false;
      } else {
        localStorage.removeItem('token');
        //TODO: Otro tipo de error
        thisStore.dispatch('goTo', `/login`);
        return false;
      }
    },
  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    //this.check_token();
    //this.get_all_contactos();
    console.log('DATOS USUARIO', this.datos.usuario);
    /*this.datos.usuario = {
      id: respuestaOK.data.id,
      nivel: respuestaOK.data.nivel,
      email: respuestaOK.data.email,
      nombre: nombre,
      apellidos: apellidos,
      iniciales: iniciales,
    }*/
    this.comprobar_token_usuario();
  },
}
</script>
<style lang="scss" scoped>
@import '../styles/variables.css';
.contenedor_carteles{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
  align-items: center;
}
.form_item{
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 16px;
  padding-top: 4px;
  width: 50%;
  text-align: left;
}
.item_mini_cartel{
  width: 100%;
  padding: 10px;
}
.item_mini_cartel .cajon{
  border-style: solid;
  border-width: 1px;
  border-color: var(--primary-color-5);
  background-color: var(--fondo-menu-claro-theme);
}
.item_mini_cartel .cajon .titulo{
  background-color: var(--primary-color-5);
  color: #FFF;
  padding-top: 10px;
  padding-bottom: 12px;
  font-size: 20px;
}

.item_mini_cartel_oscuro .cajon{
  border-style: solid;
  border-width: 1px;
  border-color: var(--primary-color-1);
  background-color: var(--fondo-menu-dark-theme)!important;
}
.item_mini_cartel_oscuro .cajon .titulo{
  background-color: var(--primary-color-1);
  color: #FFF;
  padding-top: 10px;
  padding-bottom: 12px;
  font-size: 20px;
}
.item_mini_cartel .cajon .div_acciones{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}
@media screen and (max-width: 991px){
  .item_mini_cartel{
    width: 100%;
  }
  .form_item{
    width: 100%;
  }
}

.flex_wrap{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}



</style>
