<template>
  <div v-show="datos.usuario_aceptado">
    <div>
      <div>
        <div style="font-size: 20px;
    font-weight: bold;
    text-align: left;
    margin-left: 22px;
    padding-top: 16px;">{{ datos.nombre_proyecto }}</div>
        <div style="margin-left: 22px;
    text-align: left;
    margin-top: 10px;
}">{{ datos.url_proyecto }}</div>
      </div>
      <div class="contenedor_botonera">
        <div class="btn_horas" v-bind:class="{ item_horas_seleccionado: (datos.tiempo_grafica == '30m') }" @click="seleccionar_horas('30m')">30m</div>
        <div class="btn_horas" v-bind:class="{ item_horas_seleccionado: (datos.tiempo_grafica == '1h') }" @click="seleccionar_horas('1h')">1h</div>
        <div class="btn_horas" v-bind:class="{ item_horas_seleccionado: (datos.tiempo_grafica == '3h') }" @click="seleccionar_horas('3h')">3h</div>
        <div class="btn_horas" v-bind:class="{ item_horas_seleccionado: (datos.tiempo_grafica == '6h') }" @click="seleccionar_horas('6h')">6h</div>
        <div class="btn_horas" v-bind:class="{ item_horas_seleccionado: (datos.tiempo_grafica == '12h') }" @click="seleccionar_horas('12h')">12h</div>
        <div class="btn_horas" v-bind:class="{ item_horas_seleccionado: (datos.tiempo_grafica == '24h') }" @click="seleccionar_horas('24h')">24h</div>
        <div class="btn_horas" v-bind:class="{ item_horas_seleccionado: (datos.tiempo_grafica == '1s') }" @click="seleccionar_horas('1s')">1s</div>
        <div class="btn_autoload" v-bind:class="{ item_horas_seleccionado: (datos.autoload) }" @click="activar_desactivar_autoload()">Actualización automática</div>
      </div>
      <div v-show="datos.mostrar_grafica" style="    padding-bottom: 10px;">Online: {{datos.tiempo_operativo}}% del tiempo</div>
      <div v-show="datos.mostrar_cargando">
        <img style="width: 60px; margin-top: 16px; margin-bottom: 8px;" src="../assets/loading.svg">
        <div>Cargando datos...</div>
      </div>
      <div v-show="datos.mostrar_grafica">
        <canvas class="fondo_grafica" v-bind:class="{ fondo_grafica_oscuro: datos.modo_oscuro }" id="myChart"></canvas>
      </div>
    </div>

  </div>
</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed, onBeforeUnmount} from "vue"
import { useRoute } from 'vue-router'

import Chartjs from '../js/chart';

let miGraf = null;
let miSetInterval = null;

export default {
  name: 'ProyectoEstado',
  components: {
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar


    //Datos de inicio
    datos.id_plan = route.params.id;
    datos.mostrar_menu = true;
    datos.id_proyecto = route.params.id_proyecto;
    datos.tiempo_grafica = '30m';
    datos.nombre_proyecto = '';
    datos.url_proyecto = '';
    datos.autoload = true;
    datos.tiempo_operativo = 0;
    datos.mostrar_cargando = true;
    datos.mostrar_grafica = false;

    onMounted(function (){
      store.dispatch("get_all_store",datos.id_plan); //Cargamos lo necesario
    });
    onBeforeUnmount(function (){
      if(miSetInterval != null){
        clearInterval(miSetInterval);
      }
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    async get_info_proyecto(){
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/registro/status/${this.datos.id_proyecto}/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200){
        let thisStore = this.$store;
        thisStore.dispatch('goTo', `/proyectos/`);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        //this.datos.proyectos_caidos = datos.data.proyectos;
        console.log(datos.data.estado_proyecto);
        let info_proyecto = datos.data.estado_proyecto;

        this.datos.nombre_proyecto = info_proyecto.nombre_web;
        this.datos.url_proyecto = info_proyecto.url;

      }
    },
    async get_datos_ultimas_2_horas(){
        const tiempo_cargar = this.datos.tiempo_grafica;
        if(!this.datos.mostrar_grafica) this.datos.mostrar_cargando = true;
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      //console.log('Entra en consulta aa', `${this.datos.url_host}/registro/12h/${this.datos.id_proyecto}`);
      const response = await fetch(`${this.datos.url_host}/registro/por_tiempo/${this.datos.id_proyecto}/${this.datos.tiempo_grafica}/${this.datos.id_plan}`, requestOptions);
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        this.datos.mostrar_cargando = false;
        this.datos.mostrar_grafica = false;
      } else {

        if (this.datos.tiempo_grafica != tiempo_cargar) return false;
        //this.datos.proyectos_caidos = datos.data.proyectos;
        console.log(datos.data.registros);

        let cantidad_caido = 0;
        let max_tiempo = 0
        let labels = [];
        let values = [];
        let values_caidos = [];
        let values_caidos_temp = [];
        let registros = datos.data.registros;
        let cantidad = registros.length;
        for (let a = 0; a < cantidad; a++){
          let item = registros[a];
          labels.push(item.inicio);
          let milisegundos = 0;
          let milisegundos_caidos = 0;
          if (item.estado == 1){
            milisegundos = item.milisegundos
            if(milisegundos > max_tiempo){
              max_tiempo = milisegundos
            }
          } else {
            //milisegundos_caidos = item.milisegundos;
            milisegundos_caidos = -1;
            cantidad_caido++;
          }
          values.push(milisegundos);
          values_caidos_temp.push(milisegundos_caidos);
        }

        let cantiadd_caidos = values_caidos_temp.length;
        for (let a = 0; a < cantiadd_caidos; a++){
          let valor = values_caidos_temp[a];
          if (valor < 0){
            valor = max_tiempo;
          }
          values_caidos.push(valor);
        }



        let operativo = ((cantidad-cantidad_caido)*100)/cantidad;
        let opertativo_round = Math.round(operativo * 100) / 100
        console.log('operativo', opertativo_round);
        this.datos.tiempo_operativo = opertativo_round;


        let color_cruces = '#e9e9e9';
        let color_leyenda = '#435861';
        if(this.datos.modo_oscuro){
          color_leyenda = '#e9e9e9';
          color_cruces = '#292f34';
        }


        let data = {
          labels: labels,
          datasets: [{
            fill: true,
            tension: 0.4,
            label: 'Online',
            backgroundColor: 'rgba(15,164,115,0.48)',
            borderColor: '#077652',
            borderWidth: 1,
            data: values,
          }]
        }

        if(cantidad_caido>0){
          data.datasets.push(
              {
                fill: true,
                tension: 0.4,
                label: 'OffLine',
                backgroundColor: 'rgba(164,15,20,0.48)',
                borderColor: '#760707',
                borderWidth: 1,
                type: 'bar',
                data: values_caidos,
              }
          )
        }


        let conf_graf = null;
        conf_graf = {
          type: 'line',
          data,
          options: {
            maintainAspectRatio: false,
            layout: {
              padding: 20
            },
            interaction: {
              intersect: false,
              mode: 'nearest',
            },
            tooltips: {
              enabled: true,
              mode: 'single',
              callbacks: {
                label: function (tooltipItems, data) {
                  var i = tooltipItems.index;
                  return data.labels[i] + ": " + data.datasets[0].data[i] + " %";
                }
              }
            },
            animation: false,
            scales: {
              y: {
                grid:{
                  color:color_cruces,
                },
                type: 'linear',
                min: 0,
                display: true,
                title: {
                  display: true,
                  text: 'Milisegundos',
                  color: color_leyenda,
                },
                ticks: {
                  color:color_leyenda,
                },
              },
              x: {
                grid:{
                  color:color_cruces,
                },
                ticks: {
                  color:color_leyenda,
                  autoSkip: true,
                  maxTicksLimit: 6
                },
                display: true,
                title: {
                  display: false,
                  text: 'Fecha'
                }
              }
            },
            spanGaps: true,
            showLine: true,
            responsive: true,
            elements: {
              point: {
                radius: 2, // default to disabled in all datasets
              }
            },
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                color: color_leyenda,
                display: true,
                text: 'Tiempo de respuesta del servidor'
              }
            }
          }
        };

        /*if (miGraf == null){
          console.log('Entra en null');
          miGraf= new Chart(
              document.getElementById('myChart'),
              conf_graf
          );
        } else {
          console.log('Entra en no null');
          miGraf.destroy();
          miGraf = new Chart(document.getElementById('myChart'), conf_graf);
        }*/
        this.datos.mostrar_cargando = false;
        this.datos.mostrar_grafica = true;

        if (miGraf) {
          miGraf.destroy();
        }
        miGraf = new Chart(document.getElementById('myChart'), conf_graf);

        document.getElementById('myChart').style.maxWidth = "96%";
        document.getElementById('myChart').style.minWidth = "96%";
        //document.getElementById('myChart').style.minHeight = "50%";
        document.getElementById('myChart').style.marginLeft = "auto";
        document.getElementById('myChart').style.marginRight = "auto";
        document.getElementById('myChart').style.minHeight = "550px";
        document.getElementById('myChart').style.maxHeight = "600px";

        //datos.modo_oscuro
      }
    },
    seleccionar_horas(horas){
      this.datos.mostrar_grafica = false;
      this.datos.mostrar_cargando = true;
      if(miSetInterval != null){
        clearInterval(miSetInterval);
      }
      let segundos = 15;
      if (horas == '1s' || horas == '24h' || horas == '12h'){
        segundos = 150;
      }
      console.log(horas);
      this.datos.tiempo_grafica = horas;
      this.get_datos_ultimas_2_horas();
      if (this.datos.autoload){
        if (miSetInterval != null){
          clearInterval(miSetInterval);
        }
        miSetInterval = null;
        miSetInterval = setInterval(() => {
          this.get_datos_ultimas_2_horas();
          this.get_info_proyecto();
        }, segundos*1000);
      }
    },
    activar_desactivar_autoload(){
      this.datos.autoload = !this.datos.autoload;
      console.log('autoload', this.datos.autoload)
      if (!this.datos.autoload){
        if (miSetInterval != null){
          clearInterval(miSetInterval);
        }
      } else {
        if (miSetInterval != null){
          clearInterval(miSetInterval);
        }
        miSetInterval = null;
        miSetInterval = setInterval(() => {
          this.get_datos_ultimas_2_horas();
          this.get_info_proyecto();
        }, 15000);
      }
    }
  },
  async mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    //this.check_token();
    this.get_datos_ultimas_2_horas();
    this.get_info_proyecto();
    miSetInterval = setInterval(() => {
      this.get_datos_ultimas_2_horas();
      this.get_info_proyecto();
    }, 15000);



  },
}
</script>
<style lang="scss" scoped>
@import '../styles/variables.css';
.contenedor_botonera{
  margin-top: 30px;
  margin-bottom: 46px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 30px;
}
.btn_horas{
  background-color: var(--primary-color-1);
  color:#FFF;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 4px;
  cursor: pointer;
}
.btn_horas:hover{
  background-color: var(--primary-color-2);
}
.item_horas_seleccionado{
  background-color: var(--accent-color-2)!important;
}
.item_horas_seleccionado:hover{
  background-color: var(--accent-color-3)!important;
}
.btn_autoload{
  background-color: #919191;
  color:#FFF;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 4px;
  cursor: pointer;
}
.btn_autoload:hover{
  background-color: #525252;
}
@media screen and (max-width: 991px){
  .contenedor_botonera{
    flex-wrap: wrap!important;
  }
  .btn_horas{
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.fondo_grafica{
  background-color: var(--fondo-menu-claro-theme);
}
.fondo_grafica_oscuro{
  background-color: var(--fondo-menu-dark-theme)!important;
}
</style>
