<template>
  <div class="btn_class">
    {{texto}}
  </div>

</template>

<script>
export default {
  name: 'BtnPrimarioTransparente',
  props: {
    texto: String,
  }
}
</script>

<style scoped lang="scss">
.btn_class {
  /*background-color: var(--primary-color-1);*/
  color: var(--primary-color-1);
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
}
.btn_class:hover{
  color: var(--primary-color-2);
}
</style>
