<template>
  <div v-show="datos.usuario_aceptado && datos.id_proyecto == 0" class="contenedor" v-bind:class="{ contenedor_oscuro: datos.modo_oscuro }">
    <div class="asistente">
      <div class="paso1" v-show="datos.paso_asistente == '1'">
        <div class="item_form">
          <div class="titulo">
            Url de consulta
          </div>
          <div class="explicacion">
            Escriba la URL de la página web o servicio API REST que quiera usar para validar el correcto funcionamiento.
          </div>
          <div class="input">
            <input type="text" placeholder="https://google.es/" v-model="datos.url_proyecto" v-on:keyup="validate_form" required >
          </div>
        </div>
      </div>
      <div class="paso2" v-show="datos.paso_asistente == '2'">
        <div class="item_form">
          <div class="titulo">
            Texto a buscar
          </div>
          <div class="explicacion">
            Escribre el texto que quieres buscar una vez haya respondido el servidor.<br>
            En caso de ser una página web, puedes buscar etiquetas HTML del final de la página, <br>como "&lt;/html&gt;" o "&lt;/body&gt;".
          </div>
          <div class="input">
            <input type="text" placeholder="</html>" v-model="datos.buscar_proyecto" v-on:keyup="validate_form" required >
          </div>
        </div>
      </div>
      <div class="paso3" v-show="datos.paso_asistente == '3'">
        <div class="item_form">
          <div class="titulo">
            Comprobación
          </div>
          <div class="explicacion">
            <div class="alert alert_tarea" v-show="datos.mensaje_tarea_mostrar">
              <!--<span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>-->
              {{datos.mensaje_tarea}}
            </div>
            <div v-show="datos.mensaje_tarea_mostrar" style="width: 100%;text-align: center;">
              <img style="width: 30px; margin-top: 16px; margin-bottom: 8px;" src="../assets/loading.svg">
            </div>
            <div class="alert" v-show="datos.mensaje_error_mostrar">
              <!--<span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>-->
              {{datos.mensaje_error}}
            </div>
            <div class="alert alert_verde" v-show="datos.mensaje_correcto_mostrar">
              <!--<span class="closebtn" onclick="this.parentElement.style.display='none';">&times;</span>-->
              {{datos.mensaje_correcto}}
            </div>
          </div>
        </div>
      </div>
      <div class="paso4" v-show="datos.paso_asistente == '4'">
        <div class="item_form">
          <div class="titulo">
            Nombre del proyecto
          </div>
          <div class="explicacion">
            Escribe un nombre a este proyecto.
          </div>
          <div class="input">
            <input type="text" placeholder="Web de Alberto" v-model="datos.nombre_proyecto" v-on:keyup="validate_form" required >
          </div>
        </div>
      </div>
      <div class="paso5" v-show="datos.paso_asistente == '5'">
        <div class="item_form">
          <div class="titulo">
            Guardando
          </div>
          <div class="explicacion">
            Estamos guardando la información del nuevo proyecto...
            <div style="width: 100%;text-align: center;">
              <img style="width: 30px; margin-top: 16px; margin-bottom: 8px;" src="../assets/loading.svg">
            </div>
          </div>
        </div>
      </div>
      <div class="botonera">
        <btn-primario v-show="datos.paso_asistente > 1" texto="Atrás" @click.native="asistente(-1)"></btn-primario>
        <btn-primario v-show="datos.mostrar_btn_siguiente" texto="Siguiente" @click.native="asistente(1)"></btn-primario>
        <btn-primario v-show="datos.paso_asistente == 4" texto="Guardar" @click.native="asistente(1)"></btn-primario>
        <!--<btn-primario v-show="datos.check_correcto"  texto="Comprobar" @click.native="comprobar_todo()"></btn-primario>-->
        <!--<btn-primario v-show="datos.check_correcto" texto="Guardar" @click.native="siguiente()"></btn-primario>-->
      </div>
    </div>
  </div>
  <div v-show="datos.usuario_aceptado && datos.id_proyecto > 0" class="contenedor2" v-bind:class="{ contenedor2_oscuro: datos.modo_oscuro }">
    <div style="display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 16px;
    padding-left: 10px;
    padding-right: 10px;">
      <div v-show="(datos.prioridad_proyecto_tipos.length > 0)">
        <div>Pioridad de check</div>
        <select v-model="datos.prioridad_proyecto">
          <option v-for="item in datos.prioridad_proyecto_tipos" :value="item.value">
            {{ item.text }}
          </option>
        </select>
      </div>
      <btn-primario v-show="(datos.prioridad_proyecto_defecto != datos.prioridad_proyecto)" texto="Guardar" @click.native="guardar_prioridad()"></btn-primario>
    </div>

    <div style="margin-bottom: 16px; display: flex; flex-direction: row; flex-wrap: nowrap; justify-content: space-between; align-items: center;">
      <div>
        <div style="font-size: 20px;
    font-weight: bold;
    text-align: left;
    margin-left: 22px;
    padding-top: 16px;">{{ datos.nombre_proyecto }}</div>
        <div style="margin-left: 22px;
    text-align: left;
    margin-top: 10px;
}">{{ datos.url_proyecto }}</div>
      </div>
      <div>
        <btn-primario-negativo v-show="datos.activo == 1" texto="Desactivar" @click.native="activar_y_desactivar(0)"></btn-primario-negativo>
        <btn-primario v-show="datos.activo == 0" texto="Activar" @click.native="activar_y_desactivar(1)"></btn-primario>
      </div>
    </div>
    <contactos-disponbiles-en-proyecto></contactos-disponbiles-en-proyecto>
    <div>
      <btn-primario-negativo texto="Eliminar proyecto" @click.native="eliminar_proyecto()"></btn-primario-negativo>
    </div>
    <div class="oscuro" v-show="datos.mostrar_cartel_eliminar" @click.native="ocultar_eliminar()"></div>
    <div class="div_eliminar" v-show="datos.mostrar_cartel_eliminar">
      <div>
        <div class="titulo">Eliminar {{datos.nombre_proyecto}}</div>
        <div class="texto">¿Estás seguro que quieres eliminar este proyecto?<br><br>Para eliminar el proyecto necesitarás escribir "{{datos.nombre_proyecto}}" en el campo de texto y pulsar sobre el botón de eliminar.</div>
        <div><input type="text" v-model="datos.nombre_proyecto_eliminar" :placeholder="datos.nombre_proyecto"></div>
        <div><btn-primario-negativo texto="Eliminar" @click.native="eliminar_proyecto_final()"></btn-primario-negativo></div>
      </div>

    </div>
  </div>
</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import BtnPrimario from '../components/BtnPrimario'
import ContactosDisponbilesEnProyecto from '../components/ContactosDisponiblesEnProyecto'
import BtnPrimarioNegativo from '../components/BtnPrimarioNegativo'
export default {
  name: 'ProyectoConfiguracion',
  components: {
    BtnPrimario,
    BtnPrimarioNegativo,
    ContactosDisponbilesEnProyecto,
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    //Datos de inicio
    datos.id_plan = route.params.id;
    datos.mostrar_menu = true;
    datos.id_proyecto = route.params.id_proyecto;
    datos.paso_asistente = 1;
    datos.prioridad_proyecto = 0
    datos.prioridad_proyecto_tipos = []
    datos.prioridad_proyecto_defecto = 0
    datos.nombre_proyecto_eliminar = "";
    datos.mostrar_cartel_eliminar = false;


    console.log('route',route.params);
    console.log('route plan',datos.id_plan);
    console.log('route proyecto',datos.id_proyecto);
    /*selected: 'A',
        options: [
      { text: 'One', value: 'A' },
      { text: 'Two', value: 'B' },
      { text: 'Three', value: 'C' }
    ]*/



    datos.mensaje_tarea_mostrar = false;
    datos.mensaje_tarea = ''

    datos.mensaje_error_mostrar = false;
    datos.mensaje_error = '';

    datos.mensaje_correcto_mostrar = false;
    datos.mensaje_correcto = '';

    datos.url_proyecto = '';
    datos.nombre_proyecto = '';

    datos.url_buena = '';
    datos.texto_buscar_bueno = '';
    datos.check_correcto = false;
    datos.mostrar_btn_siguiente = true;

    datos.contactos_all = [];

    onMounted(function (){
      store.dispatch("get_all_store",datos.id_plan); //Cargamos lo necesario
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    validate_form: function(e){
      if (e.keyCode === 13) {
        this.asistente(1);
      }
    },
    asistente(sumar_restar){
      this.datos.paso_asistente = this.datos.paso_asistente+sumar_restar;
      if (this.datos.paso_asistente == 3){
        this.datos.mostrar_btn_siguiente = false;
        this.comprobar_todo();
      } else {
        this.datos.mostrar_btn_siguiente = true;
      }
      if (this.datos.paso_asistente == 4){
        this.datos.mostrar_btn_siguiente = false;
      }
      if (this.datos.paso_asistente == 5){
        this.guardar();
      }
    },
    async eliminar_proyecto_final() {
      if (this.datos.nombre_proyecto_eliminar.trim().toLowerCase() == this.datos.nombre_proyecto.trim().toLowerCase()) {
        console.log('Eliminar');
        const requestOptions = {
          method: "DELETE",
          headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        };
        console.log('proyecto', this.datos.id_proyecto);
        console.log('plan', this.datos.id_plan);
        const response = await fetch(`${this.datos.url_host}/proyecto/${this.datos.id_proyecto}/${this.datos.id_plan}`, requestOptions);
        if (response.status != 200) {
          let thisStore = this.$store;
          thisStore.dispatch('goTo', `/proyectos/${this.datos.id_plan}`);
          return false;
        }
        const datos = await response.json();
        if (datos.code != '00') {
          console.log(response);
          console.log(datos);
        } else {
          let thisStore = this.$store;
          thisStore.dispatch('goTo', `/proyectos/${this.datos.id_plan}`);
        }
      } else {
        console.log('No');
      }
    },
    ocultar_eliminar(){
      this.datos.mostrar_cartel_eliminar = false;
    },
    async guardar() {
      let datos_enviar = {
          url: this.datos.url_buena,
        buscar: this.datos.texto_buscar_bueno,
        nombre: this.datos.nombre_proyecto
      }
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/proyecto/nuevo/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200) {
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00') {
        console.log(response);
        console.log(datos);
      } else {
        let thisStore = this.$store;
        thisStore.dispatch('goTo', `/proyectos/${this.datos.id_plan}`);
        return false;
      }
    },
    eliminar_proyecto(){
      this.datos.mostrar_cartel_eliminar = true;
    },
    mostrar_cargando_tarea(texto){
      this.datos.mensaje_tarea_mostrar = true;
      this.datos.mensaje_tarea = texto
    },
    mostrar_mensaje_error(texto){
      this.datos.mensaje_error_mostrar = true;
      this.datos.mensaje_error = texto
    },
    mostrar_mensaje_correcto(texto){
      this.datos.mensaje_correcto_mostrar = true;
      this.datos.mensaje_correcto = texto
    },
    borrar_alertas(){
      this.datos.mensaje_tarea_mostrar = false;
      this.datos.mensaje_tarea = ''

      this.datos.mensaje_error_mostrar = false;
      this.datos.mensaje_error = '';

      this.datos.mensaje_correcto_mostrar = false;
      this.datos.mensaje_correcto = '';
    },


    async comprobar_todo() {
      this.borrar_alertas();
      this.mostrar_cargando_tarea(`Comprobando url: ${this.datos.url_proyecto}`);
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token, "url":this.datos.url_proyecto},
      };
      const response = await fetch(`${this.datos.url_host}/check/url`, requestOptions);
      if (response.status != 200){
        this.borrar_alertas();
        this.mostrar_mensaje_error('Error de conexión, intentalo de nuevo.');
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        this.borrar_alertas();

        if(datos.codeError != undefined && datos.textError != undefined){
          //TODO: Servidor caido
          this.mostrar_mensaje_error(`Error ${datos.codeError}, ${datos.textError}`);
          this.datos.check_correcto = false;
          return false;
        } else {
          this.datos.url_buena = this.datos.url_proyecto;
          this.mostrar_cargando_tarea(`Buscando ${this.datos.buscar_proyecto}...`);
          let res = -1;
          try{
            res = datos.data.resultado.indexOf(this.datos.buscar_proyecto);
          } catch (e){
            this.borrar_alertas();
            console.log('ERROR CUBEL');
            console.log(e);
            console.log('WEB', this.datos.url_proyecto);
            console.log('Respuesta', datos);
            console.log('Buscando', this.datos.buscar_proyecto);
            this.mostrar_mensaje_error(`Error, intentalo de nuevo`);
            return false;
          }

          if (res > -1){
            this.borrar_alertas();
            this.mostrar_mensaje_correcto('Texto encontrado!')
            this.datos.texto_buscar_bueno = this.datos.buscar_proyecto;
            this.datos.check_correcto = true;
            this.datos.mostrar_btn_siguiente = true;
          } else {
            this.datos.texto_buscar_bueno = '';
            this.datos.check_correcto = false;
            this.borrar_alertas();
            this.mostrar_mensaje_error(`Texto no encontrado`);
          }
        }

      }
    },
    async get_configuracion_proyecto() {
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/proyecto/configuracion/${this.datos.id_proyecto}/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        console.log('Datos', datos.data);
        console.log('Datos proyecto', datos.data);
      }
    },
    /*async get_all_contactos() {
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/contactos/all`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        console.log('Contactos', datos.data);
        this.datos.contactos_all = datos.data;
      }
    },*/
    async get_info_proyecto(){
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      console.log('proyecto',this.datos.id_proyecto);
      console.log('plan',this.datos.id_plan);
      const response = await fetch(`${this.datos.url_host}/registro/status/${this.datos.id_proyecto}/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200){
        let thisStore = this.$store;
        thisStore.dispatch('goTo', `/proyectos/${this.datos.id_plan}`);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        //this.datos.proyectos_caidos = datos.data.proyectos;
        this.datos.prioridad_proyecto_tipos = [];
        if (this.datos.permisos.max_tipo_check == 1){
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 5 minutos', value: 1});
          //this.datos.prioridad_proyecto_tipos.push({text:'Cada 2 minutos', value: 2});
        } else if (this.datos.permisos.max_tipo_check == 2){
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 5 minutos', value: 1});
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 2 minutos', value: 2});
          //this.datos.prioridad_proyecto_tipos.push({text:'Cada 1 minuto', value: 3});
        } else if (this.datos.permisos.max_tipo_check == 3){
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 5 minutos', value: 1});
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 2 minutos', value: 2});
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 1 minuto', value: 3});
          //this.datos.prioridad_proyecto_tipos.push({text:'Cada 30 segundos', value: 4});
        } else if(this.datos.permisos.max_tipo_check == 4){
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 5 minutos', value: 1});
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 2 minutos', value: 2});
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 1 minuto', value: 3});
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 30 segundos', value: 4});
        }
        let info_proyecto = datos.data.estado_proyecto;
        this.datos.prioridad_proyecto = info_proyecto.tipo_check;
        this.datos.prioridad_proyecto_defecto = this.datos.prioridad_proyecto;
        this.datos.nombre_proyecto = info_proyecto.nombre_web;
        this.datos.url_proyecto = info_proyecto.url;
        this.datos.activo = info_proyecto.activo;

      }
    },
    async activar_y_desactivar(estado){
      const requestOptions = {
        method: "PUT",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/proyecto/activar-desactivar/${this.datos.id_proyecto}/${estado}/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        console.log('Datos', datos.data);
        console.log('Datos proyecto', datos.data.proyecto);
      }

      this.get_info_proyecto();
    },
    async guardar_prioridad(){
      const requestOptions = {
        method: "PUT",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/proyecto/prioridad/${this.datos.id_proyecto}/${this.datos.prioridad_proyecto}/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        console.log('Datos', datos.data);
        console.log('Datos proyecto', datos.data.proyecto);
      }

      this.get_info_proyecto();
    }
  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    //this.check_token();
    console.log('Datos', this.datos);
    if (this.datos.id_proyecto != 0){
      //TODO: llamamos a la funcion de cargar info
      //this.get_configuracion_proyecto();
      this.get_info_proyecto();
    }
  },
}
</script>
<style lang="scss" scoped>
@import '../styles/variables.css';

.link_registro{
  cursor:pointer;
  color: var(--primary-color-1);
}
input[type=text], input[type=password] {
  background-color: #ffffff;
  color: var(--primary-color-3);
  width: 100%;
  border-radius: 4px;
  border-style: solid;
  margin-bottom: 10px;
  margin-top: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 10px;
  padding-left: 10px;
  border-width: 1px;
  border-color: var(--primary-color-3);
}
input[type=text]:focus, input[type=password]:focus {
  outline: none;
  border-width: 1px;
  border-color: var(--primary-color-1);
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--detalles-gris-clarito);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--detalles-gris-clarito);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--detalles-gris-clarito);
}
.contenedor{
  /*display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
  align-items: center;*/

  /*width: 60%;*/
  background-color: var(--fondo-claro-theme);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.contenedor2{
  background-color: var(--fondo-claro-theme);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  text-align: center;
  /* min-height: 100vh; */
  align-content: space-around;
  flex-wrap: nowrap;
}
.contenedor_oscuro{
  background-color: var(--fondo-dark-theme);
}
.contenedor2_oscuro{
  background-color: var(--fondo-dark-theme);
}
.asistente{
  background-color: var(--fondo-menu-claro-theme);
  width: 60%;
}
.contenedor_oscuro .asistente{
  background-color: var(--fondo-menu-dark-theme);
}
.asistente .botonera{
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: flex-end;
  align-items: center;
  margin-top: 36px;
  margin-right: 16px;
  margin-bottom: 16px;
}
.item_form{
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 6px;
  padding-top: 16px;
  padding-bottom: 20px;
}
.item_form .titulo{
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}

.item_form .explicacion{
  margin-bottom: 24px;
  text-align: left;
}

@media screen and (max-width: 991px){
.item_form{
padding-right: 6px;
padding-left: 6px;
width: 100%;
margin-top: 10px;
margin-bottom: 6px;
}
}
@media screen and (max-width: 480px){
.item_form{
padding-right: 6px;
padding-left: 6px;
width: 100%;
margin-top: 10px;
margin-bottom: 6px;
}
}

.alert {
padding: 20px;
background-color: #f44336;
color: white;
width: 100%;
margin-left: 6px;
margin-right: 6px;
margin-top: 20px;
margin-bottom: 20px;
}
.alert_verde {
background-color: var(--accent-color-3);
}
.alert_tarea{
background-color: var(--primary-color-3);
}

.closebtn {
margin-left: 15px;
color: white;
font-weight: bold;
float: right;
font-size: 22px;
line-height: 20px;
cursor: pointer;
transition: 0.3s;
}

.closebtn:hover {
color: black;
}
.div_eliminar{
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  text-align: center;

  background-color: #FFFFFF;
  z-index: 2;
  padding: 12px;
}
.div_eliminar .titulo{
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}
.div_eliminar .texto{
  margin-top: 12px;
  margin-bottom: 16px;
  text-align: left;
}
.oscuro{
  width: 100%;
  height: 100%;
  background-color: #0000007a;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}
@media screen and (max-width: 991px){
  .asistente{
    width: 90%;
  }
  .div_eliminar{
    width: 90%;
  }
}
@media screen and (min-width: 992px){
  .div_eliminar{
    left: calc(50% + 150px);
    width: 400px;
  }
}
</style>
