<template>
  <div v-show="datos.usuario_aceptado" class="contenedor_carteles">
    <router-link :to="'/proyectos/'+ datos.id_plan" v-if="!datos.mostrar_contactos"><BtnAtras texto="Ver todos los proyectos"></BtnAtras></router-link>
    <BtnAtras texto="Volver atrás" @click.native="mostrar_ocultar_contactos()" v-if="datos.mostrar_contactos"></BtnAtras>
    <div class="item_100_cartel" v-bind:class="{ item_100_cartel_oscuro: datos.modo_oscuro }" v-if="!datos.mostrar_contactos">
      <div class="cajon">
        <div class="titulo">Datos del proyecto</div>
        <div class="contenido_info">
          <div class="form_item">
            <div>
              URL <a :href="datos.url_proyecto" target="_blank"><small>(Visitar)</small></a>
            </div>
            <div class="input">
              {{ datos.url_proyecto }}
            </div>
          </div>
          <div class="form_item">
            <div>
              Nombre<small @click="editar('nombre')"><font-awesome-icon :icon="['fa', 'edit']" class="icon alt"/></small>
            </div>
            <div class="input">
              {{ datos.nombre_proyecto }}
            </div>
          </div>
          <div class="form_item">
            <div>
              Estado
            </div>
            <div class="input" v-if="datos.estado_actual == 1 && datos.activo == 1">
              Online
            </div>
            <div class="input" v-if="datos.estado_actual == 0 && datos.activo == 1">
              Offline
            </div>
            <div class="input" v-if="(datos.estado_actual != 0 && datos.estado_actual != 1) || datos.activo == 0">
              --
            </div>
          </div>
          <div class="form_item">
            <div>
              Tiempo de comprobación<small @click="editar('prioridad')"><font-awesome-icon :icon="['fa', 'edit']" class="icon alt"/></small>
            </div>
            <div class="input" v-if="datos.prioridad_proyecto == 1">
              Cada 5 minutos
            </div>
            <div class="input" v-if="datos.prioridad_proyecto == 2">
              Cada 2 minutos
            </div>
            <div class="input" v-if="datos.prioridad_proyecto == 3">
              Cada 1 minuto
            </div>
            <div class="input" v-if="datos.prioridad_proyecto == 4">
              Cada 30 segundos
            </div>
          </div>
          <div class="form_item">
            <div>
              Estado comprobación <small @click="editar('estadochecking')"><font-awesome-icon :icon="['fa', 'edit']" class="icon alt"/></small>
            </div>
            <div class="input" v-if="datos.activo == 1">
              Activado
            </div>
            <div class="input" v-if="datos.activo == 0" style="color: red; font-weight: bold;">
              Desactivado
            </div>
            <div class="input" v-if="datos.activo != 0 && datos.activo != 1">
              --
            </div>
          </div>
          <div class="form_item">
            <div>
              Contactos <small @click="mostrar_ocultar_contactos()"><font-awesome-icon :icon="['fa', 'edit']" class="icon alt"/></small>
            </div>
            <div class="input" v-if="datos.contactos_en_proyecto.length == 1">
              {{ datos.contactos_en_proyecto.length }} contacto
            </div>
            <div class="input" v-if="datos.contactos_en_proyecto.length > 1">
              {{ datos.contactos_en_proyecto.length }} contactos
            </div>
            <div class="input" v-if="datos.contactos_en_proyecto.length == 0" style="color: red; font-weight: bold;">
              No hay contactos vinculados a este proyecto
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="item_100_cartel" v-bind:class="{ item_100_cartel_oscuro: datos.modo_oscuro }" v-if="datos.mostrar_contactos">
      <div class="cajon">
        <div class="titulo">Contactos de aviso</div>
        <div class="row_titulo">
          <div class="item">Contacto</div>
          <div class="item">Email</div>
          <div class="item">Móvil</div>
          <div class="item item_acciones">Acciones</div>
        </div>
        <div v-for="item in datos.contactos_en_proyecto" v-bind:id="item.id"  v-bind:nombre_contacto="item.nombre_contacto" v-bind:mail="item.mail" v-bind:movil="item.movil" class="item_row">
          <div class="item">
            {{item.nombre_contacto}}
          </div>
          <div class="item">
            {{item.mail}}
          </div>
          <div class="item">
            {{item.movil}}
          </div>
          <div class="item item_acciones item_acciones_btn">
            <btn-primario-negativo texto="Quitar del proyecto" @click.native="quitar_contacto(item.id)"></btn-primario-negativo>
          </div>
        </div>
      </div>
    </div>
    <div class="item_100_cartel" v-bind:class="{ item_100_cartel_oscuro: datos.modo_oscuro }" v-if="datos.mostrar_contactos">
      <div class="cajon">
        <div class="titulo">Contactos disponibles</div>
        <div class="row_titulo">
          <div class="item">Contacto</div>
          <div class="item">Email</div>
          <div class="item">Móvil</div>
          <div class="item item_acciones">Acciones</div>
        </div>
        <div v-for="item in datos.contactos_all" v-bind:id="item.id"  v-bind:nombre_contacto="item.nombre_contacto" v-bind:mail="item.mail" v-bind:movil="item.movil" class="item_row">
          <div class="item">
            {{item.nombre_contacto}}
          </div>
          <div class="item">
            {{item.mail}}
          </div>
          <div class="item">
            {{item.movil}}
          </div>
          <div class="item item_acciones item_acciones_btn">
            <btn-primario texto="Añadir al proyecto" @click.native="anyadir_contacto(item.id)"></btn-primario>
          </div>
        </div>
      </div>
    </div>
    <div class="item_100_cartel" v-bind:class="{ item_100_cartel_oscuro: datos.modo_oscuro }" v-if="!datos.mostrar_contactos">
      <div class="cajon">
        <div class="titulo">Tiempo de respuesta del servidor</div>
        <div class="contenedor_botonera">
          <div class="btn_horas" v-bind:class="{ item_horas_seleccionado: (datos.tiempo_grafica == '30m') }" @click="seleccionar_horas('30m')">30m</div>
          <div class="btn_horas" v-bind:class="{ item_horas_seleccionado: (datos.tiempo_grafica == '1h') }" @click="seleccionar_horas('1h')">1h</div>
          <div class="btn_horas" v-bind:class="{ item_horas_seleccionado: (datos.tiempo_grafica == '3h') }" @click="seleccionar_horas('3h')">3h</div>
          <div class="btn_horas" v-bind:class="{ item_horas_seleccionado: (datos.tiempo_grafica == '6h') }" @click="seleccionar_horas('6h')">6h</div>
          <div class="btn_horas" v-bind:class="{ item_horas_seleccionado: (datos.tiempo_grafica == '12h') }" @click="seleccionar_horas('12h')">12h</div>
          <div class="btn_horas" v-bind:class="{ item_horas_seleccionado: (datos.tiempo_grafica == '24h') }" @click="seleccionar_horas('24h')">24h</div>
          <div class="btn_horas" v-bind:class="{ item_horas_seleccionado: (datos.tiempo_grafica == '1s') }" @click="seleccionar_horas('1s')">1s</div>
          <div class="btn_autoload" v-bind:class="{ item_horas_seleccionado: (datos.autoload) }" @click="activar_desactivar_autoload()">Actualización automática</div>
        </div>
        <div v-show="datos.mostrar_grafica" style="    padding-bottom: 10px;">Online: {{datos.tiempo_operativo}}% del tiempo</div>
          <div v-show="datos.mostrar_grafica">
            <canvas class="fondo_grafica" v-bind:class="{ fondo_grafica_oscuro: datos.modo_oscuro }" id="myChart"></canvas>
          </div>
        </div>
    </div>
  </div>
  <div v-show="datos.usuario_aceptado" style="
    display: flex;
    flex-direction: row;
    justify-content: flex-end;">
    <BtnPrimarioNegativo texto="Eliminar proyecto" @click.native="eliminar_proyecto()"></BtnPrimarioNegativo>
  </div>
  <div v-show="datos.usuario_aceptado">
    <div>


      <div v-show="datos.mostrar_cargando">
        <img style="width: 60px; margin-top: 16px; margin-bottom: 8px;" src="../assets/loading.svg">
        <div>Cargando datos...</div>
      </div>

    </div>

  </div>
</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed, onBeforeUnmount} from "vue"
import { useRoute } from 'vue-router'
import BtnPrimario from '../components/BtnPrimario'
import BtnPrimarioNegativo from '../components/BtnPrimarioNegativo'
import BtnAtras from '../components/BtnAtras'
import Chartjs from '../js/chart';
import Swal from 'sweetalert2';

let miGraf = null;
let miSetInterval = null;

export default {
  name: 'ProyectoView',
  components: {
    BtnPrimario,
    BtnPrimarioNegativo,
    BtnAtras
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar


    //Datos de inicio
    datos.id_plan = route.params.id;
    datos.mostrar_menu = true;
    datos.id_proyecto = route.params.id_proyecto;
    datos.tiempo_grafica = '30m';
    datos.nombre_proyecto = '';
    datos.url_proyecto = '';
    datos.estado_actual = '';
    datos.activo = '';
    datos.autoload = true;
    datos.tiempo_operativo = 0;
    datos.mostrar_cargando = true;
    datos.mostrar_grafica = false;
    datos.editando = false;
    datos.contactos_all = [];
    datos.contactos_en_proyecto = [];

    //Configuracion
    datos.prioridad_proyecto = 0
    datos.prioridad_proyecto_tipos = []
    datos.prioridad_proyecto_defecto = 0
    datos.nombre_proyecto_eliminar = "";
    datos.mostrar_cartel_eliminar = false;
    datos.mostrar_contactos = false;



    onMounted(function (){
      store.dispatch("get_all_store",datos.id_plan); //Cargamos lo necesario
    });
    onBeforeUnmount(function (){
      if(miSetInterval != null){
        clearInterval(miSetInterval);
      }
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    mostrar_ocultar_contactos(){
      this.datos.mostrar_contactos = !this.datos.mostrar_contactos;
      if (!this.datos.mostrar_contactos){
        this.get_datos_ultimas_2_horas()
      }
    },
    eliminar_proyecto(){
      console.log('Eliminar pulsado');
      Swal.fire({
        title: '¿Estás seguro que quieres eliminar este proyecto?',
        /*showDenyButton: true,*/
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: 'Si',
        /*denyButtonText: `Desactivar`,*/
        cancelButtonText: 'No',
        preConfirm: async (aaa) => {
          let datos_enviar = {
            id_proyecto: this.datos.id_proyecto,
            id_plan: this.datos.id_plan
          }
          const requestOptions = {
            method: "DELETE",
            headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
            body: JSON.stringify(datos_enviar)
          };
          const response = await fetch(`${this.datos.url_host}/proyecto/${this.datos.id_proyecto}/${this.datos.id_plan}`, requestOptions);
          if (response.status != 200) {
            console.log('ERROR', response.status);
            console.log('data', response);
            Swal.showValidationMessage(
                `Request failed: ${response.status}`
            )
            return response;
          }
        },
        /*preDeny: async (aaa) => {
          let datos_enviar = {
            id_proyecto: this.datos.id_proyecto,
            estado: '0',
            id_plan: this.datos.id_plan
          }
          const requestOptions = {
            method: "PUT",
            headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
            body: JSON.stringify(datos_enviar)
          };
          const response = await fetch(`${this.datos.url_host}/proyecto/estado-check/`, requestOptions);
          if (response.status != 200) {
            console.log('ERROR', response.status);
            console.log('data', response);
            Swal.showValidationMessage(
                `Request failed: ${response.status}`
            )
            console.log('RES OK');
            return response;
          }
        },*/
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        console.log('Result', result);
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          //Swal.fire('Saved!', '', 'success')
          //this.datos.activo = 1;
          let thisStore = this.$store;
          thisStore.dispatch('goTo', `/proyectos/${this.datos.id_plan}`);
          return false;
        } else if (result.isDenied) {
          //Swal.fire('Changes are not saved', '', 'info')
          //this.datos.activo = 0;
        }
        /*if (result.isConfirmed) {
          this.datos.nombre_proyecto = result.value;
        }*/
      })
    },
    editar(editar){
      console.log('Editar pulsado', editar);
      switch(editar) {
        case 'nombre':
          Swal.fire({
            title: 'Nuevo nombre para este proyecto',
            input: 'text',
            inputAttributes: {
              autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Cambiar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            preConfirm: async (valor_texto) => {
              console.log(valor_texto)



              let datos_enviar = {
                id_proyecto: this.datos.id_proyecto,
                nombre: valor_texto,
                id_plan: this.datos.id_plan
              }
              const requestOptions = {
                method: "PUT",
                headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
                body: JSON.stringify(datos_enviar)
              };
              const response = await fetch(`${this.datos.url_host}/proyecto/nombre/`, requestOptions);
              if (response.status != 200) {
                console.log('ERROR', response.status);
                console.log('data', response);
                Swal.showValidationMessage(
                    `Request failed: ${response.status}`
                )
                return response;
              }
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            console.log('result', result);
            if (result.isConfirmed) {
              this.datos.nombre_proyecto = result.value;
            }
          })
          break;
        case 'estadochecking':
          // code block
          Swal.fire({
            title: '¿Activar la monitorización de este proyecto?',
            showDenyButton: true,
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: 'Activar',
            denyButtonText: `Desactivar`,
            cancelButtonText: 'Cancelar',
            preConfirm: async (aaa) => {
              let datos_enviar = {
                id_proyecto: this.datos.id_proyecto,
                estado: '1',
                id_plan: this.datos.id_plan
              }
              const requestOptions = {
                method: "PUT",
                headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
                body: JSON.stringify(datos_enviar)
              };
              const response = await fetch(`${this.datos.url_host}/proyecto/estado-check/`, requestOptions);
              if (response.status != 200) {
                console.log('ERROR', response.status);
                console.log('data', response);
                Swal.showValidationMessage(
                    `Request failed: ${response.status}`
                )
                console.log('RES OK');
                return response;
              }
            },
            preDeny: async (aaa) => {
              let datos_enviar = {
                id_proyecto: this.datos.id_proyecto,
                estado: '0',
                id_plan: this.datos.id_plan
              }
              const requestOptions = {
                method: "PUT",
                headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
                body: JSON.stringify(datos_enviar)
              };
              const response = await fetch(`${this.datos.url_host}/proyecto/estado-check/`, requestOptions);
              if (response.status != 200) {
                console.log('ERROR', response.status);
                console.log('data', response);
                Swal.showValidationMessage(
                    `Request failed: ${response.status}`
                )
                console.log('RES OK');
                return response;
              }
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            console.log('Result', result);
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              //Swal.fire('Saved!', '', 'success')
              this.datos.activo = 1;
            } else if (result.isDenied) {
              //Swal.fire('Changes are not saved', '', 'info')
              this.datos.activo = 0;
            }
            /*if (result.isConfirmed) {
              this.datos.nombre_proyecto = result.value;
            }*/
          })
          break;
        case 'prioridad':
          let cantidad_prioridades = this.datos.prioridad_proyecto_tipos.length;

          let objeto_select = {};
          for (let a = 0; a < cantidad_prioridades; a++){
            const item = this.datos.prioridad_proyecto_tipos[a];
            //this.datos.prioridad_proyecto_tipos.push({text:'Cada 5 minutos', value: 1});
            objeto_select[item.value] = item.text;
          }

          //console.log('Objeto', objeto_select);


          Swal.fire({
            title: 'Selecciona el tiempo de comprobación',
            input: 'select',
            inputOptions: objeto_select,
            inputPlaceholder: 'Tiempo',
            showCancelButton: true,
            confirmButtonText: 'Cambiar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            /*inputValidator: (value) => {
              return new Promise((resolve) => {
                if (value === 'oranges') {
                  resolve()
                } else {
                  resolve('You need to select oranges :)')
                }
              })
            }*/
            preConfirm: async (value) => {
              console.log(value)



              let datos_enviar = {
                id_proyecto: this.datos.id_proyecto,
                prioridad: `${value}`,
                id_plan: this.datos.id_plan
              }
              const requestOptions = {
                method: "PUT",
                headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
                body: JSON.stringify(datos_enviar)
              };
              const response = await fetch(`${this.datos.url_host}/proyecto/prioridad-check/`, requestOptions);
              if (response.status != 200) {
                console.log('ERROR', response.status);
                console.log('data', response);
                Swal.showValidationMessage(
                    `Request failed: ${response.status}`
                )
                return response;
              }
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
                /*console.log('result', result);
                if (result.isConfirmed) {
                  this.datos.nombre_proyecto = result.value;
                }*/
              this.get_info_proyecto();
              })
              break;
      }



    },
    async quitar_contacto(id_contacto){
      let datos_enviar = {
        id_contacto : id_contacto
      }
      const requestOptions = {
        method: "DELETE",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/contactos/proyecto/${this.datos.id_proyecto}/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        console.log('Contactos', datos.data);
        this.get_contactos_completo();
      }
    },
    async anyadir_contacto(id_contacto){
      let datos_enviar = {
        id_contacto : id_contacto
      }
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/contactos/proyecto/${this.datos.id_proyecto}/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        console.log('Contactos', datos.data);
        this.get_contactos_completo();
      }
    },
    async get_contactos_completo(){
      let contactos_totales = [];

      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      let response = await fetch(`${this.datos.url_host}/contactos/all/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      let datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        console.log('Contactos', datos.data);
        //this.datos.contactos_all = datos.data;
        contactos_totales = datos.data;
      }

      response = await fetch(`${this.datos.url_host}/contactos/proyecto/${this.datos.id_proyecto}/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        this.datos.contactos_all = contactos_totales
        this.datos.contactos_en_proyecto = [];
        return false;
      }
      datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        this.datos.contactos_all = contactos_totales
        this.datos.contactos_en_proyecto = [];
      } else {
        console.log('Contactos', datos.data);
        this.datos.contactos_en_proyecto = datos.data;
        if (!datos.data){
          this.datos.contactos_en_proyecto = [];
        }
        let cantidad = contactos_totales.length;
        this.datos.contactos_all = [];
        for (let a = 0; a < cantidad; a++){
          let item = contactos_totales[a];
          let cantidad_contactos_proyecto = this.datos.contactos_en_proyecto.length;
          let contacto_en_proyecto = false;
          for (let e = 0; e < cantidad_contactos_proyecto; e++){
            if (contacto_en_proyecto) continue;
            let miniitem = this.datos.contactos_en_proyecto[e];
            if (miniitem.id == item.id){
              contacto_en_proyecto = true;
            }
          }
          if (!contacto_en_proyecto){
            this.datos.contactos_all.push(item);
          }
        }

        console.log('Contactos', this.datos.contactos_all.length);
      }
    },
    async get_info_proyecto(){
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/registro/status/${this.datos.id_proyecto}/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200){
        let thisStore = this.$store;
        thisStore.dispatch('goTo', `/proyectos/`);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        this.datos.prioridad_proyecto_tipos = [];
        if (this.datos.permisos.max_tipo_check == 1){
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 5 minutos', value: 1});
          //this.datos.prioridad_proyecto_tipos.push({text:'Cada 2 minutos', value: 2});
        } else if (this.datos.permisos.max_tipo_check == 2){
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 5 minutos', value: 1});
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 2 minutos', value: 2});
          //this.datos.prioridad_proyecto_tipos.push({text:'Cada 1 minuto', value: 3});
        } else if (this.datos.permisos.max_tipo_check == 3){
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 5 minutos', value: 1});
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 2 minutos', value: 2});
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 1 minuto', value: 3});
          //this.datos.prioridad_proyecto_tipos.push({text:'Cada 30 segundos', value: 4});
        } else if(this.datos.permisos.max_tipo_check == 4){
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 5 minutos', value: 1});
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 2 minutos', value: 2});
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 1 minuto', value: 3});
          this.datos.prioridad_proyecto_tipos.push({text:'Cada 30 segundos', value: 4});
        }
        let info_proyecto = datos.data.estado_proyecto;
        console.log('Info_proyecto', info_proyecto);
        this.datos.prioridad_proyecto = info_proyecto.tipo_check;
        this.datos.prioridad_proyecto_defecto = this.datos.prioridad_proyecto;
        this.datos.nombre_proyecto = info_proyecto.nombre_web;
        this.datos.url_proyecto = info_proyecto.url;
        this.datos.activo = info_proyecto.activo;
        this.datos.estado_actual = info_proyecto.estado_web;
        this.get_contactos_completo();
      }
    },
    async get_datos_ultimas_2_horas(){
      const tiempo_cargar = this.datos.tiempo_grafica;
      if(!this.datos.mostrar_grafica) this.datos.mostrar_cargando = true;
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      //console.log('Entra en consulta aa', `${this.datos.url_host}/registro/12h/${this.datos.id_proyecto}`);
      const response = await fetch(`${this.datos.url_host}/registro/por_tiempo/${this.datos.id_proyecto}/${this.datos.tiempo_grafica}/${this.datos.id_plan}`, requestOptions);
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        this.datos.mostrar_cargando = false;
        this.datos.mostrar_grafica = false;
      } else {

        if (this.datos.tiempo_grafica != tiempo_cargar) return false;
        //this.datos.proyectos_caidos = datos.data.proyectos;
        console.log(datos.data.registros);

        let cantidad_caido = 0;
        let max_tiempo = 0
        let labels = [];
        let values = [];
        let values_caidos = [];
        let values_caidos_temp = [];
        let registros = datos.data.registros;
        let cantidad = registros.length;
        for (let a = 0; a < cantidad; a++){
          let item = registros[a];
          labels.push(item.inicio);
          let milisegundos = 0;
          let milisegundos_caidos = 0;
          if (item.estado == 1){
            milisegundos = item.milisegundos
            if(milisegundos > max_tiempo){
              max_tiempo = milisegundos
            }
          } else {
            //milisegundos_caidos = item.milisegundos;
            milisegundos_caidos = -1;
            cantidad_caido++;
          }
          values.push(milisegundos);
          values_caidos_temp.push(milisegundos_caidos);
        }

        let cantiadd_caidos = values_caidos_temp.length;
        for (let a = 0; a < cantiadd_caidos; a++){
          let valor = values_caidos_temp[a];
          if (valor < 0){
            valor = max_tiempo;
          }
          values_caidos.push(valor);
        }



        let operativo = ((cantidad-cantidad_caido)*100)/cantidad;
        let opertativo_round = Math.round(operativo * 100) / 100
        console.log('operativo', opertativo_round);
        this.datos.tiempo_operativo = opertativo_round;


        let color_cruces = '#e9e9e9';
        let color_leyenda = '#435861';
        if(this.datos.modo_oscuro){
          color_leyenda = '#e9e9e9';
          color_cruces = '#292f34';
        }


        let data = {
          labels: labels,
          datasets: [{
            fill: true,
            tension: 0.4,
            label: 'Online',
            backgroundColor: 'rgba(15,164,115,0.48)',
            borderColor: '#077652',
            borderWidth: 1,
            data: values,
          }]
        }

        if(cantidad_caido>0){
          data.datasets.push(
              {
                fill: true,
                tension: 0.4,
                label: 'OffLine',
                backgroundColor: 'rgba(164,15,20,0.48)',
                borderColor: '#760707',
                borderWidth: 1,
                type: 'bar',
                data: values_caidos,
              }
          )
        }


        let conf_graf = null;
        conf_graf = {
          type: 'line',
          data,
          options: {
            maintainAspectRatio: false,
            layout: {
              padding: 20
            },
            interaction: {
              intersect: false,
              mode: 'nearest',
            },
            tooltips: {
              enabled: true,
              mode: 'single',
              callbacks: {
                label: function (tooltipItems, data) {
                  var i = tooltipItems.index;
                  return data.labels[i] + ": " + data.datasets[0].data[i] + " %";
                }
              }
            },
            animation: false,
            scales: {
              y: {
                grid:{
                  color:color_cruces,
                },
                type: 'linear',
                min: 0,
                display: true,
                title: {
                  display: true,
                  text: 'Milisegundos',
                  color: color_leyenda,
                },
                ticks: {
                  color:color_leyenda,
                },
              },
              x: {
                grid:{
                  color:color_cruces,
                },
                ticks: {
                  color:color_leyenda,
                  autoSkip: true,
                  maxTicksLimit: 6
                },
                display: true,
                title: {
                  display: false,
                  text: 'Fecha'
                }
              }
            },
            spanGaps: true,
            showLine: true,
            responsive: true,
            elements: {
              point: {
                radius: 2, // default to disabled in all datasets
              }
            },
            plugins: {
              legend: {
                position: 'top',
              },
              /*title: {
                color: color_leyenda,
                display: true,
                text: 'Tiempo de respuesta del servidor'
              }*/
            }
          }
        };

        /*if (miGraf == null){
          console.log('Entra en null');
          miGraf= new Chart(
              document.getElementById('myChart'),
              conf_graf
          );
        } else {
          console.log('Entra en no null');
          miGraf.destroy();
          miGraf = new Chart(document.getElementById('myChart'), conf_graf);
        }*/
        this.datos.mostrar_cargando = false;
        this.datos.mostrar_grafica = true;

        if (miGraf) {
          miGraf.destroy();
        }
        miGraf = new Chart(document.getElementById('myChart'), conf_graf);

        document.getElementById('myChart').style.maxWidth = "100%";
        document.getElementById('myChart').style.minWidth = "100%";
        /*document.getElementById('myChart').style.marginLeft = "auto";
        document.getElementById('myChart').style.marginRight = "auto";*/
        document.getElementById('myChart').style.minHeight = "550px";
        document.getElementById('myChart').style.maxHeight = "600px";

        //datos.modo_oscuro
      }
    },
    seleccionar_horas(horas){
      this.datos.mostrar_grafica = false;
      this.datos.mostrar_cargando = true;
      if(miSetInterval != null){
        clearInterval(miSetInterval);
      }
      let segundos = 15;
      if (horas == '1s' || horas == '24h' || horas == '12h'){
        segundos = 150;
      }
      console.log(horas);
      this.datos.tiempo_grafica = horas;
      this.get_datos_ultimas_2_horas();
      if (this.datos.autoload){
        if (miSetInterval != null){
          clearInterval(miSetInterval);
        }
        miSetInterval = null;
        miSetInterval = setInterval(() => {
          this.get_datos_ultimas_2_horas();
          this.get_info_proyecto();
        }, segundos*1000);
      }
    },
    activar_desactivar_autoload(){
      this.datos.autoload = !this.datos.autoload;
      console.log('autoload', this.datos.autoload)
      if (!this.datos.autoload){
        if (miSetInterval != null){
          clearInterval(miSetInterval);
        }
      } else {
        if (miSetInterval != null){
          clearInterval(miSetInterval);
        }
        miSetInterval = null;
        miSetInterval = setInterval(() => {
          this.get_datos_ultimas_2_horas();
          this.get_info_proyecto();
        }, 15000);
      }
    }
  },
  async mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    //this.check_token();
    this.get_datos_ultimas_2_horas();
    this.get_info_proyecto();
    miSetInterval = setInterval(() => {
      this.get_datos_ultimas_2_horas();
      this.get_info_proyecto();
    }, 15000);



  },
}
</script>
<style lang="scss" scoped>
@import '../styles/variables.css';
.form_item div:first-child{
  font-size: 18px;
  font-weight: bold;
}
.form_item div:last-child{
  font-size: 14px;
}


.form_item small{
  margin-left: 10px;
  cursor: pointer;
}
.form_item small:hover{
  color: var(--primary-color-1);
}

.form_item a small{
  margin-left: 0px;
  cursor: pointer;
}



.contenedor_botonera{
  margin-top: 30px;
  margin-bottom: 46px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-right: 30px;
}
.btn_horas{
  background-color: var(--primary-color-1);
  color:#FFF;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 4px;
  cursor: pointer;
}
.btn_horas:hover{
  background-color: var(--primary-color-2);
}
.item_horas_seleccionado{
  background-color: var(--accent-color-2)!important;
}
.item_horas_seleccionado:hover{
  background-color: var(--accent-color-3)!important;
}
.btn_autoload{
  background-color: #919191;
  color:#FFF;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-left: 6px;
  margin-right: 6px;
  border-radius: 4px;
  cursor: pointer;
}
.btn_autoload:hover{
  background-color: #525252;
}
@media screen and (max-width: 991px){
  .contenedor_botonera{
    flex-wrap: wrap!important;
  }
  .btn_horas{
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.fondo_grafica{
  background-color: var(--fondo-menu-claro-theme);
}
.fondo_grafica_oscuro{
  background-color: var(--fondo-menu-dark-theme)!important;
}

.row_titulo{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: space-around;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--primary-color-2);
  background-color: var(--primary-color-4);
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;

}
.item_row{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #f9fafb;
  padding-top: 6px;
  padding-bottom: 6px;
}
.item{
  width: 20%;
}
.item_acciones{
  width: 40%;
}
.item_acciones_btn{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.grupo{
  margin: 10px;
  border-color: var(--primary-color-5);
  background-color: var(--fondo-menu-claro-theme);
  border-style: solid;
  border-width: 1px;
}
.grupo .titulo{
  font-size: 22px;
  background-color: var(--primary-color-5);
  color: #FFF;
  padding-top: 4px;
  padding-bottom: 10px;
}

.grupo_oscuro{
  border-color: var(--primary-color-1);
  background-color: var(--fondo-menu-dark-theme)!important;
}
.grupo_oscuro .titulo{
  background-color: var(--primary-color-1);
}
.grupo_oscuro .row_titulo{
  background-color: var(--primary-color-4);
  border-bottom-color: var(--primary-color-5);
}
@media screen and (max-width: 991px){
  .row_titulo{
    display: none!important;
  }
  .item{
    width: 100%;
  }
  .item_row{
    flex-wrap: wrap!important;
  }
}

@media screen and (max-width: 991px){
  .item_100_cartel .form_item {
    width: 50%;
    text-align: center;
  }
}
@media screen and (max-width: 425px){
  .item_100_cartel .form_item {
    width: 100%;
    text-align: center;
  }
}

</style>
