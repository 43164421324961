<template>
  <div class="contenedor_responsive">
    <menu-p-c></menu-p-c>
    <div class="subcontenedor_responsive">
      <menu-movil></menu-movil>
      <div class="contenedor_paginas"><router-view/></div>
    </div>
  </div>


  <!--<menu-p-c></menu-p-c>-->
  <!--<menu-movil></menu-movil>-->


  <!--<div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </div>-->

</template>
<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'

import MenuPC from "./components/MenuPC";
import MenuMovil from "./components/MenuMovil";

export default {
  components: {
    MenuPC,
    MenuMovil
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    if (datos.usuario == undefined){
      datos.usuario = {
        id: 0,
        nivel: 0,
        email: '',
        nombre: '',
        apellidos: '',
        iniciales: '',
      }
    }
    datos.max_proyectos_permitidos = 0;
    datos.variables_test.hola = '1';

    onMounted(function (){
      console.log('Comprobar URL 5', route.fullPath)
      if (datos.modo_oscuro){
        document.body.classList.add("fondo_dark_theme");
        document.getElementById('app').classList.add("texto_dark_theme");
      } else {
        document.body.classList.remove("fondo_dark_theme")
        document.getElementById('app').classList.remove("texto_dark_theme");
      }
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    async comprobar_token_usuario() {
      let thisStore = this.$store;
      let route = this.$route;
      console.log('Comprobar URL', route.fullPath)
      /*if (localStorage.token == undefined) {
        if (route.fullPath == '/login') return false;
        if (route.fullPath == '/registro') return false;
        //return false;
        thisStore.dispatch('goTo', `/login`);
        return false;
      }*/

      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/account/checktoken`, requestOptions);
      if (response.status != 200) {
        let thisStore = this.$store;
        localStorage.removeItem('token');
        /*thisStore.dispatch('goTo', `/login`);
        return false;*/
      }

      //Respuesta 200
      const respuestaOK = await response.json();
      console.log('Token valido');
      console.log(respuestaOK);
      if(respuestaOK.code == '00'){
        this.datos.usuario_aceptado = true;
        console.log(respuestaOK.data);
        let nombre = respuestaOK.data.nombre.trim();
        let apellidos = respuestaOK.data.apellidos.trim();
        let iniciales = `${nombre.toUpperCase().substr(0,1)}${apellidos.toUpperCase().substr(0,1)}`;

        this.datos.usuario = {
          id: respuestaOK.data.id,
          nivel: respuestaOK.data.nivel,
          email: respuestaOK.data.email,
          nombre: nombre,
          apellidos: apellidos,
          iniciales: iniciales,
        }
        this.datos.modo_oscuro = respuestaOK.data.modo_oscuro;

        if (this.datos.modo_oscuro){
          document.body.classList.add("fondo_dark_theme");
          document.getElementById('app').classList.add("texto_dark_theme");
        } else {
          document.body.classList.remove("fondo_dark_theme")
          document.getElementById('app').classList.remove("texto_dark_theme");
        }
        if (respuestaOK.data.nivel == 1){
          this.datos.max_proyectos_permitidos = 5;
        } else if (respuestaOK.data.nivel == 2){
          this.datos.max_proyectos_permitidos = 10;
        } else if (respuestaOK.data.nivel == 3){
          this.datos.max_proyectos_permitidos = 20;
        } else if (respuestaOK.data.nivel > 3){
          this.datos.max_proyectos_permitidos = 100;
        }
        console.log('Comprobar URL 2', route.fullPath)
        console.log('Carga datos usuario');
        if (window.location.pathname == '/'){
          thisStore.dispatch('goTo', `/selectaccount`);
        }
        console.log('Comprobar URL 3', route.fullPath)
        return false;
      } else {
        localStorage.removeItem('token');
        //TODO: Otro tipo de error
        /*thisStore.dispatch('goTo', `/login`);
        return false;*/
      }
    },
  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    this.comprobar_token_usuario();
  },
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP&family=Noto+Serif&family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import './styles/variables.css';
#app {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--color-texto-principal);
  a {
    color: inherit;
    text-decoration: inherit;
  }

}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: var(--primary-color-5);

    &.router-link-exact-active {
      color: var(--accent-color-3);
    }
  }
}




/***/
.contenedor_responsive{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: flex-start;
}
.subcontenedor_responsive{
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: flex-start;
  align-items: stretch;
}
.contenedor_paginas{
  text-align: center;
  padding-top: 0px;
}
.fondo_dark_theme{
  background-color: var(--fondo-dark-theme);
}
.texto_dark_theme{
  color: var(--color-texto-principal-dark-theme)!important;
}
@media screen and (max-width: 991px){
  .contenedor_paginas{
    /*padding-top: 60px;*/
  }
}



html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body{
  background-color: var(--fondo-claro-theme);
  margin: 0;
}

</style>
