<template>
  <div v-show="datos.usuario_aceptado">
    <!--
    <form action="http://localhost:3001/test/pago-tarjeta" method="post" id="payment-form">
      <div class="form-row">
        <label for="card-element">
          Credit or debit card
        </label>
        <div id="card-element">
        </div>

        <div id="card-errors" role="alert"></div>
      </div>

      <button>Submit Payment</button>
    </form>
          -->

    <div>
      <label for="card-element">
        Credit or debit card
      </label>
      <div id="card-element">
        <!-- A Stripe Element will be inserted here. -->
      </div>
      <!-- Used to display Element errors. -->
      <div id="card-errors" role="alert"></div>

      <input type="text" placeholder="Número tarjeta" v-model="datos.tarjeta.numero" required>
      <input type="text" placeholder="MM" v-model="datos.tarjeta.mm" required>
      <input type="text" placeholder="AA" v-model="datos.tarjeta.aa" required>
      <input type="text" placeholder="CVC" v-model="datos.tarjeta.cvc" required>
      <div @click.native="pagar()">ENVIAR</div>
      <div @click.native="pagar2()">ENVIAR 2</div>
    </div>

    <div style="height: 60px">

    </div>
    <div @click.native="pagar_licencia_unica()">Comprar licencia unica</div>
    <div @click.native="pagar_licencia_varios()">Comprar licencia 12 meses</div>


  </div>
</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'



// https://js.stripe.com/v3/

/*const ctx = document.getElementById('example');
const exampleChart = new Chart(ctx, {
  type: '',
  data: [],
  options: {},
});*/

let stripe = Stripe('pk_test_51JM9HNKpqgcSWXGVPFHmLfi391Nn4USpQnyapUF2eVo8HiG8vwuOVcpcRT8PAEmvdcWvyTI6wtdgfDPWejFSwoOm009IXa5JiF');
let elements = stripe.elements();

let style = {
  base: {
    // Add your base input styles here. For example:
    fontSize: '16px',
    color: '#32325d',
  },
};
let card = elements.create('card', {style: style});


export default {
  name: 'Test',
  components: {
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    //Datos de inicio
    datos.mostrar_menu = true;
    datos.mostrar_items_menu = false;


    //STRIPE
    datos.tarjeta = {
      numero: '',
      mm: '',
      aa: '',
      cvc: ''
    }


    datos.menu_padre_seleccioando = 'Test';


    onMounted(function (){
      //console.log('Variable global',datos);
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    pagar(){
      let context = this;
      stripe.createToken(card).then(function(result) {
        if (result.error) {
          // Inform the customer that there was an error.
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
          console.log('ERROR');
          console.log(result);
        } else {
          console.log('correcto');
          console.log(result);
          console.log(result.token);
          // Send the token to your server.
          context.stripeTokenHandler(result.token);
        }
      });
    },

    async stripeTokenHandler(token) {
      let datos_enviar = this.datos.tarjeta;
      datos_enviar.stripeToken = token.id
      // Insert the token ID into the form so it gets submitted to the server
      /*var form = document.getElementById('payment-form');
      var hiddenInput = document.createElement('input');
      hiddenInput.setAttribute('type', 'hidden');
      hiddenInput.setAttribute('name', 'stripeToken');
      hiddenInput.setAttribute('value', token.id);
      form.appendChild(hiddenInput);*

      // Submit the form
      form.submit();*/

      console.log('datos-enivar',datos_enviar)

      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/test/pago-tarjeta`, requestOptions);
      if (response.status != 200) {
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00') {
        console.log(response);
        console.log(datos);
      } else {
        console.log('Contactos', datos.data);
      }
    },

    async pagar2() {
      let datos_enviar = this.datos.tarjeta;
      //datos_enviar.stripeToken = token.id
      // Insert the token ID into the form so it gets submitted to the server
      /*var form = document.getElementById('payment-form');
      var hiddenInput = document.createElement('input');
      hiddenInput.setAttribute('type', 'hidden');
      hiddenInput.setAttribute('name', 'stripeToken');
      hiddenInput.setAttribute('value', token.id);
      form.appendChild(hiddenInput);*

      // Submit the form
      form.submit();*/

      console.log('datos-enivar', datos_enviar)

      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/test/pago-tarjeta`, requestOptions);
      if (response.status != 200) {
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00') {
        console.log(response);
        console.log(datos);
      } else {
        console.log('recibido', datos.data);
        let info_apago = datos.data;
        window.location = info_apago.url;
      }
    },
    async pagar_licencia_unica() {
      let id_plan = 1;
      let id_licencia = 2;
      let datos_enviar = {
        id_licencia: id_licencia,
      };
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/licencias/comprar/${id_plan}`, requestOptions);
      if (response.status != 200) {
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00') {
        console.log(response);
        console.log(datos);
      } else {
        console.log('recibido', datos.data);
        let info_apago = datos.data;
        window.location = info_apago.url;
      }
    },
    async pagar_licencia_varios() {
      let id_plan = 1;
      let id_licencia = 6;
      let datos_enviar = {
        id_licencia: id_licencia,
      };
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/licencias/comprar/${id_plan}`, requestOptions);
      if (response.status != 200) {
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00') {
        console.log(response);
        console.log(datos);
      } else {
        console.log('recibido', datos.data);
        let info_apago = datos.data;
        window.location = info_apago.url;
      }
    },


  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    //this.check_token();
    console.log('Datos', this.datos);






    // Add an instance of the card Element into the `card-element` <div>.
    card.mount('#card-element');



    ///// paso 3
    // Create a token or display an error when the form is submitted.
   /* var form = document.getElementById('payment-form');
    form.addEventListener('submit', function(event) {
      event.preventDefault();

      stripe.createToken(card).then(function(result) {
        if (result.error) {
          // Inform the customer that there was an error.
          var errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          // Send the token to your server.
          stripeTokenHandler(result.token);
        }
      });
    });
    ////paso 4
    function stripeTokenHandler(token) {
      // Insert the token ID into the form so it gets submitted to the server
      var form = document.getElementById('payment-form');
      var hiddenInput = document.createElement('input');
      hiddenInput.setAttribute('type', 'hidden');
      hiddenInput.setAttribute('name', 'stripeToken');
      hiddenInput.setAttribute('value', token.id);
      form.appendChild(hiddenInput);

      // Submit the form
      form.submit();
    }*/
  },
}
</script>
