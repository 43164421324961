import { createStore } from 'vuex'

export default createStore({
  state: {
    variables:{
      version: '0.4.8',
      usuario_aceptado: false,
      modo_oscuro: true,
      //url_host: 'http://localhost:3001',
      url_host: 'https://api.failder.com',
      //url_host: 'http://192.168.8.202:3001',
      permisos: {
        crear_cuenta: true,
        crear_proyecto: false,
        crear_contacto: false,
      },
      metodos_pago:{
        stripe: true,
        paypal: false,
      },
      info_cuenta: {
        nombre: ''
      },
      info_tipo_licencia: {},
      info_licencia_activa: {},
      info_resumen: {},
      info_licencias_pendientes: null,
      datos_facturacion:  {
        id_cuenta: '',
        empresa: '',
        nombre: '',
        apellidos: '',
        nif: '',
        direccion: '',
        codigo_postal: '',
        ciudad: '',
        provincia: '',
        pais: '',
        telefono: '',
        email: '',
      },
      variables_test:{

      }
    },
  },
  mutations: {
    set_variables(state, variables){
      state.variables = variables;
    },
    set_permisos(state, permisos){
      state.variables.permisos = permisos;
    },
    set_licencia(state, info){
      if (info.info_licencia_activa == null){
        info.info_licencia_activa = {};
        info.info_licencia_activa.nombre = '--';
        info.info_licencia_activa.codigo = '--';
      }

      if (info.info_tipo_licencia == null){
        info.info_tipo_licencia = {};
        info.info_tipo_licencia.nombre = '--';
      }

      info.info_cuenta.nombre_actual_plan = info.info_cuenta.nombre;

      state.variables.info_cuenta = info.info_cuenta;
      state.variables.info_tipo_licencia = info.info_tipo_licencia;
      state.variables.info_licencia_activa = info.info_licencia_activa;
    },
    set_resumen(state, resumen){
      state.variables.info_resumen = resumen;
    },
    set_facturacion(state, facturacion){
      state.variables.datos_facturacion = facturacion;
    },
    set_licencias_pendientes(state, licencias){
      state.variables.info_licencias_pendientes = licencias;
    }
  },
  actions: {
    setVariables({commit, state, dispatch}, variables) {
      commit('set_variables', variables);
    },
    goTo({commit, state, dispatch}, url) {
      console.log('cargar', url);
      if (window.location.pathname != url) {
        window.location.href = url;
      }
    },
    async get_all_store({commit, state, dispatch}, id_cuenta){
      dispatch("get_permisos_store",id_cuenta);
      dispatch("get_licencia_store",id_cuenta);
      dispatch("get_resumen_store",id_cuenta);
      dispatch("get_facturacion_store", id_cuenta);
      dispatch("get_licencias_pendientes_store", id_cuenta);
    },
    async get_permisos_store({commit, state, dispatch}, id_cuenta) {
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${state.variables.url_host}/permisos/${id_cuenta}`, requestOptions);
      if (response.status != 200) {
        /*let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);*/
        dispatch("goTo",'/selectaccount/');
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00') {
        console.log(response);
        console.log(datos);
      } else {
        let permisos = datos.data;
        console.log('Permisos_recibidos', permisos);
        commit('set_permisos', permisos);
      }
    },
    async get_licencia_store({commit, state, dispatch}, id_cuenta) {
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${state.variables.url_host}/licencia/activa/${id_cuenta}`, requestOptions);
      if (response.status != 200) {
        /*let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);*/
        //dispatch("goTo",'/plans/');
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00') {
        console.log(response);
        console.log(datos);
      } else {
        let datos_recibidos = datos.data;

        //this.datos.nombre_actual_plan = this.datos.info_cuenta.nombre;


        let licencia = {};
        licencia.info_cuenta = datos_recibidos.info_cuenta;
        licencia.info_tipo_licencia = datos_recibidos.info_tipo_licencia;
        licencia.info_licencia_activa = datos_recibidos.info_licencia_activa;
        console.log('INFO licencia', licencia);
        commit('set_licencia', licencia);

        //Resumen
        //this.datos.resumen_licencia.sms_enviados = (this.datos.info_tipo_licencia.cantidad_sms - this.datos.info_cuenta.sms_disponibles)
        //this.datos.resumen_licencia.proyectos_usados = this.datos.info_tipo_licencia.cantidad_proyectos - (this.datos.info_tipo_licencia.cantidad_proyectos - this.datos.info_cuenta.proyectos_disponibles)


      }
    },
    async get_resumen_store({commit, state, dispatch}, id_cuenta) {
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${state.variables.url_host}/dashboard/resumen_plan/${id_cuenta}`, requestOptions);
      if (response.status != 200) {
        /*let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);*/
        dispatch("goTo",'/selectaccount/');
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00') {
        console.log(response);
        console.log(datos);
      } else {
        let resumen = datos.data;
        console.log('INFO RESUMEN', resumen);
        commit('set_resumen', resumen);
      }
    },
    async get_facturacion_store({commit, state, dispatch}, id_cuenta) {
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${state.variables.url_host}/facturacion/${id_cuenta}`, requestOptions);
      if (response.status != 200) {
        /*let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);*/
        dispatch("goTo",'/selectaccount/');
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00') {
        console.log(response);
        console.log(datos);
      } else {
        let facturacion = datos.data;
        console.log('INFO facturacion', facturacion);
        commit('set_facturacion', facturacion);
      }
    },
    async get_licencias_pendientes_store({commit, state, dispatch}, id_cuenta) {
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${state.variables.url_host}/licencias/pendientes/${id_cuenta}`, requestOptions);
      if (response.status != 200) {
        /*let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);*/
        dispatch("goTo",'/selectaccount/');
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00') {
        console.log(response);
        console.log(datos);
      } else {
        let licencias_pendientes = datos.data;
        console.log('INFO licencias pendientes', licencias_pendientes);
        commit('set_licencias_pendientes', licencias_pendientes);
      }
    },
  },
  modules: {
  }
})
