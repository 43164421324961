<template>
  <div>
    <div class="btn_class" v-bind:class="{ btn_class_oscuro: datos.modo_oscuro }">
      <font-awesome-icon :icon="['fa', 'arrow-left']" class="icon alt"/> {{texto}}
    </div>
  </div>

</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed, onBeforeUnmount} from "vue"
export default {
  name: 'BtnAtras',
  props: {
    texto: String,
  },
  setup(){
    const store = useStore();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    return {
      variables: variables,
      datos: datos,
    }
  },
}
</script>

<style scoped lang="scss">
@import '../styles/variables.css';
.btn_class {
  /*background-color: var(--negativo-color-1);
  color: #FFF;*/
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
  margin:8px;

  border-style: solid;
  border-width: 1px;
  border-color: var(--primary-color-5);
  /*background-color: var(--fondo-menu-claro-theme);*/
}
.btn_class_oscuro {
  border-style: solid;
  border-width: 1px;
  border-color: var(--primary-color-1);
  /*background-color: var(--fondo-menu-dark-theme)!important;*/
}
.btn_class:hover{

  color: var(--color-texto-principal-dark-theme);
  background-color: var(--primary-color-5);
}
.btn_class_oscuro:hover{
  color: var(--color-texto-principal);
  background-color: var(--primary-color-1);
}
</style>
