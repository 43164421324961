<template>
  <div class="super_cartel">
    <div class="cartel_info" v-bind:class="{ cartel_info_oscuro: datos.modo_oscuro }">
      <div style="text-align: center;width: 100%;">
        <div class="consulta" v-show="(activo == 1)">
          Hace {{ segundos }}
        </div>
        <div class="consulta" v-show="(activo == 0 || activo == 2 || activo == 3)">
          Proyecto inactivo
        </div>
        <div class="titulo">
          {{ nombre_proyecto }}
        </div>
        <div class="info" v-show="!online && (activo==1)">
          Offline desde
        </div>
        <div class="info" v-show="online && (activo==1)">
          Online desde
        </div>
        <div class="info" style="margin-bottom: 16px;">
          {{ fecha }}
        </div>

        <!--<div class="info">
          {{url}}
        </div>-->
        <div class="botones">
          <a :href="url" target="_blank">
              <div class="div_boton" v-bind:class="{ div_boton_oscuro: datos.modo_oscuro }">
                <font-awesome-icon :icon="['fas', 'external-link-alt']" class="icon alt"/>
              </div>
          </a>
          <router-link :to="'/proyecto/'+id+'/configuracion/'+datos.id_plan">
              <div class="div_boton" v-bind:class="{ div_boton_oscuro: datos.modo_oscuro }">
                <font-awesome-icon :icon="['fas', 'cog']" class="icon alt"/>
              </div>
          </router-link>
          <router-link :to="'/proyecto/'+id+'/estado/'+datos.id_plan">
              <div class="div_boton" v-bind:class="{ div_boton_oscuro: datos.modo_oscuro }">
                <font-awesome-icon :icon="['fas', 'chart-line']" class="icon alt"/>
              </div>
          </router-link>



        </div>
      </div>
      <div class="barra_color" style="background-color: #f35959" v-show="!online && (activo==1)"></div>
      <div class="barra_color" v-show="online && (activo==1)"></div>
      <div class="barra_color" style="background-color: #303238" v-show="(activo == 0 || activo == 2 || activo == 3)"></div>
    </div>
  </div>

</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
export default {
  name: 'TarjetaInfoEstadoProyecto',
  props: {
    fecha: String,
    nombre_proyecto: String,
    online: Number,
    url: String,
    activo: Number,
    fecha_consulta: String,
    segundos:String,
    id: Number,
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    //Datos de inicio
    datos.id_plan = route.params.id;

    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    abrir_url(data){
      //      console.log('Abrir', data);
      window.open(data,'_blank');
      /*let thisStore = this.$store;
      console.log('Abrir página', pagina);
      thisStore.dispatch('goTo', `/${pagina}`);*/
    },
    abrir_configuracion(data){
      console.log('Configuracion', data);
      let thisStore = this.$store;
      thisStore.dispatch('goTo', `/proyecto/${data}/configuracion`);
    },
    abrir_graficas(data){
      console.log('Graficas', data);
      let thisStore = this.$store;
      thisStore.dispatch('goTo', `/proyecto/${data}/estado`);
    }

  },
}
</script>

<style scoped lang="scss">
.div_boton{
  width: 36px;
  height: 36px;
  border-radius: 40px;
  cursor:pointer;
}
.div_boton:hover{
  background-color: var(--accent-color-2);
  color: #FFFFFF;
}
.div_boton_oscuro:hover{
  background-color: var(--primary-color-1);
  color: #FFFFFF;
}
.div_boton svg{
  width: 100%;
  height: 100%;
  padding: 8px;
}
.botones{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 6px;
}
.consulta{
  font-size: 12px;
  text-align: left;
  margin-left: 2px;
  margin-bottom: 16px;
}
.cartel_info{
  margin: 16px;
  /*padding: 16px;*/
  background-color: var(--fondo-menu-claro-theme);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-between;
  align-items: stretch;
  border-radius: 4px;
  border-color: var(--primary-color-1);
  border-width: 1px;
  border-style: solid;
  /*-webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);*/
}
.cartel_info_oscuro{
  background-color: var(--fondo-menu-dark-theme);
}
.cartel_info .barra_color{
  width: 6px;
  background-color: #59f37b;
  margin-left: 20px;
  border-radius: 50px;
}
.cartel_info .titulo{
  font-weight: bold;
}
.cartel_info .info{
  font-size: 14px;
}
@media screen and (min-width: 992px){
  .super_cartel{
    width: 33%;
  }
}
@media screen and (min-width: 1300px){
  .super_cartel{
    width: 25%;
  }
}
@media screen and (min-width: 1600px){
  .super_cartel{
    width: 20%;
  }
}
@media screen and (max-width: 991px){
  .super_cartel{
    width: 50%;
  }
}
@media screen and (max-width: 660px){
  .super_cartel{
    width: 100%;
  }
}

</style>
