<template>
  <div class="barra_superior">
    <div>
      <a href="https://failder.com">
        <div><img alt="Failder" src="../assets/LOGO_Blue-Black.png"> </div>
      </a>
    </div>
  </div>
  <div>
    <div class="fondo">
    </div>
    <div class="contenedor">
      <div class="escritorio bloque1">
        <div class="div_info">
          <div class="logo_descripcion">
            <a href="https://failder.com">
              <div><img alt="Failder" src="../assets/LOGO_White.png"> </div>
            </a>
          </div>
          <!--<div class="titulo">
            Failder
          </div>-->
          <div class="info">
            Monitoreo gratuito del tiempo de actividad que todo sitio web necesita. Pruebas de disponibilidad, velocidad de carga y comprobaciones de certificados SSL.
          </div>
        </div>
      </div>
      <div class="bloque2 form">
        <div class="div_login">
          <div class="titulo">
            Cambio de contraseña
          </div>
          <div class="info" style="margin-bottom: 32px;">
            Has solicitado un cambio de contraseña.
          </div>
          <div v-show="datos.visibleloading">
            <img style="width: 30px; margin-top: 16px; margin-bottom: 8px;" src="../assets/loading.svg">
          </div>
          <div v-show="datos.visiblepass">
            <div class="item_form">
              <div class="titulo_form">
                Escribe la nueva contraseña
              </div>
              <div>
                <input ref="txt_pass" type="password" placeholder="Contraseña" v-model="datos.pass" v-on:keyup="validatePass" required >
              </div>
              <div v-show="datos.visiblebtnverpass" style="text-align: left;">
                <btn-alternativo-transparente texto="Mostrar contraseña"></btn-alternativo-transparente>
              </div>
              <div v-show="!datos.visiblebtnverpass" style="text-align: left;">
                <btn-alternativo-transparente texto="Ocultar contraseña"></btn-alternativo-transparente>
              </div>
            </div>
          </div>

          <div class="div_botonera">
            <!--<btn-alternativo-transparente texto="Recordar contraseña" v-show="datos.visiblerecordarpass"></btn-alternativo-transparente>-->
            <btn-primario texto="Cambiar contraseña" @click.native="siguiente()"></btn-primario>
          </div>
          <div v-show="datos.view_div_error"  class="div_error">
            <div class="div_title_error">{{ datos.div_titulo_mensaje_error }}</div>
            <div class="div_info_error">{{ datos.div_info_mensaje_error }}</div>
          </div>
        </div>
        <div>
          <div class="div_links">
            <router-link :to="'/contacto/'"><btn-alternativo-transparente texto="Contacto"></btn-alternativo-transparente></router-link>
            <router-link :to="'/politica-de-privacidad/'"><btn-alternativo-transparente texto="Privacidad"></btn-alternativo-transparente></router-link>
            <router-link :to="'/terminos-y-condiciones/'"><btn-alternativo-transparente texto="Términos y condiciones"></btn-alternativo-transparente></router-link>
          </div>
        </div>
        <!--<a href="https://cubel.dev" target="_blank">
          <div class="copy4" style="margin-top:60px;">
            Failder © 2021. Desarrollo por Cubel.dev
          </div>
        </a>-->
      </div>
    </div>
    <!--<div class="copy">
      <a href="https://cubel.dev" target="_blank">
        <div class="copy1">
          Failder © 2021. Desarrollo por Cubel.dev
        </div>
      </a>
      <div class="copy2">
        <router-link :to="'/politica-de-privacidad/'"><btn-alternativo-transparente texto="Privacidad"></btn-alternativo-transparente></router-link>
        <router-link :to="'/contacto/'"><btn-alternativo-transparente texto="Contacto"></btn-alternativo-transparente></router-link>
        <router-link :to="'/terminos-y-condiciones/'"><btn-alternativo-transparente texto="Términos y condiciones"></btn-alternativo-transparente></router-link>

      </div>
    </div>-->

  </div>
</template>

<script>
import sha1 from 'js-sha1';
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import BtnPrimario from '../components/BtnPrimario'
import BtnAlternativoTransparente from '../components/BtnAlternativoTransparente'
import Swal from 'sweetalert2';

export default {
  name: 'CP',
  components: {
    BtnPrimario,
    BtnAlternativoTransparente
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar
    datos.imagen_fondo = '550113.jpg';
    datos.visiblepass = true;
    datos.visibleloading = false;
    datos.visiblemail = false;
    datos.email = '';
    datos.pass = '';
    datos.salt_recibido = '';
    datos.check_recordar_pass = false;
    datos.view_div_error = false;
    datos.div_titulo_mensaje_error = '';
    datos.div_info_mensaje_error = '';
    datos.token = route.params.token;

    datos.visiblebtnverpass = true;

    onMounted(function (){
      //console.log('Variable global',datos);
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    cargar_fondo_de_pantalla(){
      let array_fondos = ['550113.jpg', '555680.jpg', '555683.jpg','555684.jpg','555705.jpg','817972.jpg','828878.jpg','884555.jpeg','1047595.jpg','1074989.jpg','1080706.jpg'];
      //document.getElementById('fondo').style.backgroundImage=`url(../assets/${array_fondos[2]})`; // specify the image path here
      this.datos.imagen_fondo = array_fondos[2];
    },
    siguiente(){
      this.new_salt();
    },
    escribir_mensaje_error(titulo, mensaje){
      if (titulo == '' && mensaje == ''){
        this.datos.view_div_error = false;
      } else {
        this.datos.view_div_error = true;
      }
      this.datos.div_titulo_mensaje_error = titulo;
      this.datos.div_info_mensaje_error = mensaje;
    },
    validateMail: function(e){
      if (e.keyCode === 13) {
        this.check_mail();
      }
    },
    validatePass(e){
      if (e.keyCode === 13) {
        this.new_salt();
      }
    },
    async check_mail() {
      this.datos.visiblemail = false;
      this.datos.visibleloading = true;
      this.escribir_mensaje_error('','');
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      };
      await this.sleep(500);
      const response = await fetch(`${this.datos.url_host}/account/salt/${this.datos.mail.trim()}`, requestOptions);
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        //this.escribir_mensaje_error(`Error código ${datos.code}`, datos.message);
        this.datos.visiblemail = false;
        this.datos.visibleloading = false;
        this.datos.visiblepass = true;
      } else {
        this.escribir_mensaje_error(`Ya existe la cuenta`, `El usuario ${this.datos.mail.trim()} ya existe`);
        this.datos.visiblemail = true;
        this.datos.visibleloading = false;

        //this.datos.salt_recibido = datos.data.salt;
        /*this.view_fragment_1 = !this.view_fragment_1;
        this.view_fragment_2 = !this.view_fragment_2;*/
        //this.$nextTick(() => this.$refs.txt_pass.focus());
      }
    },
    async new_salt() {
      this.datos.visiblepass = false;
      this.datos.visibleloading = true;
      this.escribir_mensaje_error('', '');


      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      };
      await this.sleep(500);
      //let sesion = 0;
      //if (this.datos.check_recordar_pass) sesion = 1
      console.log('emailssss',this.datos.email);
      console.log('emailssss',this.datos.email.trim());
      const response = await fetch(`${this.datos.url_host}/account/newsalt/${this.datos.email.trim()}`, requestOptions);
      const datos = await response.json();

      if (datos.code != '00'){
        this.escribir_mensaje_error(`Error código ${datos.code}`, datos.message);
        console.log(response);
        console.log(datos);
        this.datos.visiblepass = true;
        this.datos.visibleloading = false;
      } else {
        this.datos.salt_recibido = datos.data;
        console.log('SALT',this.datos.salt_recibido);
        //localStorage.token = datos.data.token;
        //window.location.href = "/";
        //let thisStore = this.$store;
        //thisStore.dispatch('goTo', `/`);
        this.cambiar_pass();
      }
    },
    async cambiar_pass() {
      this.datos.visiblepass = false;
      this.datos.visibleloading = true;
      this.escribir_mensaje_error('', '');

      let token = this.datos.token;
      let pass_completo = sha1(this.datos.pass + this.datos.salt_recibido) + ':' + this.datos.salt_recibido;

      let datos_enviar = {
        np: pass_completo,
        mail: this.datos.email.trim(),
        token: token,
      }

      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": 'falsa'},
        body: JSON.stringify(datos_enviar)
      };


      const response = await fetch(`${this.datos.url_host}/account/new-pass/${token}`, requestOptions);
      const datos = await response.json();

      if (datos.code != '00'){
        this.escribir_mensaje_error(`Error código ${datos.code}`, datos.message);
        console.log(response);
        console.log(datos);
        this.datos.visiblepass = true;
        this.datos.visibleloading = false;
        /*this.datos.visiblemail = true;
        this.datos.pass = '';*/

        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Se ha produccido un error, intentalo de nuevo.',
          confirmButtonText: 'Aceptar',
        })
      } else {
        this.datos.visiblepass = true;
        this.datos.visibleloading = false;
        //this.check_pass();

        Swal.fire({
          icon: 'success',
          title: '¡Contraseña cambiada correctamente!',
          text: 'Ya puedes hacer login con tu nueva contraseña',
          confirmButtonText: 'Aceptar',
        })


        Swal.fire({
          icon: 'success',
          title: '¡Contraseña cambiada correctamente!',
          text: 'Ya puedes hacer login con tu nueva contraseña',
          confirmButtonText: 'Aceptar',
        }).then((result) => {
          if (result.isConfirmed) {
            let thisStore = this.$store;
            thisStore.dispatch('goTo', `/login`);
            return false;
          }
        })


      }



      /*console.log('HOLA');
      return false;*/


    },
    async check_pass() {
      this.datos.visiblepass = false;
      this.datos.visibleloading = true;
      this.escribir_mensaje_error('', '');
      let pass_completo = sha1(this.datos.pass + this.datos.salt_recibido) + ':' + this.datos.salt_recibido;
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      };
      await this.sleep(500);
      let sesion = 0;

      const response = await fetch(`${this.datos.url_host}/account/token/${this.datos.mail.trim()}/${pass_completo}/${sesion}`, requestOptions);
      const datos = await response.json();

      if (datos.code != '00'){
        this.escribir_mensaje_error(`Error código ${datos.code}`, datos.message);
        console.log(response);
        console.log(datos);
        this.datos.visiblepass = false;
        this.datos.visibleloading = false;
        this.datos.visiblemail = true;
        this.datos.pass = '';
      } else {
        localStorage.token = datos.data.token;
        //window.location.href = "/";
        let thisStore = this.$store;
        thisStore.dispatch('goTo', `/`);
      }
    },
    check_event: function(e) {
      this.datos.check_recordar_pass = !this.datos.check_recordar_pass;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async comprobar_token() {
      let token = this.datos.token;
      this.datos.visibleloading = true;
      this.escribir_mensaje_error('', '');
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      };
      await this.sleep(500);
      const response = await fetch(`${this.datos.url_host}/account/token-n/${token}`, requestOptions);
      const datos = await response.json();

      if (datos.code != '00') {
        console.log(response);
        console.log(datos);
        //this.escribir_mensaje_error(`Error código ${datos.code}`, datos.message);
        this.datos.visiblemail = false;
        this.datos.visibleloading = false;
        this.datos.visiblepass = true;
      } else {
        /*this.escribir_mensaje_error(`Ya existe la cuenta`, `El usuario ${this.datos.mail.trim()} ya existe`);
        this.datos.visiblemail = true;
        this.datos.visibleloading = false;*/
        this.datos.visiblemail = false;
        this.datos.visibleloading = false;
        this.datos.visiblepass = true;


        this.datos.email =  datos.data.mail;
        console.log('mail',  this.datos.email);
        //this.datos.salt_recibido = datos.data.salt;
        /*this.view_fragment_1 = !this.view_fragment_1;
        this.view_fragment_2 = !this.view_fragment_2;*/
        //this.$nextTick(() => this.$refs.txt_pass.focus());
      }
    }
  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    //this.cargar_fondo_de_pantalla();
    //this.comprobar_token();
    this.comprobar_token();
  },
}
</script>
<style lang="scss" scoped>
@import '../styles/variables.css';
.logo_descripcion img{
  width: 210px;
}
.barra_superior{
  display: none;
}
.div_links{
  margin-top: 60px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
}



.item_form{
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
}
.item_form .titulo_form{
  text-align: left;
  color: var(--color-texto-principal-claro)
}




.copy{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 20px;
  padding-right: 16px;
  padding-left: 16px;
}
.copy .copy1{
  color: #FFFFFF;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
.copy .copy2{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  max-width: 430px;
  padding-right: 10px;
}
.copy .copy3{
  display: none;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 30px;

}
.div_info{
  text-align: left;
  width: 80%;
  max-width: 480px;
}
.div_info .titulo{
  font-size: 38px;
  font-weight: bold;
  color: #FFFFFF;
}
.div_info .info{
  /*color: var(--color-texto-principal-claro);*/
  font-weight: bold;
  color: #FFFFFF;
}
.div_botonera{
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-between;
  align-items: center;
  color: var(--color-texto-principal-claro);
}
.div_login{
  margin-top: 66px;
  margin-bottom: 66px;
}
.div_login .titulo{
  font-size: 38px;
  font-weight: bold;
}
.div_login .info{
  color: var(--color-texto-principal-claro);
  font-weight: bold;
}
.contenedor{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.bloque1{
  width: 40%;
  background-color: #00000060;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.bloque2{
  width: 60%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.div_login{
  width: 80%;
  max-width: 400px;
}
.fondo{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  /* The image used */
  background-image: url("../assets/backgrounds/550113.jpg");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.movil{
  display: none;
}
.escritorio{
  display: flex;
}
.div_error{
  background-color: var(--primary-color-5);
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  color: #FFF;
}
.div_title_error{
  font-weight: bold;
}
.copy4{
  display: none;
}
@media screen and (max-width: 991px){

  .movil{
    display: flex;
  }
  .escritorio{
    display: none;
  }
  .bloque2{
    width: 100%;
  }
  .barra_superior{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: var(--fondo-menu-claro-theme);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
    position: fixed;
    width: 100%;
  }
  .barra_superior img{
    height: 46px;
  }
  .copy .copy1{
    color: inherit;
  }

  .copy .copy1{
    display: none;
  }
  .copy .copy3{
    display: none;
  }
  .copy{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 16px;
    padding-left: 16px;
    height: 66px;
  }
  .copy4{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 16px;
    padding-left: 16px;
    cursor: pointer;
  }
  .div_links{
    display: inherit;
    bottom: inherit;
    right: inherit;
    position: inherit;
    margin-bottom: 60px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 480px){
  .copy .copy1{
    display: none;
  }
  .copy .copy3{
    display: none;
  }
  .copy{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 16px;
    padding-left: 16px;
    height: 66px;

  }
}
@media screen and (max-width: 660px){
  .barra_superior img{
    height: 30px;
  }
}
</style>
