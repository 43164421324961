<template>
  <div>
    <a :href="url" class="float btn_material" v-bind:class="{ float_oscuro: datos.modo_oscuro }">
      <font-awesome-icon :icon="['fas', 'plus']" class="icon alt my-float"/>
    </a>
    <div class="label-container">
      <div class="label-text">{{ texto }}</div>

    </div>
  </div>

</template>

<script>
import {useStore} from "vuex"
import {useRoute} from "vue-router";
import {computed} from "vue";
export default {
  name: 'BtnPlusFlotante',
  props: {
    texto: String,
    url: String,
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    return {
      variables: variables,
      datos: datos,
    }
  },
}
</script>

<style scoped lang="scss">
.label-container{
  position:fixed;
  bottom:48px;
  right:105px;
  display:table;
  visibility: hidden;
}

.label-text{
  color:#FFF;
  background:rgba(51,51,51,0.5);
  display:table-cell;
  vertical-align:middle;
  padding:10px;
  border-radius:3px;
}

.label-arrow{
  display:table-cell;
  vertical-align:middle;
  color:#333;
  opacity:0.5;
}

.float{
  position:fixed;
  width:60px;
  height:60px;
  bottom:40px;
  right:40px;
  background-color:var(--primary-color-3);
  color:#FFF;
  border-radius:50px;
  text-align:center;
  box-shadow: 2px 2px 3px #999;
}
.float_oscuro{
  box-shadow: 2px 2px 3px rgba(0,0,0,0.75);
}

.float:hover{
  background-color:var(--primary-color-4);
}

.my-float{
  font-size:24px;
  margin-top:18px;
}

a.float + div.label-container {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease;
}

a.float:hover + div.label-container{
  visibility: visible;
  opacity: 1;
}
.btn_material{
  color: #FFF!important;
  cursor: pointer;
}
</style>
