<template>
  <div class="menu_pc" v-show="datos.usuario_aceptado && datos.mostrar_menu">
    <div class="tam_menu_pc div_menu" v-bind:class="{ menu_pc_oscuro: datos.modo_oscuro }">
      <div style="width: 100%">
        <div style="width: 100%">
          <div class="version_info">
            V. {{datos.version}}
          </div>
          <div style="display: flex; flex-direction: row; flex-wrap: nowrap; align-content: space-around; justify-content: space-around; align-items: center;">
            <div class="div_logo_web">
              <!--<div><img alt="Vue logo" src="../assets/logo.png"></div>
              <div class="logo_nombre">Failder</div>-->
              <div v-show="!datos.modo_oscuro" style="margin-top: 10px; margin-bottom: 30px;"><img alt="Failder" src="../assets/LOGO_Blue-Black.png"> </div>
              <div v-show="datos.modo_oscuro" style="margin-top: 10px; margin-bottom: 30px;"><img alt="Failder" src="../assets/LOGO_White.png"> </div>
              <!--<div v-show="datos.modo_oscuro" style="margin-top: 10px; margin-bottom: 30px;"><img alt="Failder" src="../assets/LOGO_Blue.png"> </div>-->
            </div>
          </div>
        </div>
        <div class="div_info_usuario">
          <div>
            <div class="contenedor_circular_iniciales">{{datos.usuario.iniciales}}</div>
          </div>
          <div class="datos_usuario">
            <div style="font-weight: bold">{{datos.usuario.nombre}} {{datos.usuario.apellidos}}</div>
            <div style="font-size: 14px;">{{ datos.usuario.email }}</div>
          </div>
        </div>

        <router-link :to="'/dashboard/'+ datos.id_plan"><ItemMenuPC icon="home" prefix="fa" texto="Dashboard" texto_seleccionado="Dashboard"></ItemMenuPC></router-link>
        <router-link :to="'/proyectos/'+ datos.id_plan"><ItemMenuPC icon="window-maximize" prefix="fa" texto="Proyectos" texto_seleccionado="Proyectos"></ItemMenuPC></router-link>
        <router-link :to="'/contactos/'+ datos.id_plan"><ItemMenuPC icon="users" prefix="fa" texto="Contactos" texto_seleccionado="Contactos"></ItemMenuPC></router-link>
        <router-link :to="'/userconfig/'+ datos.id_plan"><ItemMenuPC icon="user-cog" prefix="fa" texto="Configuración personal" texto_seleccionado="Confpersonal"></ItemMenuPC></router-link>
        <router-link :to="'/planconfig/'+datos.id_plan"><ItemMenuPC icon="cogs" prefix="fa" texto="Configuración cuenta" texto_seleccionado="Confcuenta"></ItemMenuPC></router-link>
        <router-link :to="'/selectaccount/'"><ItemMenuPC icon="briefcase" prefix="fa" texto="Cambiar de cuenta"></ItemMenuPC></router-link>


      </div>
      <div style="width: 100%">
        <ItemMenuPC icon="sign-out-alt" prefix="fa" texto="Cerrar sesión" @click.native="abrir_pagina('cerrar_sesion')"></ItemMenuPC>
      </div>


    </div>
    <div class="tam_menu_pc">
      <!-- Contenedor espacio -->
    </div>
  </div>

</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import ItemMenuPC from "./ItemMenuPC";
export default {
  name: 'MenuPC',
  components: {
    ItemMenuPC
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    //Datos de inicio
    datos.id_plan = route.params.id;

    onMounted(function (){
      //console.log('Variable global',datos);
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    abrir_pagina(pagina){
      let thisStore = this.$store;
      if(pagina == 'cerrar_sesion'){
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/`);
        return false;
      }
      console.log('Abrir página', pagina);
      thisStore.dispatch('goTo', `/${pagina}`);
    },

  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
  },
}
</script>
<style scoped lang="scss">
.div_menu{
  background-color: var(--fondo-menu-claro-theme);
  height: 100%;
  position: fixed;
  top:0;
  left: 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: space-between;
  align-items: flex-start;
  -webkit-box-shadow: 1px 0px 6px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 1px 0px 6px 0px rgba(0,0,0,0.75);
  box-shadow: 1px 0px 6px 0px rgba(0,0,0,0.75);
}
/*.item_menu{
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: flex-start;
  align-items: center;
}*/
.contenedor_circular_iniciales{
  background-color: var(--primary-color-1);
  width: 50px;
  height: 50px;
  border-radius: 50px;
  text-align: center;
  font-size: 26px;
  padding-top: 4px;
  margin-left: 10px;
  margin-right: 10px;
  color: #FFFFFF;
}
.div_info_usuario{
  margin-top: 16px;
  margin-bottom: 36px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.datos_usuario{
  /*color:var(--primary-color-3);*/
  width: 100%;
}
.tam_menu_pc{
  width: 300px;
}
.div_logo_web{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: flex-start;
  align-items: center;

  /*padding-left: 10px;*/
}
.div_logo_web img{
  height: 50px;
  width: auto;
  margin-right: 10px;
}
.logo_nombre{
  color: #FFFFFF;
  font-size: 24px;
  font-weight: bold;
}
.version_info{
  font-size: 10px;
  text-align: right;
  color: var(--detalles-gris-clarito);
  margin-right: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}
@media screen and (max-width: 991px){
  .menu_pc{
    display: none;
  }
}

.menu_pc_oscuro{
  background-color: var(--fondo-menu-dark-theme);
}

</style>
