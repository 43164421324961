<template>
  <div v-show="datos.usuario_aceptado">
    <contactos-disponibles></contactos-disponibles>
  </div>

  <!--<div v-show="datos.usuario_aceptado">
    <div>
      <a href="/proyecto/0/configuracion" class="float">
        <font-awesome-icon :icon="['fas', 'plus']" class="icon alt my-float"/>
      </a>
      <div class="label-container">
        <div class="label-text">Crear proyecto</div>

      </div>
    </div>
    <div class="contenedor_carteles">
      <TarjetaInfoEstadoProyecto v-for="item in datos.proyectos_caidos" v-bind:id="item.id" v-bind:segundos="item.segundos_desde_consulta" v-bind:fecha_consulta="item.fecha_consulta" v-bind:fecha="item.fecha_ultimo_cambio_estado" v-bind:nombre_proyecto="item.nombre_web" v-bind:online="item.estado_web" v-bind:url="item.url" v-bind:activo="item.activo"></TarjetaInfoEstadoProyecto>
    </div>
  </div>-->
</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import ContactosDisponibles from '../components/ContactosDisponibles'
export default {
  name: 'Contactos',
  components: {
    ContactosDisponibles,
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    //Datos de inicio
    datos.id_plan = route.params.id;
    datos.mostrar_menu = true;
    datos.asistente_abierto = false;
    datos.mostrar_items_menu = false;

    datos.menu_padre_seleccioando = 'Contactos';


    onMounted(function (){
      store.dispatch("get_all_store",datos.id_plan); //Cargamos lo necesario
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
  },
}
</script>
<style lang="scss" scoped>
@import '../styles/variables.css';
.contenedor_carteles{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
}

.label-container{
  position:fixed;
  bottom:48px;
  right:105px;
  display:table;
  visibility: hidden;
}

.label-text{
  color:#FFF;
  background:rgba(51,51,51,0.5);
  display:table-cell;
  vertical-align:middle;
  padding:10px;
  border-radius:3px;
}

.label-arrow{
  display:table-cell;
  vertical-align:middle;
  color:#333;
  opacity:0.5;
}

.float{
  position:fixed;
  width:60px;
  height:60px;
  bottom:40px;
  right:40px;
  background-color:#06C;
  color:#FFF;
  border-radius:50px;
  text-align:center;
  box-shadow: 2px 2px 3px #999;
}

.my-float{
  font-size:24px;
  margin-top:18px;
}

a.float + div.label-container {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease;
}

a.float:hover + div.label-container{
  visibility: visible;
  opacity: 1;
}
@media screen and (max-width: 991px){

}
</style>
