<template>
  <div v-show="datos.usuario_aceptado">
    <cargando v-show="datos.mostrar_cargando"></cargando>
    <div class="contenedor_carteles">

        <cartel-de-informacion-link
            v-show="(datos.cantidad_proyectos_activos>0)"
            v-bind:titulo="'Proyectos activos'"
            v-bind:mensaje = "datos.cantidad_proyectos_activos+'/'+datos.cantidad_proyectos_permitidos"
            v-bind:color = "'#59f37b;'"
            v-bind:url="'/proyectos/'+datos.id_plan"></cartel-de-informacion-link>

        <cartel-de-informacion-link
            v-show="(datos.cantidad_proyectos_inactivos>0)"
            v-bind:titulo="'Proyectos inactivos'"
            v-bind:mensaje = "datos.cantidad_proyectos_inactivos"
            v-bind:color = "'#f35959;'"
            v-bind:url="'/proyectos/'+datos.id_plan"></cartel-de-informacion-link>
        <cartel-de-informacion-link
            v-show="(datos.cantidad_contactos == 0)"
            v-bind:titulo="'Contactos'"
            v-bind:mensaje = "'No tienes contactos creados.'"
            v-bind:color = "'#f35959;'"
            v-bind:url="'/contactos/'+datos.id_plan"></cartel-de-informacion-link>

        <cartel-de-informacion-link
            v-show="(datos.cantidad_contactos == 1)"
            v-bind:titulo="'Contactos'"
            v-bind:mensaje = "'1 contacto'"
            v-bind:color = "'#59f37b;'"
            v-bind:url="'/contactos/'+datos.id_plan"></cartel-de-informacion-link>

        <cartel-de-informacion-link
            v-show="(datos.cantidad_contactos > 1)"
            v-bind:titulo="'Contactos'"
            v-bind:mensaje = "datos.cantidad_contactos+' contactos'"
            v-bind:color = "'#59f37b;'"
            v-bind:url="'/contactos/'+datos.id_plan"></cartel-de-informacion-link>

        <cartel-de-informacion-link
            v-show="(datos.cantidad_licencias_pendientes == 0)"
            v-bind:titulo="'Próximas licencias'"
            v-bind:mensaje = "'No tienes licencias pendientes de activar. Cuando caduque la licencia actual, se desactivarán todos los proyectos.'"
            v-bind:color = "'#f35959;'"
            v-bind:url="'/planconfig/'+datos.id_plan"></cartel-de-informacion-link>

        <cartel-de-informacion-link
            v-show="(datos.cantidad_licencias_pendientes == 1)"
            v-bind:titulo="'Próximas licencias'"
            v-bind:mensaje = "'Muy bien, tienes 1 licencia pendiente.'"
            v-bind:color = "'#59f37b;'"
            v-bind:url="'/planconfig/'+datos.id_plan"></cartel-de-informacion-link>
        <cartel-de-informacion-link
            v-show="(datos.cantidad_licencias_pendientes > 1)"
            v-bind:titulo="'Próximas licencias'"
            v-bind:mensaje = "'¡Guau!, tienes '+datos.cantidad_licencias_pendientes+' licencias pendientes.'"
            v-bind:color = "'#59f37b;'"
            v-bind:url="'/planconfig/'+datos.id_plan"></cartel-de-informacion-link>
      <TarjetaInfoEstadoProyecto v-for="item in datos.proyectos_caidos" v-bind:fecha="item.fecha_ultimo_cambio_estado" v-bind:nombre_proyecto="item.nombre_web" v-bind:online="item.estado_web" v-bind:url="item.url"></TarjetaInfoEstadoProyecto>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import TarjetaInfoEstadoProyecto from '../components/TarjetaInfoEstadoProyecto'
import CartelDeInformacion from '../components/CartelDeInformacion'
import CartelDeInformacionLink from '../components/CartelDeInformacionLink'
import Cargando from "../components/Cargando";

export default {
  name: 'Dashboard',
  components: {
    TarjetaInfoEstadoProyecto,
    CartelDeInformacion,
    CartelDeInformacionLink,
    Cargando
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    //Datos de inicio
    datos.id_plan = route.params.id;
    datos.interval = null;
    datos.mostrar_menu = true;
    datos.mostrar_items_menu = false;


    datos.proyectos_caidos = [];
    datos.mostrar_cargando = true;


    datos.menu_padre_seleccioando = 'Dashboard';




    onMounted(function (){
      store.dispatch("get_all_store",datos.id_plan); //Cargamos lo necesario
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    async get_resumen_plan(){
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/dashboard/resumen_plan/${this.datos.id_plan}`, requestOptions);
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        console.log('Resumen', datos.data);
        let resumen = datos.data;
        this.datos.cantidad_proyectos_activos = resumen.proyectos_activos;
        this.datos.cantidad_proyectos_totales = resumen.proyectos_creados;
        this.datos.cantidad_proyectos_inactivos = resumen.proyectos_inactivos;
        this.datos.cantidad_contactos = resumen.cantidad_contactos_creados;
        this.datos.cantidad_licencias_pendientes = resumen.cantidad_licencias_pendientes;
        this.datos.cantidad_proyectos_permitidos = resumen.proyectos_totales_crear;
        /*{
          "proyectos_creados": 33,
            "proyectos_activos": 32,
            "proyectos_inactivos": 1,
            "sms_totales_enviar": 100,
            "sms_enviados": 6,
            "proyectos_totales_crear": 30
        }*/
      }
      this.datos.mostrar_cargando = false;
    },
    async get_proyectos_caidos(){
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/dashboard/proyectos_caidos/${this.datos.id_plan}`, requestOptions);
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        this.datos.proyectos_caidos = datos.data.proyectos;
        //console.log(proyectos);

        /*let cantidad_proyectos = this.datos.proyectos_caidos.length;
        for (let a = 0; a < cantidad_proyectos; a++){
          let item = this.datos.proyectos_caidos[a];
          if (item.activo == 1){
            this.datos.cantidad_proyectos_activos++;
          } else {
            this.datos.cantidad_proyectos_inactivos++;
          }
        }
        console.log('Datos', this.datos);*/
      }
    },

  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    //this.check_token();
    //console.log('Datos', this.datos);
    this.get_resumen_plan();
    //this.get_proyectos_caidos();
  },
}
</script>
<style lang="scss" scoped>
@import '../styles/variables.css';
.contenedor_carteles{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: stretch;
}


@media screen and (max-width: 991px){
  .menu_movil{
    display: none;
  }
}
</style>
