<template>
  <div class="contenedor_cartel" v-bind:class="{ contenedor_cartel_oscuro: datos.modo_oscuro }" v-show="(datos.tipo_licencia_mostrar == tipo)">
    <div class="encabezado"  v-bind:class="{ encabezado_oscuro: datos.modo_oscuro }">
      <div class="nombre_plan">
        {{ nombre }}
      </div>
      <div v-show="precio > 0" class="precio">
        {{ precio }}€
        <small>(+ IVA)</small>
      </div>
      <div v-show="precio <= 0" class="precio">
        Gratuito
      </div>
    </div>
    <div class="cuerpo">
      <div v-show="(meses == 1)">
        1 mes de duración
      </div>
      <div v-show="(meses > 1)">
        {{meses}} meses de duración
      </div>
      <div v-show="(descuento == 0 || descuento == null)">
        Sin descuento
      </div>
      <div v-show="(descuento > 0)">
        {{descuento}}% de descuesto aplicado
      </div>
      <div v-show="(cantidad_proyectos == 1)">
        1 proyecto
      </div>
      <div v-show="(cantidad_proyectos>1)">
        {{ cantidad_proyectos }} proyectos
      </div>
      <div v-show="(tipo_check==1)">
        Consultas cada 5 minutos
      </div>
      <div v-show="(tipo_check==2)">
        Consultas cada 2 minutos
      </div>
      <div v-show="(tipo_check==3)">
        Consultas cada 1 minuto
      </div>
      <div v-show="(tipo_check==4)">
        Consultas cada 30 segundos
      </div>
      <div v-show="(cantidad_sms == 0)">
        Sin servicio SMS
      </div>
      <div v-show="(cantidad_sms > 0)">
        {{ cantidad_sms }} SMS Mensuales
      </div>
      <div>
        {{ cantidad_contactos }} contactos de aviso
      </div>
      <div>
        {{ dias_log }} días de logs
      </div>
    </div>
    <div>
      <div v-show="(datos.datos_facturacion.nif == '' && precio > 0 && datos.btn_clicado_pago_id != id)" class="alert alert-full" v-bind:class="{ 'alert-oscuro': datos.modo_oscuro }" style="font-size: 14px;">
        Es necesario crear datos de facturación
      </div>
    </div>

    <div v-show="(precio > 0 && datos.datos_facturacion.nif != '' && !datos.mostrar_cargando_pagar)" class="pie" v-bind:class="{ pie_oscuro: datos.modo_oscuro }" @click.native="abrir_metodo_pago(id)">
      <div v-show="(datos.datos_facturacion.nif != '' && precio > 0 && datos.btn_clicado_pago_id != id)">
        Comprar
      </div>
      <div v-show="(datos.btn_clicado_pago_id == id)">
        <div v-show="(!datos.mostrar_cargando_pagar && datos.metodos_pago.paypal)">
          <btn-primario texto="Paypal" @click.native="pagar_con('paypal',id)"></btn-primario>
        </div>
        <div v-show="(!datos.mostrar_cargando_pagar && datos.metodos_pago.stripe)">
          <btn-primario texto="Tarjeta" @click.native="pagar_con('stripe',id)"></btn-primario>
        </div>
      </div>
    </div>
    <div v-show="(precio <= 0 && datos.btn_clicado_pago_id != id && !datos.mostrar_cargando_pagar)" class="pie" v-bind:class="{ pie_oscuro: datos.modo_oscuro }" @click.native="abrir_metodo_pago(id)">
      <div v-show="(precio == 0 && datos.btn_clicado_pago_id != id && !datos.mostrar_cargando_pagar)" @click.native="solicitar_licencia_gratuita(id)">
        Conseguir
      </div>
    </div>

    <div v-show="(datos.btn_clicado_pago_id == id && datos.mostrar_cargando_pagar)" class="pie_cargador" v-bind:class="{ pie_cargador_oscuro: datos.modo_oscuro }">
      <div><img style="width: 26px; margin-top: 16px; margin-bottom: 8px;" src="../assets/loading.svg"></div>
    </div>


    <!--<div v-show="(datos.datos_facturacion.nif != '')" class="pie" v-bind:class="{ pie_oscuro: datos.modo_oscuro }" @click.native="abrir_metodo_pago(id)">
      <div v-show="(datos.datos_facturacion.nif != '' && precio > 0 && datos.btn_clicado_pago_id != id)">
        Comprar
      </div>

      <div v-show="(precio == 0 && datos.btn_clicado_pago_id != id)">
        Conseguir
      </div>
      <div v-show="(datos.mostrar_cargando_pagar)"><img style="width: 26px; margin-top: 16px; margin-bottom: 8px;" src="../assets/loading.svg"></div>

    </div>
    <div v-show="(datos.datos_facturacion.nif == '' && precio <= 0 && datos.btn_clicado_pago_id != id)" class="pie" v-bind:class="{ pie_oscuro: datos.modo_oscuro }" @click.native="abrir_metodo_pago(id)">
      <div v-show="(precio == 0 && datos.btn_clicado_pago_id != id && !datos.mostrar_cargando_pagar)" @click.native="solicitar_licencia_gratuita(id)">
        Conseguir
      </div>

    </div>-->
  </div>
</template>



<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import BtnPrimario from '../components/BtnPrimario'
export default {
  name: 'ItemPrecioLicencia',
  components:{
    BtnPrimario
  },
  props: {
    nombre: String,
    precio: Number,
    meses: Number,
    descuento: Number,
    cantidad_proyectos: Number,
    dias_log:Number,
    cantidad_sms:Number,
    tipo_check:Number,
    id: Number,
    tipo: Number,
    cantidad_contactos: Number,
  },
  setup(){
    const store = useStore();
    //const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    datos.mostrar_cargando_pagar = false;

    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    abrir_metodo_pago(id_mostrar){
      this.datos.btn_clicado_pago_id = id_mostrar;
    },
    solicitar_licencia_gratuita(id_mostrar){
      //this.datos.btn_clicado_pago_id = id_mostrar;
      this.datos.mostrar_cargando_pagar = true;
      this.pagar_con('gratuita',id_mostrar)
    },
    async pagar_con(metodo_pago, id_licencia) {
      this.datos.mostrar_cargando_pagar = true;
      let datos_enviar = {
        id_licencia: id_licencia,
        metodo_pago: metodo_pago,
      };
      console.log('PAGAR', datos_enviar)
      //return false;
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/licencias/comprar/${this.datos.id_plan}`, requestOptions);

      if (response.status != 200) {
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      /*console.log('RESPUWSTA', datos);
      return false;*/
      if (datos.code != '00') {
        console.log(response);
        console.log(datos);
      } else {
        console.log('recibido', datos.data);
        let info_apago = datos.data;

        /*if (metodo_pago == 'paypal'){
        return false;
        }*/

        if (info_apago.url == 'reload'){
          location.reload();
        } else {
          window.location = info_apago.url;
        }


        //https://app.failder.com/pagos/21/paypal/valido/STZOWZURVU?token=41V55756969312633&PayerID=32D53MHUTSCMN
      }
    }
  },

  /**
   * {
"id": 1,
"nombre_interno": "A9edEa",
"nombre": "Atenea Systems",
"tipo_licencia_activa": 4,
"sms_total": 100,
"sms_disponibles": 100,
"proyectos_total": 30,
"proyectos_disponibles": 30,
"fecha_caducidad": "2021-08-17T22:00:00.000Z",
"fecha_modificado": "2021-08-06T14:29:06.000Z",
"fecha_creado": "2021-08-06T14:29:06.000Z",
"estado": 1
}
   * */
}
</script>

<style scoped lang="scss">
.cantidad_proyectos{
  margin-top: 30px;
  margin-bottom: 20px;
}
.tipo_licencia{
  font-size: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
  flex-wrap: nowrap;
  align-items: center;
}
.tipo_licencia .licencia{
  margin-left: 6px;
};
.caducidad_licencia{
  font-size: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 32px;
}
.caducidad_licencia .fecha{
  margin-left: 6px;
}
.contenedor_cartel{
  border-style: solid;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 16px;
  margin-left: 16px;
  /*padding: 8px;*/
  border-radius: 6px;
  border-width: 1px;
  border-color: var(--primary-color-1);
  width: 30%;
}
.contenedor_cartel_oscuro{
  border-color: var(--primary-color-4);
}
.encabezado{
  background-color: var(--primary-color-1);
}
.encabezado_oscuro{
  background-color: var(--primary-color-4);
}
.encabezado .nombre_plan{
  font-size: 24px;
  font-weight: bold;
}
.encabezado .precio{
  font-size: 20px;
}
.encabezado .precio small{
  font-size: 12px;
}
.cuerpo{
  margin: 8px;
}
.pie{
  font-size: 24px;
  background-color: var(--primary-color-1);
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
.pie:hover{
  background-color: var(--primary-color-2);
}
.pie_oscuro{
  background-color: var(--primary-color-4)!important;
}
.pie_oscuro:hover{
  background-color: var(--primary-color-5)!important;
}

.pie_cargador{
  font-size: 24px;
  background-color: var(--primary-color-1);
  padding-top: 10px;
  padding-bottom: 10px;
}
.pie_cargador_oscuro{
  background-color: var(--primary-color-4)!important;
}
</style>
