<template>
  <div class="item_menu" v-bind:class="(datos.menu_padre_seleccioando == texto_seleccionado)?'item_menu_seleccionado':''">
    <div class="icono">
      <font-awesome-icon :icon="[prefix, icon]" class="icon alt"/>
    </div>
    <div class="texto">
      {{texto}}
    </div>
  </div>

</template>

<script>
import {useStore} from "vuex"
import {computed, onMounted} from "vue";
export default {
  name: 'ItemMenuMovil',
  props: {
    icon: String,
    prefix: String,
    texto: String,
    texto_seleccionado: {
      default: '',
      type: String,
    }
  },
  setup(){
    const store = useStore();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar


    //Datos de inicio
    return {
      variables: variables,
      datos: datos,
    }
  },
  /*setup(props){
    let seleccionado = false;
    if (window.location.pathname == `/${props.texto.toLowerCase()}`){
      seleccionado = true;
    }
    return {
      seleccionado: seleccionado,
    }
  }*/
}
</script>

<style scoped lang="scss">
.item_menu{
  /*color: #FFFFFF;*/
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--detalles-gris-muy-clarito);
  cursor: pointer;
}
.item_menu:hover{
  background-color: var(--primary-color-4);
}
.item_menu_seleccionado{
  color: var(--primary-color-1);
}
.icono{
  padding: 8px;
  margin-right: 10px;
}
.icono svg{
  width: 28px;
  height: 28px;
}
.item_menu .texto{
  font-weight: bold;
}
</style>
