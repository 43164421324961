<template>
  <div v-show="datos.usuario_aceptado" style="padding-bottom: 100px">
    <btn-plus-flotante v-show="datos.permisos.crear_proyecto" v-bind:texto="'Crear proyecto'" v-bind:url="'/proyecto/0/configuracion/'+datos.id_plan"></btn-plus-flotante>
    <cargando v-show="datos.mostrar_cargando"></cargando>
    <div class="contenedor_carteles">
      <!--<TarjetaInfoEstadoProyecto v-for="item in datos.proyectos_plan" v-bind:id="item.id" v-bind:segundos="item.segundos_desde_consulta" v-bind:fecha_consulta="item.fecha_consulta" v-bind:fecha="item.fecha_ultimo_cambio_estado" v-bind:nombre_proyecto="item.nombre_web" v-bind:online="item.estado_web" v-bind:url="item.url" v-bind:activo="item.activo"></TarjetaInfoEstadoProyecto>-->
      <TarjetaInfoProyecto  v-for="item in datos.proyectos_plan" v-bind:id="item.id" v-bind:segundos="item.segundos_desde_consulta" v-bind:fecha_consulta="item.fecha_consulta" v-bind:fecha="item.fecha_ultimo_cambio_estado" v-bind:nombre_proyecto="item.nombre_web" v-bind:online="item.estado_web" v-bind:url="item.url" v-bind:activo="item.activo"></TarjetaInfoProyecto>
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed, onBeforeUnmount} from "vue"
import { useRoute } from 'vue-router'
import TarjetaInfoEstadoProyecto from '../components/TarjetaInfoEstadoProyecto'
import TarjetaInfoProyecto from '../components/TarjetaInfoProyecto'
import BtnPlusFlotante from "../components/BtnPlusFlotante";
import Cargando from "../components/Cargando";


let miSetInterval = null;

export default {
  name: 'Proyectos',
  components: {
    TarjetaInfoEstadoProyecto,
    TarjetaInfoProyecto,
    BtnPlusFlotante,
    Cargando
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    //Datos de inicio
    datos.id_plan = route.params.id;
    datos.mostrar_menu = true;
    datos.mostrar_items_menu = false;
    datos.proyectos_plan = [];
    datos.mostrar_cargando = true;

    datos.menu_padre_seleccioando = 'Proyectos';

    onMounted(function (){
      store.dispatch("get_all_store",datos.id_plan); //Cargamos lo necesario
    });
    onBeforeUnmount(function (){
      if(miSetInterval != null){
        clearInterval(miSetInterval);
      }
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    async get_todos_los_proyectos(){
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/proyectos/${this.datos.id_plan}`, requestOptions);
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        this.datos.proyectos_plan = datos.data;
      }
      this.datos.mostrar_cargando = false;

      if (!this.datos.proyectos_plan.length){
        const thisStore = this.$store;
        thisStore.dispatch('goTo', `/proyecto/0/configuracion/${this.datos.id_plan}`);
      }
    }
  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    //this.check_token();
    this.get_todos_los_proyectos();
    //console.log('Datos', this.datos);
    miSetInterval = setInterval(() => {
      this.get_todos_los_proyectos();
    }, 5000);
  },
}
</script>
<style lang="scss" scoped>
@import '../styles/variables.css';
.contenedor_carteles{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
}
</style>
