<template>
  <div class="contenedor_cartel">
      <div class="sub_contenedor_cartel" v-bind:class="{ sub_contenedor_cartel_oscuro: datos.modo_oscuro }">
        <div class="titulo">
          {{ nombre }}
        </div>

        <div class="cantidad_proyectos" v-show="(proyectos_activos == 1)">
          1 proyecto activo
        </div>
        <div class="cantidad_proyectos" v-show="(proyectos_activos != 1)">
          {{ proyectos_activos }} proyectos activos
        </div>

        <div style="padding-left: 16px; padding-right: 16px">
          <div class="tipo_licencia">
            <div>
              Tipo licencia:
            </div>
            <div class="licencia">
              {{ nombre_licencia }}
            </div>
          </div>

          <div class="caducidad_licencia">
            <div>
              Licencia válida hasta:
            </div>
            <div class="fecha">
              {{ fecha_caducidad }}
            </div>
          </div>
        </div>

        <div>
          <router-link :to="'/dashboard/'+ id"><btn-primario texto="Acceder"></btn-primario></router-link>
        </div>
      </div>
  </div>

</template>



<script>
import {useStore} from "vuex"
import BtnPrimario from '../components/BtnPrimario'
import {useRoute} from "vue-router";
import {computed, onMounted} from "vue";
export default {
  name: 'Plans',
  components:{
    BtnPrimario
  },
  props: {
    fecha_caducidad: String,
    estado: Number,
    nombre: String,
    nombre_licencia: String,
    id: Number,
    proyectos_activos: Number,
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
  },

  /**
   * {
"id": 1,
"nombre_interno": "A9edEa",
"nombre": "Atenea Systems",
"tipo_licencia_activa": 4,
"sms_total": 100,
"sms_disponibles": 100,
"proyectos_total": 30,
"proyectos_disponibles": 30,
"fecha_caducidad": "2021-08-17T22:00:00.000Z",
"fecha_modificado": "2021-08-06T14:29:06.000Z",
"fecha_creado": "2021-08-06T14:29:06.000Z",
"estado": 1
}
   * */
}
</script>

<style scoped lang="scss">
.cantidad_proyectos{
  margin-top: 30px;
  margin-bottom: 20px;
}
.tipo_licencia{
  font-size: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
  flex-wrap: nowrap;
  align-items: center;
}
.tipo_licencia .licencia{
  margin-left: 6px;
};
.caducidad_licencia{
  font-size: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: space-around;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 32px;
}
.caducidad_licencia .fecha{
  margin-left: 6px;
}
.contenedor_cartel{
  padding: 8px;
}
.sub_contenedor_cartel{
  border-style: solid;
  border-radius: 6px;
  border-width: 1px;
  border-color: var(--primary-color-1);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 16px;
  margin-left: 16px;
  background-color: var(--fondo-menu-claro-theme);
}
.sub_contenedor_cartel_oscuro{
  background-color: var(--fondo-menu-dark-theme)!important;
}
.titulo{
  font-weight: bold;
}

@media screen and (min-width: 992px){
  .contenedor_cartel{
    width: 33%;
  }
}
@media screen and (min-width: 1300px){
  .contenedor_cartel{
    width: 25%;
  }
}
@media screen and (min-width: 1600px){
  .contenedor_cartel{
    width: 20%;
  }
}
@media screen and (max-width: 991px){
  .contenedor_cartel{
    width: 50%;
  }
}
@media screen and (max-width: 660px){
  .contenedor_cartel{
    width: 100%;
  }
}
</style>
