<template>
  <div v-show="datos.usuario_aceptado" class="contenedor_carteles">
    <cargando v-show="datos.mostrar_cargando"></cargando>
    <div class="item_mini_cartel" v-bind:class="{ item_mini_cartel_oscuro: datos.modo_oscuro }">
      <div class="cajon">
        <div class="titulo">Nombre de la cuenta</div>
        <div class="form_item">
          <div class="input">
            <div>
              <input type="text" v-model="datos.info_cuenta.nombre" v-on:keyup="comprobar_cambios_nombre_plan">
            </div>
          </div>
          <div style="display: flex; justify-content: flex-end;">
            <btn-primario v-show="(datos.mostrar_boton_guardar_plan)" texto="Guardar" @click.native="update_nombre_plan()"></btn-primario>
          </div>
        </div>
      </div>
    </div>
    <div v-show="datos.datos_facturacion.nif == ''" class="item_mini_cartel" v-bind:class="{ item_mini_cartel_oscuro: datos.modo_oscuro }">
      <div class="cajon">
        <div class="titulo">Datos de facturación</div>
        <div>
          <div class="alert" v-bind:class="{ 'alert-oscuro': datos.modo_oscuro }">
              No se han encontrado datos de facturación
          </div>
        </div>
        <div style="display: flex; justify-content: flex-end;">
          <btn-primario texto="Crear datos de facturación" @click.native="editar_datos_facturacion()"></btn-primario>
        </div>
      </div>
    </div>
    <div v-show="datos.datos_facturacion.nif != ''" class="item_mini_cartel" v-bind:class="{ item_mini_cartel_oscuro: datos.modo_oscuro }">
      <div class="cajon">
        <div class="titulo">Datos de facturación</div>
        <div class="form_item">
          <div>
            Empresa
          </div>
          <div class="input">
            {{ datos.datos_facturacion.empresa }}
          </div>
        </div>
        <div v-show="!datos.mostrar_datos_facturacion" style="text-align: right;">
          <btn-primario-transparente texto="Mostrar datos de facturación" @click.native="mostrar_datos_facturacion()"></btn-primario-transparente>
        </div>
        <div v-show="datos.mostrar_datos_facturacion"  class="form_item">
          <div>
            NIF
          </div>
          <div class="input">
            {{ datos.datos_facturacion.nif }}
          </div>
        </div>
        <div v-show="datos.mostrar_datos_facturacion"  class="form_item">
          <div>
            Nombre y apellidos
          </div>
          <div class="input">
            {{ datos.datos_facturacion.nombre }} {{ datos.datos_facturacion.apellidos }}
          </div>
        </div>
        <div v-show="datos.mostrar_datos_facturacion"  class="form_item">
          <div>
            Dirección
          </div>
          <div class="input">
            {{ datos.datos_facturacion.direccion }}
          </div>
        </div>
        <div v-show="datos.mostrar_datos_facturacion"  class="form_item">
          <div>
            Código postal
          </div>
          <div class="input">
            {{ datos.datos_facturacion.codigo_postal }}
          </div>
        </div>
        <div v-show="datos.mostrar_datos_facturacion"  class="form_item">
          <div>
            Ciudad
          </div>
          <div class="input">
            {{ datos.datos_facturacion.ciudad }}
          </div>
        </div>
        <div v-show="datos.mostrar_datos_facturacion"  class="form_item">
          <div>
            Provincia
          </div>
          <div class="input">
            {{ datos.datos_facturacion.provincia }}
          </div>
        </div>
        <div v-show="datos.mostrar_datos_facturacion"  class="form_item">
          <div>
            País
          </div>
          <div class="input">
            {{ datos.datos_facturacion.pais }}
          </div>
        </div>

        <div style="display: flex; justify-content: flex-end;" v-show="datos.mostrar_datos_facturacion" >
          <btn-primario texto="Editar" @click.native="editar_datos_facturacion()"></btn-primario>
        </div>
        <div v-show="datos.mostrar_datos_facturacion" style="text-align: left;">
          <btn-primario-transparente texto="Ocultar datos de facturación" @click.native="mostrar_datos_facturacion()"></btn-primario-transparente>
        </div>
      </div>
    </div>
    <div style="width: 100%;"></div>
    <div class="item_mini_cartel" v-bind:class="{ item_mini_cartel_oscuro: datos.modo_oscuro }" v-show="datos.info_tipo_licencia.nombre != undefined && datos.info_tipo_licencia.nombre != '--'">
      <div class="cajon">
        <div class="titulo">Licencia activa</div>
        <div class="form_item">
          <div>
            Licencia
          </div>
          <div class="input">
            {{ datos.info_tipo_licencia.nombre }}
          </div>
        </div>
        <div class="form_item">
          <div>
            Código licencia
          </div>
          <div class="input">
            {{ datos.info_licencia_activa.codigo }}
          </div>
        </div>
        <div class="form_item">
          <div>
            Proyectos
          </div>
          <div class="input">
            {{ datos.info_resumen.proyectos_creados }} de {{ (datos.info_tipo_licencia.cantidad_proyectos) }} disponibles
          </div>
        </div>
        <div class="form_item">
          <div>
            SMS
          </div>
          <div class="input">
            {{ datos.info_resumen.sms_enviados }} enviados de {{ (datos.info_tipo_licencia.cantidad_sms) }} disponibles
          </div>
        </div>
        <!--<div class="div_acciones">
          <btn-primario texto="Guardar" @click.native="update_account_data()"></btn-primario>
        </div>-->
      </div>
    </div>
    <div class="item_mini_cartel" v-bind:class="{ item_mini_cartel_oscuro: datos.modo_oscuro }" v-show="((datos.info_tipo_licencia.nombre != undefined && datos.info_tipo_licencia.nombre != '--') || datos.info_licencias_pendientes != null)">
      <div class="cajon">
        <div class="titulo">Licencias pendientes</div>
        <div v-show="datos.info_licencias_pendientes != null && datos.info_licencias_pendientes.length > 0">
          <div style="padding: 8px;">
            <item-licencias-pendientes v-for="item in datos.info_licencias_pendientes"
                                       v-bind:nombre="item.tipo_licencia_info.nombre"
                                       v-bind:cantidad_contactos="item.tipo_licencia_info.cantidad_contactos"
                                       v-bind:cantidad_proyectos="item.tipo_licencia_info.cantidad_proyectos"
                                       v-bind:cantidad_sms="item.tipo_licencia_info.cantidad_sms"
                                       v-bind:codigo="item.codigo"
                                       v-bind:dias_log="item.tipo_licencia_info.dias_log"
                                       v-bind:duracion="item.tipo_licencia_info.meses"></item-licencias-pendientes>
          </div>

        </div>
        <div v-show="(datos.info_licencias_pendientes == null || datos.info_licencias_pendientes.length == 0)">
          <div class="alert alert_tarea" v-bind:class="{ 'alert-oscuro': datos.modo_oscuro }">
            No hay licencias pendientes
          </div>
        </div>
        <!--<div class="form_item">
          <div>
            Contraseña actual
          </div>
          <div class="input">
            <input type="password" placeholder="Contraseña actual" v-model="datos.pass_viejo" v-on:keyup="validate_form" required >
          </div>
        </div>
        <div class="form_item">
          <div>
            Nueva contraseña
          </div>
          <div class="input">
            <input type="password" placeholder="Nueva contraseña" v-model="datos.pass_nuevo1" v-on:keyup="validate_form" required >
          </div>
        </div>
        <div class="form_item">
          <div>
            Repite la nueva contraseña
          </div>
          <div class="input">
            <input type="password" placeholder="Repite la nueva contraseña" v-model="datos.pass_nuevo2" v-on:keyup="validate_form" required >
          </div>
        </div>-->
        <!--<div class="div_acciones">
          <btn-primario texto="Guardar" @click.native="update_account_access()"></btn-primario>
        </div>-->
      </div>

    </div>
    <div class="item_mini_cartel" v-bind:class="{ item_mini_cartel_oscuro: datos.modo_oscuro }"  style="width: 100%;" v-show="(datos.lista_planes_venta.length > 0)">
      <div class="cajon">
        <div class="titulo">Comprar licencia</div>
        <div class="contenedor_botones_licencias">
          <btn-primario-transparente texto="Mensual" @click.native="seleccionar_tipo_de_licencias(1)"></btn-primario-transparente>
          <btn-primario-transparente texto="3 meses" @click.native="seleccionar_tipo_de_licencias(2)"></btn-primario-transparente>
          <btn-primario-transparente texto="6 meses" @click.native="seleccionar_tipo_de_licencias(3)"></btn-primario-transparente>
          <btn-primario-transparente texto="12 meses" @click.native="seleccionar_tipo_de_licencias(4)"></btn-primario-transparente>
          <btn-primario-transparente texto="Personalizados" @click.native="seleccionar_tipo_de_licencias(0)" v-show="datos.mostrar_boton_licencias_personalizadas"></btn-primario-transparente>
        </div>

        <!--
        {
    "id": 7,
    "id_licencia_padre": 2,
    "cantidad": 12,
    "descuento": 40,
    "nombre": "Básico anual",
    "precio": 43.13,
    "dias_log": 90,
    "dias_licencia": 30,
    "cantidad_proyectos": 2,
    "cantidad_sms": 20,
    "tipo_check": 2,
    "compra_unica": 0,
    "activa": 1,
    "id_cuenta": null
}
        -->

        <div class="contenedor_planes_de_precio">
          <item-precio-licencia v-for="item in datos.lista_planes_venta"
                                v-bind:id="item.id"
                                v-bind:descuento="item.descuento"
                                v-bind:meses="item.meses"
                                v-bind:nombre="item.nombre"
                                v-bind:precio="item.precio"
                                v-bind:cantidad_proyectos="item.cantidad_proyectos"
                                v-bind:dias_log="item.dias_log"
                                v-bind:cantidad_sms="item.cantidad_sms"
                                v-bind:tipo_check="item.tipo_check"
                                v-bind:tipo="item.tipo"
                                v-bind:cantidad_contactos="item.cantidad_contactos"
          >
          </item-precio-licencia>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import BtnPrimario from '../components/BtnPrimario'
import BtnPrimarioTransparente from '../components/BtnPrimarioTransparente'
import sha1 from 'js-sha1';
import ItemPrecioLicencia from '../components/ItemPrecioLicencia'
import Cargando from "../components/Cargando";
import ItemLicenciasPendientes from "@/components/ItemLicenciasPendientes";
export default {
  name: 'ConfiguracionPlan',
  components: {
    BtnPrimario,
    ItemPrecioLicencia,
    BtnPrimarioTransparente,
    Cargando,
    ItemLicenciasPendientes
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    //Datos de inicio
    datos.id_plan = route.params.id;
    datos.mostrar_menu = true;
    datos.mostrar_items_menu = false;
    datos.mostrar_cargando = true;
    datos.resumen_licencia = {}
    datos.lista_planes_venta = [];
    datos.btn_clicado_pago_id = 0;
    datos.tipo_licencia_mostrar = 1;
    datos.nombre_actual_plan = '';
    datos.mostrar_boton_guardar_plan = false;


    //Facturacion
    datos.mostrar_datos_facturacion = false;
    datos.mostrar_boton_licencias_personalizadas = false;


    datos.menu_padre_seleccioando = 'Confcuenta';


    onMounted(function (){
      store.dispatch("get_all_store",datos.id_plan); //Cargamos lo necesario
      console.log('AAAAAA',datos.info_tipo_licencia)
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    editar_datos_facturacion(){
      const thisStore = this.$store;
      thisStore.dispatch('goTo', `/facturacion/${this.datos.id_plan}`);
    },
    comprobar_cambios_nombre_plan(){
      if(this.datos.info_cuenta.nombre != this.datos.info_cuenta.nombre_actual_plan){
        this.datos.mostrar_boton_guardar_plan = true;
      } else {
        this.datos.mostrar_boton_guardar_plan = false;
      }
    },
    mostrar_datos_facturacion(){
      this.datos.mostrar_datos_facturacion = !this.datos.mostrar_datos_facturacion;
    },
    async update_nombre_plan() {
      let datos_enviar = {
        nombre_plan: this.datos.info_cuenta.nombre
      }
      let requestOptions = {
        method: "PUT",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      let response = await fetch(`${this.datos.url_host}/plan/cambio-nombre/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      let datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        return false;
      }

      //this.datos.nombre_actual_plan = this.datos.info_cuenta.nombre;
      this.comprobar_cambios_nombre_plan();

      /////////////////////////////////////////
    },
    async get_licencia_activa() {

      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/licencia/activa/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200) {
        /*let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);*/
        return false;
      }

      //Respuesta 200
      const respuestaOK = await response.json();
      console.log(respuestaOK);
      if(respuestaOK.code != '00'){
        console.log('Respuesta error', respuestaOK.code);
        console.log('Respuesta', respuestaOK);
        return false;
      }

      let datos_recibidos = respuestaOK.data;
      console.log('Respuesta Licencia', datos_recibidos);
      this.datos.info_cuenta = datos_recibidos.info_cuenta;
      this.datos.info_tipo_licencia = datos_recibidos.info_tipo_licencia;
      this.datos.info_licencia_activa = datos_recibidos.info_licencia_activa;

      //this.datos.nombre_actual_plan = this.datos.info_cuenta.nombre;

      //Resumen
      //console.log('aaa',this.datos.info_tipo_licencia.cantidad_proyectos);
      this.datos.resumen_licencia.sms_enviados = (this.datos.info_tipo_licencia.cantidad_sms - this.datos.info_cuenta.sms_disponibles)
      this.datos.resumen_licencia.proyectos_usados = this.datos.info_tipo_licencia.cantidad_proyectos - (this.datos.info_tipo_licencia.cantidad_proyectos - this.datos.info_cuenta.proyectos_disponibles)
      //console.log('aaa',this.datos.info_cuenta.proyectos_disponibles);
      //console.log('aaa',this.datos.resumen_licencia.proyectos_usados);
    },
    async get_lista_planes_venta() {
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/licencias/shop/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200) {
        /*let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);*/
        return false;
      }

      //Respuesta 200
      const respuestaOK = await response.json();
      console.log(respuestaOK);
      if(respuestaOK.code != '00'){
        console.log('Respuesta error', respuestaOK.code);
        console.log('Respuesta', respuestaOK);
        return false;
      }


      let datos_recibidos = respuestaOK.data;
      console.log('Respuesta SHOP', datos_recibidos);
      this.datos.lista_planes_venta =  datos_recibidos;
      this.datos.mostrar_cargando = false;

      this.datos.mostrar_boton_licencias_personalizadas = false;
      const cantidad_licencias = datos_recibidos.length;
      for (let a = 0; a < cantidad_licencias; a++){
        if (this.datos.mostrar_boton_licencias_personalizadas) break;
        const item = datos_recibidos[a];
        if(item.tipo == 0) this.datos.mostrar_boton_licencias_personalizadas = true;
      }



    },
    seleccionar_tipo_de_licencias(tipo_licencia){
      this.datos.tipo_licencia_mostrar = tipo_licencia
    },
  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    //this.check_token();
    //this.get_all_contactos();
    //console.log('DATOS USUARIO', this.datos.usuario);
    /*this.datos.usuario = {
      id: respuestaOK.data.id,
      nivel: respuestaOK.data.nivel,
      email: respuestaOK.data.email,
      nombre: nombre,
      apellidos: apellidos,
      iniciales: iniciales,
    }*/
    //this.get_licencia_activa();
    this.get_lista_planes_venta();
  },
}
</script>
<style lang="scss" scoped>
@import '../styles/variables.css';
.contenedor_carteles{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-between;
  align-items: flex-start;
}
.contenedor_botones_licencias{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
}


.form_item{
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 16px;
  padding-top: 4px;
  width: 100%;
  text-align: left;
}
.item_mini_cartel{
  width: 50%;
  padding: 10px;
}
.item_mini_cartel .cajon{
  border-style: solid;
  border-width: 1px;
  border-color: var(--primary-color-5);
  background-color: var(--fondo-menu-claro-theme);
}
.item_mini_cartel .cajon .titulo{
  background-color: var(--primary-color-5);
  color: #FFF;
  padding-top: 10px;
  padding-bottom: 12px;
  font-size: 20px;
}
.item_mini_cartel .cajon .div_acciones{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
}

.item_mini_cartel_oscuro .cajon{
  border-style: solid;
  border-width: 1px;
  border-color: var(--primary-color-1);
  background-color: var(--fondo-menu-dark-theme)!important;
}
.item_mini_cartel_oscuro .cajon .titulo{
  background-color: var(--primary-color-1);
  color: #FFF;
  padding-top: 10px;
  padding-bottom: 12px;
  font-size: 20px;
}


.contenedor_planes_de_precio{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: flex-start;
}

@media screen and (max-width: 991px){
  .contenedor_cartel{
    width: 45%;
  }
}
@media screen and (max-width: 660px){
  .item_mini_cartel{
    width: 100%;
  }
  .contenedor_cartel{
    width: 100%;
  }
}




/***/

</style>
