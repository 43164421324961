<template>
  <div v-show="datos.usuario_aceptado && datos.asistente_visible" class="contenedor" v-bind:class="{ contenedor_oscuro: datos.modo_oscuro }">
    <div class="asistente" v-bind:class="{ asistente_oscuro: datos.modo_oscuro }">
      <div class="paso1" v-show="datos.paso_asistente == '1'">
        <div class="item_form">
          <div class="titulo">
            Nombre del contacto
          </div>
          <div class="explicacion">
            Escribe el nombre y el primer apellido del contacto
          </div>
          <div class="input">
            <input type="text" placeholder="John Doe" v-model="datos.nombre_contacto" v-on:keyup="validate_form" required >
          </div>
        </div>
      </div>
      <div class="paso2" v-show="datos.paso_asistente == '2'">
        <div class="item_form">
          <div class="titulo">
            Email
          </div>
          <div class="explicacion">
            Escribe una dirección de correo electrónico válida para recibir las notifiaciones.<br>Si no quieres que este contacto reciba las notificaciones por correo electrónico, lo puedes dejar en blanco.
          </div>
          <div class="input">
            <input type="email" placeholder="johndoe@gmail.com" v-model="datos.email_contacto" v-on:keyup="validate_form" required >
          </div>
        </div>
      </div>
      <div class="paso3" v-show="datos.paso_asistente == '3'">
        <div class="item_form">
          <div class="titulo">
            Móvil
          </div>
          <div class="explicacion">
            Escribe el número de teléfono móvil para recibir un SMS con la notificación. Se debe incluir el prefijo del país SIN el simbolo "+". Es decir, el prefijo de España es el +34, por lo tanto el número de teléfono que deberemos añadir es "34612345678".<br>Si no quieres que este contacto reciba las notificaciones por SMS, lo puedes dejar en blanco.
          </div>
          <div class="input">
            <input type="tel" placeholder="34600111222" v-model="datos.movil_contacto" v-on:keyup="validate_form" required >
          </div>
        </div>
      </div>
      <div class="botonera">
        <btn-primario v-show="datos.paso_asistente > 1" texto="Atrás" @click.native="asistente(-1)"></btn-primario>
        <btn-primario v-show="datos.mostrar_btn_siguiente" texto="Siguiente" @click.native="asistente(1)"></btn-primario>
        <btn-primario v-show="datos.paso_asistente == 3" texto="Guardar" @click.native="asistente(1)"></btn-primario>
        <!--<btn-primario v-show="datos.check_correcto"  texto="Comprobar" @click.native="comprobar_todo()"></btn-primario>-->
        <!--<btn-primario v-show="datos.check_correcto" texto="Guardar" @click.native="siguiente()"></btn-primario>-->
      </div>
    </div>
  </div>
  <div v-show="datos.usuario_aceptado && !datos.asistente_visible">
    <btn-plus-flotante v-show="datos.permisos.crear_contacto" v-bind:texto="'Crear contacto'" @click.native="abrir_asistente()"></btn-plus-flotante>
    <div class="grupo" v-bind:class="{ grupo_oscuro: datos.modo_oscuro }" v-show="datos.contactos_all.length > 0">
      <div class="titulo">
        Contactos disponibles
      </div>
      <div class="row_titulo" v-bind:class="{ row_titulo_oscuro: datos.modo_oscuro }">
        <div class="item">Contacto</div>
        <div class="item">Email</div>
        <div class="item">Móvil</div>
        <div class="item item_acciones">Acciones</div>
      </div>
      <div v-for="item in datos.contactos_all" v-bind:id="item.id"  v-bind:nombre_contacto="item.nombre_contacto" v-bind:mail="item.mail" v-bind:movil="item.movil" class="item_row">
        <div class="item">
          {{item.nombre_contacto}}
        </div>
        <div class="item">
          {{item.mail}}
        </div>
        <div class="item">
          {{item.movil}}
        </div>
        <div class="item item_acciones item_acciones_btn">
          <btn-primario texto="Editar" @click.native="editar_contacto(item.id)"></btn-primario>
          <btn-primario-negativo texto="Eliminar" @click.native="eliminar_contacto(item.id)"></btn-primario-negativo>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import BtnPrimario from '../components/BtnPrimario'
import BtnPrimarioNegativo from '../components/BtnPrimarioNegativo'
import BtnPlusFlotante from "../components/BtnPlusFlotante";
export default {
  name: 'ContactosDisponibles',
  components: {
    BtnPrimario,
    BtnPrimarioNegativo,
    BtnPlusFlotante
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    //Datos de inicio
    datos.contactos_all = [];
    datos.asistente_visible = false;
    datos.paso_asistente = 1;
    datos.mostrar_btn_siguiente = true;
    datos.nombre_contacto = '';
    datos.email_contacto = '';
    datos.movil_contacto = '';

    onMounted(function (){
      //console.log('Variable global',datos);
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    async get_all_contactos() {
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/contactos/all/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        console.log('Contactos', datos.data);
        this.datos.contactos_all = datos.data;

        if (!this.datos.contactos_all){
          this.abrir_asistente();
        }
      }
    },
    abrir_asistente(){
      this.datos.asistente_visible = true;
    },
    validate_form: function(e){
      if (e.keyCode === 13) {
        this.asistente(1);
      }
    },
    asistente(sumar_restar){
      this.datos.paso_asistente = this.datos.paso_asistente+sumar_restar;
      this.datos.mostrar_btn_siguiente = true;
      if (this.datos.paso_asistente == 4){
        this.guardar();
      }
      if (this.datos.paso_asistente == 3){
        this.datos.mostrar_btn_siguiente = false;
      }
    },
    async guardar() {
      this.datos.asistente_visible = false;
      let datos_enviar = {
        nombre: this.datos.nombre_contacto,
        tel: this.datos.movil_contacto,
        mail: this.datos.email_contacto
      }
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/contacto/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200) {
        console.log('ERROR', response.status);
        console.log('data', response);
        this.datos.asistente_visible = true;
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00') {
        console.log(response);
        console.log(datos);
        this.datos.asistente_visible = true;
      } else {
        this.get_all_contactos();
        this.datos.nombre_contacto = '';
        this.datos.movil_contacto = '';
        this.datos.email_contacto = '';
        this.datos.asistente_visible = false;
        return false;
      }
    },
    async eliminar_contacto(id_contacto) {
      this.datos.asistente_visible = false;
      const requestOptions = {
        method: "DELETE",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/contacto/${id_contacto}/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200) {
        console.log('ERROR', response.status);
        console.log('data', response);
        this.datos.asistente_visible = true;
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00') {
        console.log(response);
        console.log(datos);
        this.datos.asistente_visible = true;
      } else {
        this.get_all_contactos();
        this.datos.nombre_contacto = '';
        this.datos.movil_contacto = '';
        this.datos.email_contacto = '';
        this.datos.asistente_visible = false;
        return false;
      }
    },
  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    //this.check_token();
    console.log('Datos', this.datos);
    this.get_all_contactos();
  }
}
</script>

<style scoped lang="scss">
@import '../styles/variables.css';
.item_row{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #f9fafb;
  padding-top: 6px;
  padding-bottom: 6px;
}
.item{
  width: 20%;
}
.item_acciones{
  width: 40%;
}
.item_acciones_btn{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.row_titulo{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: space-around;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--primary-color-2);
  background-color: var(--primary-color-1);
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;
}
.grupo{
  margin: 10px;
  border-color: var(--primary-color-5);
  border-style: solid;
  border-width: 1px;
  background-color: var(--fondo-menu-claro-theme);
}
.grupo .titulo{
  font-size: 22px;
  background-color: var(--primary-color-5);
  color: #FFF;
  padding-top: 4px;
  padding-bottom: 10px;
}
.row_titulo_oscuro{
  border-bottom-color: var(--primary-color-5);
  background-color: var(--primary-color-4);
}
.grupo_oscuro{
  background-color: var(--fondo-menu-dark-theme)!important;
}
.grupo_oscuro .titulo{
  background-color: var(--primary-color-1);
}

.contenedor{
  /*display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-content: space-around;
  align-items: center;*/

  /*width: 60%;*/
  background-color: var(--fondo-claro-theme);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.contenedor_oscuro{
  background-color: var(--fondo-dark-theme);
}
.asistente{
  background-color: var(--fondo-menu-claro-theme);
  width: 60%;
}
.asistente_oscuro{
  background-color: var(--fondo-menu-dark-theme);
}
.asistente .botonera{
  display: flex;
  flex-direction: row;
  align-content: space-around;
  justify-content: flex-end;
  align-items: center;
  margin-top: 36px;
  margin-right: 16px;
  margin-bottom: 16px;
}

.item_form{
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 6px;
  padding-top: 16px;
  padding-bottom: 20px;
}
.item_form .titulo{
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}

.item_form .explicacion{
  margin-bottom: 24px;
  text-align: left;
}
input{
  width: 80%;
}
@media screen and (max-width: 991px){
  .row_titulo{
    display: none!important;
  }
  .item_row{
    flex-wrap: wrap!important;
  }
  .item{
    width: 100%;
  }

  .asistente{
    width: 90%;
  }
  input{
    width: 100%;
  }

}
</style>
