<template>
  <div class="barra_superior">
    <div>
      <a href="https://failder.com">
        <div><img alt="Failder" src="../assets/LOGO_Blue-Black.png"> </div>
      </a>
    </div>
  </div>
  <div>
    <div class="fondo">
    </div>
    <div class="contenedor">
      <div class="escritorio bloque1">
        <div class="div_info">
          <div class="logo_descripcion">
            <a href="https://failder.com">
              <div><img alt="Failder" src="../assets/LOGO_White.png"> </div>
            </a>
          </div>
          <!--<div class="titulo">
            Failder
          </div>-->
          <div class="info">
            Monitoreo gratuito del tiempo de actividad que todo sitio web necesita. Pruebas de disponibilidad, velocidad de carga y comprobaciones de certificados SSL.
          </div>
        </div>
      </div>
      <div class="bloque2 form">
        <div class="div_login">
          <div class="titulo">
            Contacto
          </div>
          <div class="info" style="margin-bottom: 32px;">
            Para cualquier duda o para pedir información de nuestros planes personalizados, contacte con nosotros utilizando el siguiente formulario de contacto.
          </div>
          <div v-show="datos.visibleloading">
            <img style="width: 30px; margin-top: 16px; margin-bottom: 8px;" src="../assets/loading.svg">
          </div>

          <div class="contenedor_form">
            <div class="form form1">
              <div class="form_item">
                <div class="titulo_form">
                  Nombre
                </div>
                <div class="input">
                  <input type="text" class="txt_nombre" v-model="datos.form.nombre">
                </div>
              </div>
              <div class="form_item">
                <div class="titulo_form">
                  Número de Teléfono
                </div>
                <div class="input">
                  <input type="tel" class="txt_tel" v-model="datos.form.tel">
                </div>
              </div>
              <div class="form_item">
                <div class="titulo_form">
                  Email
                </div>
                <div class="input">
                  <input type="email" class="txt_email" v-model="datos.form.email">
                </div>
              </div>

            </div>
            <div class="form form2">
              <div class="form_item">
                <div class="titulo_form">
                  Consulta
                </div>
                <div class="input">
                  <textarea type="text" class="txt_consulta" rows="7" v-model="datos.form.mensaje"></textarea>
                </div>
              </div>
              <div style="display:flex; flex-direction: row-reverse;">
                <div>
                  <btn-primario texto="Enviar" @click.native="enviar_formulario()"></btn-primario>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="div_links">
            <router-link :to="'/'"><btn-alternativo-transparente texto="Login/Registro"></btn-alternativo-transparente></router-link>
            <router-link :to="'/politica-de-privacidad/'"><btn-alternativo-transparente texto="Privacidad"></btn-alternativo-transparente></router-link>
            <router-link :to="'/terminos-y-condiciones/'"><btn-alternativo-transparente texto="Términos y condiciones"></btn-alternativo-transparente></router-link>
          </div>
        </div>
        <!--<a href="https://cubel.dev" target="_blank">
          <div class="copy4" style="margin-top:60px;">
            Failder © 2021. Desarrollo por Cubel.dev
          </div>
        </a>-->
      </div>
    </div>
    <!--<div class="copy">
      <a href="https://cubel.dev" target="_blank">
        <div class="copy1">
          Failder © 2021. Desarrollo por Cubel.dev
        </div>
      </a>
      <div class="copy2">
        <router-link :to="'/politica-de-privacidad/'"><btn-alternativo-transparente texto="Privacidad"></btn-alternativo-transparente></router-link>
        <router-link :to="'/contacto/'"><btn-alternativo-transparente texto="Contacto"></btn-alternativo-transparente></router-link>
        <router-link :to="'/terminos-y-condiciones/'"><btn-alternativo-transparente texto="Términos y condiciones"></btn-alternativo-transparente></router-link>

      </div>
    </div>-->

  </div>
  <!--<div>
    <div class="fondo">
    </div>
    <div class="contenedor">
      <div class="escritorio bloque1">
        <div class="div_info">
          <div class="titulo">
            Failder
          </div>
          <div class="info">
            Monitoreo gratuito del tiempo de actividad que todo sitio web necesita. Pruebas de disponibilidad, velocidad de carga y comprobaciones de certificados SSL.
          </div>
        </div>
      </div>
      <div class="bloque2 form">
        <div class="div_login">
          <div class="titulo">
            Contacto
          </div>
          <div class="info" style="margin-bottom: 32px;">
            Para cualquier duda o para pedir información de nuestros planes personalizados, contacte con nosotros utilizando el siguiente formulario de contacto.
          </div>
          <div v-show="datos.visibleloading">
            <img style="width: 30px; margin-top: 16px; margin-bottom: 8px;" src="../assets/loading.svg">
          </div>

          <div class="contenedor_form">
            <div class="form form1">
              <div class="form_item">
                <div class="titulo_form">
                  Nombre
                </div>
                <div class="input">
                  <input type="text" class="txt_nombre" v-model="datos.form.nombre">
                </div>
              </div>
              <div class="form_item">
                <div class="titulo_form">
                  Número de Teléfono
                </div>
                <div class="input">
                  <input type="tel" class="txt_tel" v-model="datos.form.tel">
                </div>
              </div>
              <div class="form_item">
                <div class="titulo_form">
                  Email
                </div>
                <div class="input">
                  <input type="email" class="txt_email" v-model="datos.form.email">
                </div>
              </div>

            </div>
            <div class="form form2">
              <div class="form_item">
                <div class="titulo_form">
                  Consulta
                </div>
                <div class="input">
                  <textarea type="text" class="txt_consulta" rows="7" v-model="datos.form.mensaje"></textarea>
                </div>
              </div>
              <div style="display:flex; flex-direction: row-reverse;">
                <div>
                  <btn-primario texto="Enviar" @click.native="enviar_formulario()"></btn-primario>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a href="https://cubel.dev" target="_blank">
          <div class="copy4" style="margin-top:60px;">
            Failder © 2021. Desarrollo por Cubel.dev
          </div>
        </a>
      </div>
    </div>
    <div class="copy">
      <a href="https://cubel.dev" target="_blank">
        <div class="copy1">
          Failder © 2021. Desarrollo por Cubel.dev
        </div>
      </a>
      <div class="copy2">
        <router-link :to="'/politica-de-privacidad/'"><btn-alternativo-transparente texto="Privacidad"></btn-alternativo-transparente></router-link>
        <router-link :to="'/contacto/'"><btn-alternativo-transparente texto="Contacto"></btn-alternativo-transparente></router-link>
        <router-link :to="'/terminos-y-condiciones/'"><btn-alternativo-transparente texto="Términos y condiciones"></btn-alternativo-transparente></router-link>

      </div>
    </div>

  </div>-->
</template>

<script>
import sha1 from 'js-sha1';
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import BtnPrimario from '../components/BtnPrimario'
import BtnAlternativoTransparente from '../components/BtnAlternativoTransparente'
import Swal from 'sweetalert2';

export default {
  name: 'Login',
  components: {
    BtnPrimario,
    BtnAlternativoTransparente
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar
    datos.imagen_fondo = '550113.jpg';
    datos.mail = '';
    datos.visiblepass = false;
    datos.visibleloading = false;
    datos.visiblemail = true;
    datos.pass = '';
    datos.salt_recibido = '';
    datos.check_recordar_pass = false;
    datos.view_div_error = false;
    datos.div_titulo_mensaje_error = '';
    datos.div_info_mensaje_error = '';

    datos.visiblebtnverpass = true;


    datos.form = [];
    datos.form.nombre = '';
    datos.form.tel = '';
    datos.form.email = '';
    datos.form.mensaje = '';

    onMounted(function (){
      //console.log('Variable global',datos);
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    mostrar_mensaje_error(texto_error){
      Swal.fire({
        icon: 'error',
        title: '¡Oops!',
        text: texto_error,
      })
    },
    async enviar_formulario() {






      if (this.datos.form.nombre.trim() == ''){
        this.mostrar_mensaje_error('Parece que falta rellenar el nombre.')
        return false;
      }
      if (this.datos.form.email.trim() == ''){
        this.mostrar_mensaje_error('Parece que falta rellenar el correo electrónico.')
        return false;
      }
      if (this.datos.form.mensaje.trim() == ''){
        this.mostrar_mensaje_error('Parece que falta rellenar el mensaje.')
        return false;
      }

      let datos_enviar = {
        "nombre": this.datos.form.nombre.trim(),
        "email": this.datos.form.email.trim(),
        "tel": this.datos.form.tel.trim(),
        "mensaje":this.datos.form.mensaje.trim()
      }
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/web/contacto`, requestOptions);
      if (response.status != 200){
        this.datos.mensaje_error = 'ERROR';
        console.log('ERROR', response.status);
        console.log('data', response);
        this.datos.mostrar_btn_atras = true;
        this.mostrar_mensaje_error('Se ha producido un error.\nInténtalo de nuevo.');
        return false;
      }
      const datos = await response.json();
      console.log('datos web', datos);
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        //this.datos.mensaje_error = 'Error en el servicio. Inténtalo de nuevo';
        //this.datos.mostrar_btn_atras = true;
        this.mostrar_mensaje_error('Se ha producido un error.\nInténtalo de nuevo.');
      } else {
        /* this.datos.mostrar_btn_atras = false;
         this.datos.mostrar_btn_finalizar = true;
         this.datos.mostrar_cargador = false;
         this.datos.mensaje_correcto = '¡Proceso finalizado!'*/
        this.datos.form.nombre = '';
        this.datos.form.email = '';
        this.datos.form.tel = '';
        this.datos.form.mensaje = '';
        //TODO: Requiere un mensaje de confirmacion
        Swal.fire({
          icon: 'success',
          title: '¡Mensaje enviado correctamente!',
          /*text: 'En breve te responderemos',*/
        })
      }
    },


    //Funciones
    //para acceder a la variable datos this.datos
    cargar_fondo_de_pantalla(){
      let array_fondos = ['550113.jpg', '555680.jpg', '555683.jpg','555684.jpg','555705.jpg','817972.jpg','828878.jpg','884555.jpeg','1047595.jpg','1074989.jpg','1080706.jpg'];
      //document.getElementById('fondo').style.backgroundImage=`url(../assets/${array_fondos[2]})`; // specify the image path here
      this.datos.imagen_fondo = array_fondos[2];
    },
    siguiente(){
      if (this.datos.visiblemail){
        this.check_mail();
      } else if (this.datos.visiblepass){
        this.new_salt();
      }
    },
    escribir_mensaje_error(titulo, mensaje){
      if (titulo == '' && mensaje == ''){
        this.datos.view_div_error = false;
      } else {
        this.datos.view_div_error = true;
      }
      this.datos.div_titulo_mensaje_error = titulo;
      this.datos.div_info_mensaje_error = mensaje;
    },
    validateMail: function(e){
      if (e.keyCode === 13) {
        this.check_mail();
      }
    },
    validatePass(e){
      if (e.keyCode === 13) {
        this.new_salt();
      }
    },
    async check_mail() {
      this.datos.visiblemail = false;
      this.datos.visibleloading = true;
      this.escribir_mensaje_error('','');
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      };
      await this.sleep(500);
      const response = await fetch(`${this.datos.url_host}/account/salt/${this.datos.mail.trim()}`, requestOptions);
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        //this.escribir_mensaje_error(`Error código ${datos.code}`, datos.message);
        this.datos.visiblemail = false;
        this.datos.visibleloading = false;
        this.datos.visiblepass = true;
      } else {
        this.escribir_mensaje_error(`Ya existe la cuenta`, `El usuario ${this.datos.mail.trim()} ya existe`);
        this.datos.visiblemail = true;
        this.datos.visibleloading = false;

        //this.datos.salt_recibido = datos.data.salt;
        /*this.view_fragment_1 = !this.view_fragment_1;
        this.view_fragment_2 = !this.view_fragment_2;*/
        //this.$nextTick(() => this.$refs.txt_pass.focus());
      }
    },
    async new_salt() {
      this.datos.visiblepass = false;
      this.datos.visibleloading = true;
      this.escribir_mensaje_error('', '');
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      };
      await this.sleep(500);
      //let sesion = 0;
      //if (this.datos.check_recordar_pass) sesion = 1
      const response = await fetch(`${this.datos.url_host}/account/newsalt/${this.datos.mail.trim()}`, requestOptions);
      const datos = await response.json();

      if (datos.code != '00'){
        this.escribir_mensaje_error(`Error código ${datos.code}`, datos.message);
        console.log(response);
        console.log(datos);
        this.datos.visiblepass = true;
        this.datos.visibleloading = false;
      } else {
        this.datos.salt_recibido = datos.data;
        console.log('SALT',this.datos.salt_recibido);
        //localStorage.token = datos.data.token;
        //window.location.href = "/";
        //let thisStore = this.$store;
        //thisStore.dispatch('goTo', `/`);
        this.crear_cuenta();
      }
    },
    async crear_cuenta() {
      this.datos.visiblepass = false;
      this.datos.visibleloading = true;
      this.escribir_mensaje_error('', '');
      let pass_completo = sha1(this.datos.pass + this.datos.salt_recibido) + ':' + this.datos.salt_recibido;

      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      };


      const response = await fetch(`${this.datos.url_host}/account/create-account/${this.datos.mail.trim()}/${pass_completo}`, requestOptions);
      const datos = await response.json();

      if (datos.code != '00'){
        this.escribir_mensaje_error(`Error código ${datos.code}`, datos.message);
        console.log(response);
        console.log(datos);
        this.datos.visiblepass = true;
        this.datos.visibleloading = false;
        /*this.datos.visiblemail = true;
        this.datos.pass = '';*/
      } else {
        this.check_pass();
      }
    },
    async check_pass() {
      this.datos.visiblepass = false;
      this.datos.visibleloading = true;
      this.escribir_mensaje_error('', '');
      let pass_completo = sha1(this.datos.pass + this.datos.salt_recibido) + ':' + this.datos.salt_recibido;
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      };
      await this.sleep(500);
      let sesion = 0;

      const response = await fetch(`${this.datos.url_host}/account/token/${this.datos.mail.trim()}/${pass_completo}/${sesion}`, requestOptions);
      const datos = await response.json();

      if (datos.code != '00'){
        this.escribir_mensaje_error(`Error código ${datos.code}`, datos.message);
        console.log(response);
        console.log(datos);
        this.datos.visiblepass = false;
        this.datos.visibleloading = false;
        this.datos.visiblemail = true;
        this.datos.pass = '';
      } else {
        localStorage.token = datos.data.token;
        //window.location.href = "/";
        let thisStore = this.$store;
        thisStore.dispatch('goTo', `/`);
      }
    },
    check_event: function(e) {
      this.datos.check_recordar_pass = !this.datos.check_recordar_pass;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    comprobar_token(){
      let thisStore = this.$store;
      if (localStorage.token != undefined) {
        thisStore.dispatch('goTo', `/`);
        return false;
      }
    }
  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    //this.cargar_fondo_de_pantalla();
    //this.comprobar_token();
  },
}
</script>
<style lang="scss" scoped>
@import '../styles/variables.css';
.logo_descripcion img{
  width: 210px;
}
.barra_superior{
  display: none;
}
.div_links{
  margin-top: 60px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
}

.item_form{
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
}
.item_form .titulo_form{
  text-align: left;
  color: var(--color-texto-principal-claro)
}

.form_item {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  text-align: left;
}

textarea {
  font-family: inherit;
  font-size: inherit;
}




.copy{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 20px;
  padding-right: 16px;
  padding-left: 16px;
}
.copy .copy1{
  color: #FFFFFF;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
.copy .copy2{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  max-width: 430px;
  padding-right: 10px;
}
.copy .copy3{
  display: none;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 30px;

}
.div_info{
  text-align: left;
  width: 80%;
  max-width: 480px;
}
.div_info .titulo{
  font-size: 38px;
  font-weight: bold;
  color: #FFFFFF;
}
.div_info .info{
  /*color: var(--color-texto-principal-claro);*/
  font-weight: bold;
  color: #FFFFFF;
}
.div_botonera{
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-between;
  align-items: center;
  color: var(--color-texto-principal-claro);
}
.div_login{
  margin-top: 66px;
  margin-bottom: 66px;
}
.div_login .titulo{
  font-size: 38px;
  font-weight: bold;
}
.div_login .info{
  color: var(--color-texto-principal-claro);
  font-weight: bold;
}
.contenedor{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.bloque1{
  width: 40%;
  background-color: #00000060;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.bloque2{
  width: 60%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.div_login{
  width: 80%;
  max-width: 400px;
}
.div_login{
  margin-top: 66px;
  margin-bottom: 66px;
}
.fondo{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  /* The image used */
  background-image: url("../assets/backgrounds/1080706.jpg");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.movil{
  display: none;
}
.escritorio{
  display: flex;
}
.div_error{
  background-color: var(--primary-color-5);
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  color: #FFF;
}
.div_title_error{
  font-weight: bold;
}
.copy4{
  display: none;
}
@media screen and (max-width: 991px){
  .movil{
    display: flex;
  }
  .escritorio{
    display: none;
  }
  .bloque2{
    width: 100%;
  }
  .barra_superior{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: var(--fondo-menu-claro-theme);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
    position: fixed;
    width: 100%;
  }
  .barra_superior img{
    height: 46px;
  }
  .copy .copy1{
    color: inherit;
  }

  .copy .copy1{
    display: none;
  }
  .copy .copy3{
    display: none;
  }
  .copy{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 16px;
    padding-left: 16px;
    height: 66px;
  }
  .copy4{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 16px;
    padding-left: 16px;
    cursor: pointer;
  }
  .div_links{
    display: inherit;
    bottom: inherit;
    right: inherit;
    position: inherit;
    margin-bottom: 60px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 480px){
  .copy .copy1{
    display: none;
  }
  .copy .copy3{
    display: none;
  }
  .copy{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 16px;
    padding-left: 16px;
    height: 66px;

  }
}
@media screen and (max-width: 660px){
  .barra_superior img{
    height: 30px;
  }
}
</style>
