<template>
  <div class="item_licencia" v-bind:class="{ item_licencia_oscuro: datos.modo_oscuro }">
    <div style="width: 100%;">
      <div class="row_licencia">
        <div class="col_licencia">
          <div class="title_col_licencia">Código</div>
          <div>{{ codigo }}</div>
        </div>
        <div class="col_licencia">
          <div class="title_col_licencia">Nombre</div>
          <div>{{ nombre }}</div>
        </div>
        <div class="col_licencia">
          <div class="title_col_licencia">Duración</div>
          <div v-show="duracion > 1">{{duracion}} meses</div>
          <div v-show="duracion == 1">{{duracion}} mes</div>
        </div>
        <div class="col_licencia">
          <div class="title_col_licencia">Días LOG</div>
          <div>{{ dias_log }}</div>
        </div>
        <div class="col_licencia">
          <div class="title_col_licencia">Proyectos</div>
          <div>{{ cantidad_proyectos }}</div>
        </div>
        <div class="col_licencia">
          <div class="title_col_licencia">SMS/Contactos</div>
          <div>{{ cantidad_sms }}/{{ cantidad_contactos }}</div>
        </div>
      </div>
    </div>
    <div class="div_btn_licencia" v-show="!datos.mostrar_cargando_pagar_activar">
      <btn-primario texto="Activar" @click.native:="activar_licencia(codigo)"></btn-primario>
    </div>
    <div v-show="datos.mostrar_cargando_pagar_activar && datos.mostrar_cargando_pagar_activar_codigo == codigo">
      <div><img style="width: 26px; margin-top: 16px; margin-bottom: 8px;" src="../assets/loading.svg"></div>
    </div>

  </div>

</template>

<script>
import {useStore} from "vuex"
import {computed, onMounted} from "vue";
import BtnPrimario from '../components/BtnPrimario'
import ItemPrecioLicencia from "@/components/ItemPrecioLicencia";
import BtnPrimarioTransparente from "@/components/BtnPrimarioTransparente";
import Cargando from "@/components/Cargando";
export default {
  name: 'ItemLicenciasPendintes',
  components: {
    BtnPrimario,
  },
  props: {
    codigo: {
      default: '',
      type: String,
    },
    nombre: {
      default: '',
      type: String,
    },
    duracion: {
      default: 0,
      type: Number,
    },
    dias_log: {
      default: 0,
      type: Number,
    },
    cantidad_proyectos: {
      default: 0,
      type: Number,
    },
    cantidad_sms: {
      default: 0,
      type: Number,
    },
    cantidad_contactos: {
      default: 0,
      type: Number,
    },
  },
  setup(){
    const store = useStore();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    //Datos de inicio
    datos.mostrar_cargando_pagar_activar_codigo = 0;
    datos.mostrar_cargando_pagar_activar = false;

    onMounted(function (){
      store.dispatch("get_all_store",datos.id_plan); //Cargamos lo necesario
      console.log('AAAAAA',datos.info_tipo_licencia)
    });


    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    async activar_licencia(codigo_licencia) {

      if (this.datos.info_cuenta != undefined && this.datos.info_cuenta.nombre != '' && this.datos.info_cuenta.nombre != '--'){
        if (!confirm("¿Estás seguro que quieres activar esta licencia?\nSi continuas, se eliminará la licencia que tienes en uso.")) {
          return false;
        }
      }


      this.datos.mostrar_cargando_pagar_activar_codigo = codigo_licencia;
      this.datos.mostrar_cargando_pagar_activar = true;
      let datos_enviar = {
        codigo_licencia: codigo_licencia,
      };

      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/licencias/activar-licencia/${this.datos.id_plan}`, requestOptions);

      if (response.status != 200) {
        console.log('ERROR', response.status);
        console.log('data', response);
        this.datos.mostrar_cargando_pagar_activar = false;
        return false;
      }
      const datos = await response.json();
      /*console.log('RESPUWSTA', datos);
      return false;*/
      if (datos.code != '00') {
        console.log(response);
        console.log(datos);
        this.datos.mostrar_cargando_pagar_activar = false;
      } else {
        //this.datos.mostrar_cargando_pagar_activar = false;
        console.log('recibido', datos.data);
        location.reload();
      }
    }
  },
  /*setup(props){
    let seleccionado = false;
    console.log('MENU', props.texto.toLowerCase());
    console.log('MENU2', window.location.pathname)
    / *if (window.location.pathname == `/${props.texto.toLowerCase()}`){
      seleccionado = true;
    }* /
   if (window.location.pathname.indexOf(props.texto.toLowerCase()) > -1){
     seleccionado = true;
   }

    return {
      seleccionado: seleccionado,
    }
  }*/
}
</script>

<style scoped lang="scss">
.item_licencia{
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  border-style: solid;
  border-width: 1px;
  border-color: var(--primary-color-5);
  margin-top: 10px;
  margin-bottom: 10px;
}
.item_licencia_oscuro{
  border-color: var(--primary-color-1)!important;
}
.row_licencia{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.col_licencia{
  width: 33%;
  margin-top: 6px;
  margin-bottom: 6px;
}
.title_col_licencia{
  font-weight: bold;
  font-size: 18px;
}
.div_btn_licencia{
  width: 33%;
}
@media screen and (max-width: 1170px){
  .col_licencia{
    width: 50%;
  }
  .div_btn_licencia{
    width: 50%;
  }
}
@media screen and (max-width: 991px){
  .div_btn_licencia{
    width: 100%;
  }
}
</style>
