<template>
  <div class="menu_movil" v-show="datos.usuario_aceptado && datos.mostrar_menu">
    <div class="tam_menu_movil encabezado" v-bind:class="{ menu_movil_oscuro: datos.modo_oscuro }">
      <div style="display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;">
        <!--<div style="margin-left: 16px;"><img style="width: 50px;" alt="Vue logo" src="../assets/logo.png"></div>
        <div class="logo_nombre">Failder</div>-->
        <div v-show="!datos.modo_oscuro"  style="margin-left: 18px;"><img style="height: 46px;" alt="Failder" src="../assets/LOGO_Blue-Black.png"> </div>
        <div v-show="datos.modo_oscuro"  style="margin-left: 18px;"><img style="height: 46px;" alt="Failder" src="../assets/LOGO_White.png"> </div>


      </div>
      <div class="div_btn_menu"  v-bind:class="{ div_btn_menu_oscuro: datos.modo_oscuro }" @click.native="btn_menu()">
        <font-awesome-icon :icon="['fa', 'bars']" class="icon alt"/>
      </div>

    </div>
    <div class="tam_menu_movil">
      <!-- Contenedor espacio -->
    </div>
    <div class="contenedor_items_menu" v-bind:class="{ contenedor_items_menu_oscuro: datos.modo_oscuro }" v-show="datos.mostrar_items_menu">
      <router-link :to="'/dashboard/'+ datos.id_plan" @click.native="ocultar_menu()"><ItemMenuMovil icon="home" prefix="fa" texto="Dashboard" texto_seleccionado="Dashboard"></ItemMenuMovil></router-link>
      <router-link :to="'/proyectos/'+ datos.id_plan" @click.native="ocultar_menu()"><ItemMenuMovil icon="window-maximize" prefix="fa" texto="Proyectos" texto_seleccionado="Proyectos"></ItemMenuMovil></router-link>
      <router-link :to="'/contactos/'+ datos.id_plan" @click.native="ocultar_menu()"><ItemMenuMovil icon="users" prefix="fa" texto="Contactos" texto_seleccionado="Contactos"></ItemMenuMovil></router-link>
      <router-link :to="'/userconfig/'+ datos.id_plan" @click.native="ocultar_menu()"><ItemMenuMovil icon="user-cog" prefix="fa" texto="Configuración personal" texto_seleccionado="Confpersonal"></ItemMenuMovil></router-link>
      <router-link :to="'/planconfig/'+datos.id_plan" @click.native="ocultar_menu()"><ItemMenuMovil icon="cogs" prefix="fa" texto="Configuración cuenta" texto_seleccionado="Confcuenta"></ItemMenuMovil></router-link>
      <router-link :to="'/selectaccount/'" @click.native="ocultar_menu()"><ItemMenuMovil icon="briefcase" prefix="fa" texto="Cambiar de cuenta" texto_seleccionado="Camcuenta"></ItemMenuMovil></router-link>

      <div style="width: 100%">
        <ItemMenuMovil icon="sign-out-alt" prefix="fa" texto="Cerrar sesión" @click.native="abrir_pagina('cerrar_sesion')"></ItemMenuMovil>
      </div>
    </div>
    <div v-show="datos.mostrar_items_menu" class="sombra_pantalla" @click.native="ocultar_menu()"></div>
  </div>

</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import ItemMenuMovil from '../components/ItemMenuMovil'
export default {
  name: 'MenuMovil',
  components: {
    ItemMenuMovil
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    //Datos de inicio
    datos.id_plan = route.params.id;
    datos.mostrar_items_menu = false;

    onMounted(function (){
      //console.log('Variable global',datos);
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    abrir_pagina(pagina){
      let thisStore = this.$store;
      if(pagina == 'cerrar_sesion'){
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/`);
        return false;
      }
      console.log('Abrir página', pagina);
      thisStore.dispatch('goTo', `/${pagina}`);
    },
    btn_menu(){
      this.datos.mostrar_items_menu = !this.datos.mostrar_items_menu;
    },
    ocultar_menu(){
      this.datos.mostrar_items_menu = false;
    }

  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
  },
}
</script>
<style scoped lang="scss">
@media screen and (min-width: 992px){
  .menu_movil{
    display: none;
  }
}
.tam_menu_movil{
  width: 100%;
  height: 74px;
}
.sombra_pantalla{
  background-color: #00000070;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
}
.contenedor_items_menu{
  z-index: 2;
  width: 100%;
  position: fixed;
  background-color: var(--fondo-menu-claro-theme);
}
.contenedor_items_menu_oscuro{
  background-color: var(--fondo-menu-dark-theme);
}
.encabezado{
  background-color: var(--fondo-menu-claro-theme);
  width: 100%;
  position: fixed;
  top:0;
  left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px;
  z-index: 2;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 4px 5px 0px rgba(0,0,0,0.75);
}
.div_btn_menu{
  font-size: 30px;
  color: var(--primary-color-5);
  margin-right: 16px;
  /* background-color: red; */
  /* padding: 10px; */
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
.div_btn_menu_oscuro{
  color: var(--detalles-gris-muy-clarito);
}
.logo_nombre{
  font-size: 30px;
  font-weight: bold;
  margin-left: 14px;
  color:#FFFFFF;
}
/*.bloqueador {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #6f6f6f;
  z-index: 10;
}
.div_menu_oculto{
  width: 90%;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  background-color: aquamarine;
  top: 0;
  left: -90%;
  position: fixed;
  z-index: 11;


}
.div_menu_oculto_animacion{
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.div_menu_oculto_animacion_ocultar{
  animation-name: example2;
  animation-duration: 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes example {
  from {
    background-color: red;
    position: fixed;
    top: 0;
    left: -90%;
  }
  to {
    background-color: yellow;
    position: fixed;
    top: 0;
    left: 0;
  }
}
@keyframes example2 {
  from {
    background-color: yellow;
    position: fixed;
    top: 0;
    left: 0;
  }
  to {

    background-color: red;
    position: fixed;
    top: 0;
    left: -90%;
  }
}*/
/*.menu_movil{

}*/
/*.div_menu{
  background-color: var(--primary-color);
  height: 100%;
  position: fixed;
  top:0;
  left: 0;
}

.tam_menu_pc{
  width: 300px;
}*/
.menu_movil_oscuro{
  background-color: var(--fondo-menu-dark-theme);
}

</style>
