<template>
  <div v-show="datos.usuario_aceptado" class="contenedor_carteles">
    <div class="item_mini_cartel" v-bind:class="{ item_mini_cartel_oscuro: datos.modo_oscuro }">
      <div class="cajon">
        <div class="titulo">Datos de la cuenta</div>
        <div style="display: flex;">
          <div class="form_item">
            <div>
              Nombre
            </div>
            <div class="input">
              <input type="text" placeholder="Nombre" v-model="datos.temporales.nombre" v-on:keyup="validate_form" required >
            </div>
          </div>
          <div class="form_item">
            <div>
              Apellido
            </div>
            <div class="input">
              <input type="text" placeholder="Apellido" v-model="datos.temporales.apellidos" v-on:keyup="validate_form" required >
            </div>
          </div>
        </div>
        <div class="form_item">
          <div>
            Email
          </div>
          <div class="input">
            <input type="email" placeholder="Email" v-model="datos.usuario.email" v-on:keyup="validate_form" :disabled=true>
          </div>
        </div>
        <div class="form_item">
          <div>
            Tema
          </div>
          <div style="display: flex; justify-content: space-around;">
            <btn-primario-transparente texto="Oscuro" @click.native="cambiar_tema(true)"></btn-primario-transparente>
            <btn-primario-transparente texto="Claro" @click.native="cambiar_tema(false)"></btn-primario-transparente>
          </div>
        </div>
        <div class="div_acciones">
          <btn-primario texto="Guardar" @click.native="update_account_data()"></btn-primario>
        </div>
      </div>
    </div>
    <div class="item_mini_cartel" v-bind:class="{ item_mini_cartel_oscuro: datos.modo_oscuro }">
      <div class="cajon">
        <div class="titulo">Cambio de contraseña</div>
        <div class="form_item">
          <div>
            Contraseña actual
          </div>
          <div class="input">
            <input type="password" placeholder="Contraseña actual" v-model="datos.pass_viejo" v-on:keyup="validate_form" required >
          </div>
        </div>
        <div class="form_item">
          <div>
            Nueva contraseña
          </div>
          <div class="input">
            <input type="password" placeholder="Nueva contraseña" v-model="datos.pass_nuevo1" v-on:keyup="validate_form" required >
          </div>
        </div>
        <div class="form_item">
          <div>
            Repite la nueva contraseña
          </div>
          <div class="input">
            <input type="password" placeholder="Repite la nueva contraseña" v-model="datos.pass_nuevo2" v-on:keyup="validate_form" required >
          </div>
        </div>
        <div class="div_acciones">
          <btn-primario texto="Guardar" @click.native="update_account_access()"></btn-primario>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import BtnPrimario from '../components/BtnPrimario'
import BtnPrimarioTransparente from "@/components/BtnPrimarioTransparente";
import sha1 from 'js-sha1';
export default {
  name: 'Configuracion',
  components: {
    BtnPrimario,
    BtnPrimarioTransparente
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    //Datos de inicio
    datos.id_plan = route.params.id;
    datos.mostrar_menu = true;
    datos.mostrar_items_menu = false;
    datos.temporales = {
      nombre: '',
      apellidos: '',
    }

    datos.pass_viejo = '';
    datos.pass_nuevo1 = '';
    datos.pass_nuevo2 = '';
    datos.salt = '';
    datos.nuevo_salt = '';


    datos.menu_padre_seleccioando = 'Confpersonal';


    onMounted(function (){
      console.log('Comprobar URL 4', route.fullPath)
      store.dispatch("get_all_store",datos.id_plan); //Cargamos lo necesario
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    async cambiar_tema(oscuro){
      this.datos.modo_oscuro = oscuro;

      if (this.datos.modo_oscuro){
        document.body.classList.add("fondo_dark_theme");
        document.getElementById('app').classList.add("texto_dark_theme");
      } else {
        document.body.classList.remove("fondo_dark_theme")
        document.getElementById('app').classList.remove("texto_dark_theme");
      }
      this.update_account_data();
    },
    async update_account_data() {
      let datos_enviar = {
        nombre: this.datos.temporales.nombre,
        apellidos: this.datos.temporales.apellidos,
        email: this.datos.usuario.email,
        modo_oscuro: this.datos.modo_oscuro,
        id_usuario: this.datos.usuario.id
      }
      const requestOptions = {
        method: "PUT",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/account/data`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        //console.log('Contactos', datos.data);
        console.log('Datos_actualizados')
        //TODO: Mostrar cartel de actualizado
        this.datos.usuario.nombre = this.datos.temporales.nombre;
        this.datos.usuario.apellidos = this.datos.temporales.apellidos;
      }
    },
    async update_account_access() {
      if (this.datos.pass_nuevo1.trim() != this.datos.pass_nuevo2.trim()){
        alert('Las dos contraseñas nuevas no coinciden.');
        return false;
      }

      //Sacamos el SALT;
      let requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      let response = await fetch(`${this.datos.url_host}/account/salt/${this.datos.usuario.email}`, requestOptions);
      if (response.status != 200) {
        /*let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);*/
        console.log('ERROR', response.status);
        console.log(response);
        return false;
      }

      //Respuesta 200
      const respuestaOK = await response.json();
      if(respuestaOK.code != '00'){
        console.log('ERROR', respuestaOK.code);
        console.log(respuestaOK);
        return false;
      }
      this.datos.salt = respuestaOK.data.salt;


      //Comprobamos la contraseña
      let pass_completo = sha1(this.datos.pass_viejo + this.datos.salt) + ':' + this.datos.salt;
      requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      response = await fetch(`${this.datos.url_host}/account/comprobar_pass/${this.datos.usuario.email}/${pass_completo}`, requestOptions);
      if (response.status != 200) {
        /*let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);*/
        console.log('ERROR', response.status);
        console.log(response);
        alert('Las contraseña actual no es correcta.');
        return false;
      }
      let datos = await response.json();
      if(datos.code != '00'){
        console.log('ERROR', datos.code);
        console.log(datos);
        alert('Las contraseña actual no es correcta.');
        return false;
      }

      //Si llega hasta aquí es que la contraseña es correcta, por lo tanto pedimos un salt nuevo.
      requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      response = await fetch(`${this.datos.url_host}/account/newsalt_cambio_pass/`, requestOptions);
      if (response.status != 200) {
        /*let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);*/
        console.log('ERROR', response.status);
        console.log(response);
        return false;
      }
      datos = await response.json();
      if(datos.code != '00'){
        console.log('ERROR', datos.code);
        console.log(datos);
        return false;
      }
      this.datos.nuevo_salt = datos.data.salt;
      console.log('Nuevo salt', this.datos.nuevo_salt);


      let datos_enviar = {
        newpass: sha1(this.datos.pass_nuevo1 + this.datos.nuevo_salt) + ':' + this.datos.nuevo_salt,
        id_usuario: this.datos.usuario.id
      }
      requestOptions = {
        method: "PUT",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      response = await fetch(`${this.datos.url_host}/account/actualizarpass`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        let thisStore = this.$store;
        thisStore.dispatch('goTo', `/login`);
        return false;
      }

      /////////////////////////////////////////
    },
    async comprobar_token_usuario() {
      let thisStore = this.$store;
      if (localStorage.token == undefined) {
        thisStore.dispatch('goTo', `/login`);
        return false;
      }

      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      const response = await fetch(`${this.datos.url_host}/account/checktoken`, requestOptions);
      if (response.status != 200) {
        let thisStore = this.$store;
        localStorage.removeItem('token');
        thisStore.dispatch('goTo', `/login`);
        return false;
      }

      //Respuesta 200
      const respuestaOK = await response.json();
      console.log('Token valido');
      console.log(respuestaOK);
      if(respuestaOK.code == '00'){

        let nombre = respuestaOK.data.nombre.trim();
        let apellidos = respuestaOK.data.apellidos.trim();


        this.datos.temporales.nombre = nombre;
        this.datos.temporales.apellidos = apellidos;
        return false;
      } else {
        localStorage.removeItem('token');
        //TODO: Otro tipo de error
        thisStore.dispatch('goTo', `/login`);
        return false;
      }
    },
  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    //this.check_token();
    //this.get_all_contactos();
    console.log('DATOS USUARIO', this.datos.usuario);
    /*this.datos.usuario = {
      id: respuestaOK.data.id,
      nivel: respuestaOK.data.nivel,
      email: respuestaOK.data.email,
      nombre: nombre,
      apellidos: apellidos,
      iniciales: iniciales,
    }*/
    this.comprobar_token_usuario();
  },
}
</script>
<style lang="scss" scoped>
@import '../styles/variables.css';




</style>
