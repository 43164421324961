<template>
  <div class="barra_superior">
    <div>
      <a href="https://failder.com">
        <div><img alt="Failder" src="../assets/LOGO_Blue-Black.png"> </div>
      </a>
    </div>
    <div>
      <a href="/">
      <div>Cerrar</div>
      </a>
    </div>
  </div>
  <div>
    <div class="fondo">
    </div>
    <div class="contenedor">
      <div class="escritorio bloque1">
        <div class="div_info">
          <div class="logo_descripcion">
            <a href="https://failder.com">
              <div><img alt="Failder" src="../assets/LOGO_White.png"> </div>
            </a>
          </div>
          <!--<div class="titulo">
            Failder
          </div>-->
          <div class="info">
            Términos y condiciones
          </div>
          <div style="position: absolute; top: 20px; left: 20px;">
            <a href="/">
              <div>Cerrar</div>
            </a>
          </div>
        </div>
      </div>
      <div class="bloque2 form">
        <div>
          1. EL ACUERDO
        </div>
        <div>
          <p>
            1. Este Acuerdo rige su relación con Failder (en lo sucesivo, "Failder.com", "nosotros", "nuestro") y su uso del Servicio Failder y reemplaza todas y cada una de las versiones anteriores del Acuerdo.
          </p>

          <p>
            2. Cuando envía su Formulario de registro completo en el sitio web del Servicio de Failder (el "Sitio web de Failder"), reconoce su aceptación y acuerdo de cumplir con todos los términos y condiciones establecidos en este Acuerdo que puedan ser enmendados y/o modificado de vez en cuando.
          </p>

          <p>
            3. La información que complete y luego nos envíe en el Formulario de registro o como nos proporcione, o solicitemos, en el curso del uso del Servicio Failder debe ser veraz y completa en todo momento. Si alguna o toda la información que nos ha proporcionado cambia, entonces es su responsabilidad actualizarla en los sistemas o notificarnos por escrito por correo electrónico.
          </p>

          <p>
            4. Este Acuerdo se forma (la "Fecha de vigencia") con el envío del Formulario de registro y nuestra creación de su cuenta de cliente de Failder ("Cuenta de cliente").
          </p>

          <p>
            5. Usted reconoce y acepta que podemos, en cualquier momento, a nuestro exclusivo y absoluto criterio, modificar y/o enmendar cualquiera de los términos de este Acuerdo ("Cambios en el Acuerdo").
          </p>

          <p>
            6. Podemos notificarle de cualquier Cambio en el Acuerdo ya sea: (a) enviando un correo electrónico a los datos de contacto relevantes que envió en su Formulario de registro; o (b) mediante la publicación de la nueva versión del Acuerdo en el sitio web de Failder.
          </p>

          <p>
            7. Usted reconoce y acepta que tenemos el derecho de realizar cambios en el acuerdo sin previo aviso y sin causa, y que dichos cambios en el acuerdo entrarán en vigencia tan pronto como le hayamos notificado en virtud de la cláusula 1.6 anterior. Al continuar utilizando los Servicios de Failder después de cualquier Cambio en el Acuerdo, usted reconoce su aceptación del mismo.
          </p>

          <p>
            8. Cuando haya un conflicto entre cualquiera de los contenidos establecidos en los Servicios de Failder y/o cualquier correspondencia entre usted y nosotros (ya sea por escrito o de otro modo), y/o este Acuerdo, prevalecerá el Acuerdo.
          </p>

          <p>
            9. Es su responsabilidad visitar el sitio web de Failder con frecuencia para asegurarse de tener la última versión del Acuerdo.
          </p>

        </div>

        <div>
          2. PRESTACIÓN DEL SERVICIO FAILDER
        </div>

        <div>
          <p>
            1. El Servicio Failder se proporcionará para su uso a partir de la Fecha de entrada en vigor de acuerdo con este Acuerdo.
          </p>
          <p>

          </p>
          2. Usted reconoce y acepta que la prestación del Servicio Failder está condicionada a que: (a) cumpla con todos los términos y condiciones de este Acuerdo; y (b) proporcionarnos con prontitud toda la asistencia e información razonables que podamos razonablemente requerir de vez en cuando para permitirnos brindarle el Servicio Failder.
          <br>

        </div>

        <div>
          3. TIPOS DE LICENCIAS
        </div>
        <div>
          <p>
            1. Hay tres (3) tipos de licencias disponibles para usted:
          </p>
          <div style="padding-left: 22px;">
            <p>
              (a) Licencia gratuita, ("Licencia gratuita");
            </p>
            <p>
              (b) Licencia de pago, cuyos diferentes niveles pueden denominarse de vez en cuando "Licencia Básica", "Licencia Estándar" o "Licencia Avanzada" (cada una de las cuales es una "Licencia de pago"); y
            </p>
            <p>
              (c) Licencia de regalo, ("Licencia de regalo") de una licencia de pago.
            </p>
          </div>
          <p>
            2. La licencia de regalo le permite tener acceso, sin cargo, a todas esas funciones y beneficios de una Licencia de pago por un período de treinta (30) días sin cargo (el "Período de Prueba"). Al final del período de prueba, puede actualizar a una cuenta de pago o, si no se realiza ninguna actualización, su cuenta se degradará a la espera de activar una licencia gratuita.
          </p>
          <p>
            3. Los detalles de las características de la licencia gratuita, junto con los costos y las características de las licencias de pago, se pueden encontrar actualmente en la página del plan de pago del sitio web de Failder (el "Nuestros planes").
          </p>
          <p>
            4. Sujeto a su adhesión a todos los términos de este Acuerdo, tendrá derecho durante la vigencia de este Acuerdo a aquellas características, límites de prueba y precios que estaban disponibles en la licencia gratuita o licencia de pago en la Fecha de entrada en vigencia. ("Funciones con derechos adquiridos").
          </p>
          <p>
            5. No está permitido vender o transferir su cuenta a un tercero sin el consentimiento previo por escrito de Failder.
          </p>
        </div>




        <div>
          4. LICENCIA GRATUITA
        </div>
        <div>
          <p>
            1. Es una condición de su licencia gratuita que permanezca activa. Para los propósitos de este Acuerdo, "activo" significa que su licencia Gratuita ha sido iniciada dentro de los últimos treinta (30) días. Cualquier licencia gratuita que no cumpla con estos criterios se considerará "abandonada" y pasará a estar inactiva.
          </p>
          <p>
            2. Si bien nos esforzaremos por recordar a los clientes siete (7) días antes de que su licencia gratuita se abandone, es su responsabilidad asegurarse de que su licencia esté activa, independientemente de si se ha recibido algún recordatorio.
          </p>
          <p>
            3. Tenemos derecho a pausar, suspender o eliminar cualquier licencia gratuita abandonada a nuestra absoluta discreción.
          </p>
        </div>

        <div>
          5. PAGOS DE LICENCIAS DE PAGO
        </div>
        <div>
          <p>
            1. Las licencias de pago se pueden comprar por mes (un "plan mensual"), por trimestre (un "plan trimestral"), por semestre (un "plan semestral") o por año (un "plan anual"). Cada uno se renueva automáticamente siempre y cuando tenga una nueva licencia a la espera de ser activada.
          </p>
          <p>
            2. Si tiene una licencia a la espera de ser activada, se activará automáticamente cuando la licencia en uso, caduque. En caso de activar manualmente una licencia que esté en espera, la licencia actual será cancelada y borrada.
          </p>
          <p>
            3. De vez en cuando podemos aumentar el precio de nuestras licencias ("nuevos precios"). En el caso de que tengamos que aumentar el precio de una licencia personalizada nos pondremos en contacto con el cliente a través de correo electrónico. Si no recibimos una respuesta de conformidad, eliminaremos la licencia personalizada para que no pueda ser renovada.
          </p>
        </div>


        <div>
          6. ACTUALIZAR Y BAJAR LA CALIFICACIÓN DE UNA LICENCIA DE PAGO
        </div>
        <div>
          <p>
            1. En el caso de que decida actualizar una licencia pagada a un nivel superior de licencia, solo a modo de ejemplo, de una licencia estándar a una licencia avanzada tendrá acceso a las funciones de la licencia avanzada una vez caduque la licencia actual en uso. Podrá activar la nueva licencia manualmente, teniendo en cuenta que su licencia actual será cancelada y borrada.
          </p>
          <p>
            2. En el caso de que decida actualizar una licencia pagada a un nivel inferior de licencia, solo a modo de ejemplo, de una licencia avanzada a una licencia estándar tendrá acceso a las funciones de la licencia estándar una vez caduque la licencia actual en uso. Podrá activar la nueva licencia manualmente, teniendo en cuenta que su licencia actual será cancelada y borrada.
          </p>
        </div>
        <div>
          7. NOTIFICACIONES POR SMS
        </div>
        <div>
          <p>
            1. Cuando tenga una licencia de pago que tenga derecho al envío de notificaciones por SMS, solo a modo de ejemplo, cuando una licencia de pago tenga derecho a cuarenta (40) SMS por mes, estos SMS no se pueden transferir de un mes a otro, por lo tanto, al siguiente mes volverá a tener disponibles todos los SMS vinculados a esa licencia.
          </p>
        </div>

        <div>
          8. FACTURACIÓN
        </div>
        <div>
          <p>
            1. Para poder pagar una licencia de pago, será necesario que se rellenen los datos de facturación. Hasta que no se rellenen los datos de facturación no tendrá opcion de usar una licencia de pago.
          </p>
          <p>
            2. Las facturas se enviarán por correo electrónico o se podrán descargar desde la web una vez estén generadas.
          </p>
          <p>
            3. Las facturas se generarán en los próximos diez (10) días a la adquisición de la licencia de pago.
          </p>
        </div>
        <div>
          9. USO AUTORIZADO DEL SERVICIO FAILDER
        </div>
        <div>
          <p>
            1. En consideración a que nos proporcionó la información que nos proporcionó cuando se registró para obtener una Cuenta de cliente y, cuando corresponda, en consideración de su pago por una licencia de pago, y sujeto a las demás disposiciones de este Acuerdo, usted concedió una licencia no exclusiva e intransferible, durante la vigencia de este Acuerdo, para acceder y utilizar el Servicio Failder y los Datos del Cliente.
          </p>
          <p>
            2. Salvo que se establezca expresamente en este Acuerdo, todos los derechos sobre el Servicio Failder, incluido el software y los datos (en conjunto y en forma individual, el "Software Failder") están reservados para nosotros.
          </p>
          <p>
            3. Usted no deberá:
          </p>
          <div style="padding-left: 22px;">
            <p>
              (a) permitir que ningún tercero acceda o use el Servicio Failder o el Software Failder o use el Servicio Failder o el Software Failder en nombre de un tercero;
            </p>
            <p>
              (b) copiar, modificar, adaptar o crear trabajos derivados del software Failder o cualquier resultado del software Failder;
            </p>
            <p>
              (c) intentar descubrir, obtener acceso o aplicar ingeniería inversa al Servicio Failder o al Software Failder;
            </p>
            <p>
              (d) intentar interferir con el funcionamiento correcto del Software Failder o el Servicio Failder y, en particular, no intentar eludir la seguridad, el control de licencias u otros mecanismos de protección, o manipular, piratear o interrumpir de cualquier otra forma el Software Failder o Failder Servicio o cualquier sitio web, sistema informático, servidor, enrutador o cualquier otro dispositivo conectado a Internet; o
            </p>
            <p>
              (e) ocultar, modificar o eliminar cualquier aviso de derechos de autor, marca comercial u otra marca de propiedad en, o visible durante la operación o uso del Software Failder o el Servicio Failder; y no permitirá que ningún tercero haga nada de lo anterior.
            </p>
          </div>
          <p>
            4. Sin perjuicio de lo anterior, no debe utilizar Failder, el Servicio Failder o cualquier otro servicio que podamos ofrecer de vez en cuando de cualquier manera que sea ilegal o que de cualquier otra manera nos cause daño o perjuicio y/u otros usuarios de Failder, el Servicio Failder y/o nuestros servicios ("Daño del servicio"). Dicho uso puede incluir, entre otros, agregar una cantidad desproporcionada de sitios web, pruebas u otras solicitudes en Failder, el Servicio y/o los servicios de Failder, teniendo en cuenta en todo momento el nivel de cuenta en el que se encuentra y el nivel de recursos que sus solicitudes demandarán ("Uso legítimo"). Nos reservamos el derecho, a nuestra única y absoluta discreción, de limitar, restringir y/o cancelar cualquier cuenta que cause Daño al Servicio o infrinja nuestra política de Uso Justo.
          </p>
          <p>
            5. Solo puede usar Failder y el Servicio Failder para monitorear sitios web de su propiedad y/o controlados por usted. En virtud de este Acuerdo, no está autorizado a usar Failder y/o el Servicio Failder para monitorear sitios web de terceros (que incluirán, para evitar dudas, cualquier sitio web que no sea de su propiedad y/o controlado por usted y donde no tenga permiso previo por escrito del propietario del sitio web para monitorear su sitio web). Sin perjuicio de lo anterior, dicho uso puede incluir, entre otros, el monitoreo de sitios web de terceros con el fin de crear investigaciones o informes sobre esos sitios web para cualquier propósito.
          </p>
          <p>
            6. No debe usar Failder, el Servicio Failder o cualquier otro servicio que podamos ofrecer ocasionalmente de cualquier manera que sea ilegal, que de cualquier otra manera cause daño o perjuicio a terceros. Dicho uso puede incluir, entre otros, intentar probar o realizar solicitudes a un sitio web que no le pertenece; usar el Servicio Failder para "mantener vivo" un sitio web, cualquier aplicación o bot; o usar el Servicio Failder de una manera o con un propósito para el cual, a nuestro exclusivo y absoluto criterio, no está destinado.
          </p>
          <p>
            7. El Servicio de Failder es solo para su uso y no puede, sin nuestro consentimiento previo por escrito (que podemos retener a nuestra absoluta discreción), permitir que un tercero utilice el Servicio de Failder, revender u ofrecer para la venta el Servicio Failder.
          </p>
          <p>
            8. Cuando creamos, a nuestra única y absoluta discreción, que usted está o es probable que infrinja cualquier disposición de esta Cláusula 9, tendremos derecho a suspender y/o cancelar de inmediato, mediante notificación por escrito, su Cuenta de usuario de Failder y prohibirle a usted o a cualquier asociado registrarse en cualquier otra cuenta de usuario de Failder en el futuro. Cuando tenga una licencia de pago, no se le adeudará ningún reembolso (prorrateado o de otro tipo) al momento de la rescisión.
          </p>
        </div>


        <div>
          10. USO DEL NOMBRE Y EL LOGOTIPO Y DERECHOS DE PROPIEDAD INTELECTUAL
        </div>
        <div>
          <p>
            A continuación, y en cumplimiento de la L.S.S.I. (Ley de Servicios de la Sociedad de la Información y Comercio Electrónico), se facilitan los siguientes datos de contacto y registro:
          </p>
          <p>
            La titularidad del sitio web de Failder (https://failder.com), y de la marca registrada "Failder" pertenecen a: Francisco Cubel Martinez, con NIF: 44521794G, y cuya dirección de contacto es:
          </p>
          <p>
            Plaça Clara Campoamor, 10,<br>
            46530 Puçol (València)<br>
            E-mail de contacto: info@failder.com
          </p>
          <p>
            1. Usted acepta que podemos usar su nombre o logotipo en el sitio web de Failder para anunciar que es cliente del Servicio de Failder. Si no desea que su logotipo se use en el sitio web de Failder, debe enviarnos un correo electrónico.
          </p>
          <p>
            2. "Failder" es una marca comercial registrada en el España (M4139809), ("Nuestras Marcas Comerciales") y usted acepta, reconoce y acepta que cualquier uso de cualquier marca comercial, nombre de dominio, nombre comercial o de servicio que contenga Nuestras Marcas Comerciales (o cualquier elemento de las mismas) y/o sea confusamente similar a Nuestras Marcas Comerciales no está autorizado sin nuestro consentimiento previo por escrito.
          </p>
          <p>
            3. Usted acepta y se compromete a que:
          </p>
          <div style="padding-left: 22px;">
            <p>
              (a) no registrará ni intentará registrar ningún nombre de dominio, marca comercial o nombre comercial o de servicio (en cualquier parte del mundo) que incluya Nuestras marcas comerciales (o cualquier elemento de las mismas) y/o es confusamente similar a Nuestras marcas comerciales;
            </p>
            <p>
              (b) cualquier uso que usted haga de Nuestras Marcas Comerciales (incluido cualquier nombre de dominio o marca comercial registrada por usted), redunda en nuestro beneficio exclusivo y que usted no obtiene tal nombre de dominio y/o comercio marca como resultado de dicho uso;
            </p>
            <p>
              (c) usted, a su exclusivo costo y responsabilidad, nos transferirá a pedido cualquier nombre de dominio o solicitud de marca comercial o registro registrado por usted; y
            </p>
            <p>
              (d) no podrá desafiar o atacar nuestro derecho a nuestras marcas comerciales o desafiar nuestra propiedad de ellas de cualquier manera.
            </p>
          </div>
          <p>
            4. No se le permite, salvo de conformidad con este Acuerdo, utilizar cualquiera de nuestros Derechos de propiedad intelectual sin nuestro consentimiento previo por escrito. Nuestros "Derechos de propiedad intelectual" incluyen, entre otros, todos los derechos sobre marcas comerciales, marcas de servicio, nombres comerciales, nombres de dominios, diseños registrados, derechos de autor, software y código informático, derechos de bases de datos, derechos de topografía, existentes y futuros, derechos morales, secretos comerciales, conocimientos técnicos y cualquier otra forma similar de protección en todo el mundo (esté o no registrada), durante todo el período de tiempo durante el cual dichos derechos y pueden protegerse, y cualquier extensión y/o renovación de lo mismo.
          </p>
          <p>
            5. Usted acepta y se compromete a no cuestionar ni atacar nuestro derecho a los Derechos de propiedad intelectual ni cuestionar nuestra propiedad de los mismos de ninguna manera.
          </p>
          <p>
            6. Tendremos una licencia perpetua, irrevocable, mundial y libre de regalías para usar o incorporar en los Servicios de Failder cualquier sugerencia, solicitud de mejora, recomendación u otro comentario proporcionado por Usted, incluidos los Usuarios, en relación con el funcionamiento de los Servicios.
          </p>
        </div>

        <div>
          11. DATOS DEL CLIENTE Y DATOS DE SERVICIO
        </div>

        <div>
          <p>
            1. Los Datos del Cliente serán de su propiedad. "Datos del cliente" se refiere a los datos analíticos que podemos proporcionarle de vez en cuando en relación con los sitios web que desea que se controlen, incluido solo a modo de ejemplo, la disponibilidad en línea de esos sitios web que usted monitorea (individual y colectivamente "Sus sitios web"). Solo puede utilizar los Datos del cliente para los fines internos de su negocio (o según lo permita este Acuerdo) y siempre de acuerdo con todas las leyes aplicables.
          </p>
          <p>
            2. Tendremos derecho a utilizar los Datos del cliente en forma anónima y/o agregada ("Datos agregados") siempre que el destinatario correspondiente de los Datos agregados no pueda identificarlo a usted o a sus sitios web.
          </p>
          <p>
            3. Cualquier dato que no sea Datos del Cliente se considerará "Datos de servicio" y será de nuestra propiedad.
          </p>
        </div>

        <div>
          12. INFORMACIÓN PÚBLICA
        </div>
        <div>
          <p>
            1. Es posible que de vez en cuando ofrezcamos una función de informes públicos ("Informes públicos") mediante la cual usted puede mostrar el tiempo de actividad (u otras estadísticas que puedan estar disponibles a través de Informes públicos) de cualquier sitio web que esté monitoreando.
          </p>
          <p>
            2. No puede agregar ningún sitio web o fuente a la instalación de informes públicos donde el contenido y/o material de dicho sitio web o fuente sea potencialmente difamatorio, malicioso, obsceno, contra cualquier ley aplicable, sexualmente explícito, pornográfico o en nuestro único y absoluto discreción otros inadecuados ("Sitio web inadecuado").
          </p>
          <p>
            3. Cuando descubramos que se ha agregado un sitio web inadecuado al servicio de informes públicos, le solicitaremos por correo electrónico que se asegure de que el sitio web inadecuado se elimine de cualquier servicio de informes públicos en su cuenta de usuario de inmediato ("Solicitud de eliminación").
          </p>
          <p>
            4. Cuando no se cumpla con una Solicitud de eliminación, nos reservamos el derecho de eliminar el sitio web inadecuado de su instalación de informes públicos.
          </p>
          <p>
            5. En el caso de que tengamos varias Solicitudes de eliminación realizadas en su contra, y/o se agreguen Sitios web inadecuados a su Cuenta de cliente después de nuestras advertencias, nos reservamos el derecho de cancelar su Cuenta de cliente y no recibir reembolso, cuando tenga una licencia de pago.
          </p>
        </div>

        <div>
          13. REEMBOLSOS
        </div>
        <div>
          <p>
            1. De acuerdo con las cláusulas anteriores, puede cancelar su Licencia de pago en cualquier momento, sin embargo, no se otorgará ningún reembolso prorrateado por el período de tiempo entre la Fecha de cancelación y la Fecha de terminación. En caso de ser una licencia de pago pendiente de activar, si que se podrá hacer un reembolso de dicha licencia.
          </p>
          <p>
            2. En caso de que experimente algún problema con el Servicio de Failder, debe comunicarse con un miembro de nuestro personal por correo electrónico, en el sitio web de Failder o desde el propio apartado de contacto que encontrará en el menu del administrador.
          </p>
          <p>
            3. Trabajaremos con usted para resolver cualquier problema que pueda tener con su Cuenta de Cliente, pero no se otorgarán reembolsos (excepto según lo requiera la ley) a los Clientes que solo a modo de ejemplo, se hayan registrado para una licencia de pago pero ya no desea utilizar el servicio Failder, o para el llamado "no uso".
          </p>
        </div>

        <div>
          14. CUMPLIMIENTO LEGAL
        </div>
        <div>
          <p>
            1. Solo puede utilizar el Servicio Failder y/o el Software Failder y/o los Datos del Cliente de acuerdo con todas las Leyes Aplicables.
          </p>
          <p>
            2. Usted garantiza que, sin perjuicio de la generalidad de lo anterior:
          </p>
          <div style="padding-left: 22px;">
            <p>
              (a) sus sitios web serán y seguirán siendo legales; y
            </p>
            <p>
              (b) que sus sitios web no promocionan ningún producto, bien o servicio que no sea legal en ninguna jurisdicción en la que estén disponibles.
            </p>
          </div>
          <p>
            3. Failder garantiza que:
          </p>
          <div style="padding-left: 22px;">
            <p>
              (a) tiene derecho a celebrar este Acuerdo y proporcionar el Servicio Failder según lo contemplado en este Acuerdo; y
            </p>
            <p>
              (b) proporcionará los Servicios de Failder con un cuidado y habilidad razonables.
            </p>
          </div>

          <p>
            4. Si se incumple alguna de las garantías de la Cláusula 14.3, debe notificarnos lo antes posible y darnos una cantidad de tiempo razonable para solucionar el problema y (si es necesario) poner a disposición una versión corregida del Servicio Failder o una forma de solucionar el problema que no sea materialmente perjudicial para usted. Tomaremos cualquiera de estas medidas correctivas sin cargo adicional para usted. Y siempre que podamos hacer esto, entonces este será su único y exclusivo recurso en relación con cualquier incumplimiento por nuestra parte en virtud de este Acuerdo, y sujeto solo a la Cláusula 17.1 a continuación, no tendremos ninguna otra obligación o responsabilidad en relación con cualquier incumplimiento.
          </p>
          <p>
            5. Usted acepta que no podemos ser responsables y no garantizamos:
          </p>
          <div style="padding-left: 22px;">
            <p>
              (a) la disponibilidad continua del Servicio Failder y/o el sitio web de Failder;
            </p>
            <p>
              (b) el tiempo de respuesta del Servicio Failder y/o el Sitio web de Failder;
            </p>
            <p>
              (c) la exactitud de los datos del cliente en relación con sus sitios web; y/o
            </p>
            <p>
              (d) el éxito del servicio Failder en la detección de si sus sitios web están activos y funcionando correctamente, ni garantizamos que podremos comunicarnos con usted para informarle de los mismos.
            </p>
          </div>
          <p>
            6. Salvo que se establezca expresamente lo contrario en este Acuerdo, y sujeto únicamente a la Cláusula 17.1, ninguna condición implícita, garantía u otros términos, incluidos los términos implícitos relacionados con la calidad satisfactoria o idoneidad para cualquier propósito, se aplicará a los Sitios web de Failder, Failder Services y/o el Software Failder o cualquier otra cosa suministrada, proporcionada o producida por nosotros en virtud de este Acuerdo.
          </p>
          <p>
            7. Sin perjuicio de la generalidad de las Cláusulas 14.5 y 14.6 anteriores, en particular, no ofrecemos garantías en cuanto al funcionamiento del Servicio Failder, el Sitio web Failder y/o el Software Failder y/o que la provisión de los Datos del Cliente sean ininterrumpidos, libres de contaminantes y/o sin errores y/o que cumplan con sus requisitos.
          </p>
        </div>


        <div>
          15. INFORMACIÓN CONFIDENCIAL
        </div>
        <div>
          <p>
            1. Cualquiera de las Partes podrá divulgar la información confidencial de la otra en la medida requerida por la ley o por cualquier corte, tribunal, regulador u otra autoridad con jurisdicción competente para ordenar su divulgación (pero solo en la medida de dicho requisito).
          </p>
        </div>

        <div>
          16. INDEMNIZACIONES
        </div>
        <div>
          <p>
            1. Le indemnizaremos por toda pérdida o daño en el que incurra o sufra como resultado de cualquier reclamo de un tercero de que su acceso y uso del Software Failder y/o el Servicio Failder infringe los derechos de propiedad intelectual de cualquier tercero. Esta indemnización no se aplicará con respecto a ningún reclamo en la medida en que dicho reclamo surja como resultado de:
          </p>
          <div style="padding-left: 22px;">
            <p>
              (a) el uso o combinación del Software Failder y/o el Servicio Failder con material o servicios no proporcionados por nosotros o de los cuales no estaban al tanto;
            </p>
            <p>
              (b) modificaciones al Software Failder y/o al Servicio Failder realizadas por cualquier persona que no sea nosotros;
            </p>
            <p>
              (c) cuando hayamos modificado el Servicio Failder y/o el Software Failder con cualquier diseño, función o funcionalidad, especificación o instrucciones o de otro modo según lo solicite;
            </p>
          </div>
          <p>
            2. En el caso de que se realice una reclamación en virtud de la Cláusula 16.1 anterior, nosotros, a nuestra sola opción, y a nuestro propio costo y gasto, haremos todos los esfuerzos razonables, tan pronto como sea razonablemente posible para:
          </p>
          <div style="padding-left: 22px;">
            <p>
              (a) procurarle el derecho a continuar accediendo y utilizando el Software Failder y/o el Servicio Failder (según sea el caso), de acuerdo con este Acuerdo, sin infracción; o
            </p>
            <p>
              (b) reemplazar o modificar el Software Failder y/o el Servicio Failder (según sea el caso) con software y/o servicios de especificación sustancialmente equivalente para evitar la infracción; y siempre que lo hagamos, no tendremos más responsabilidad ante usted con respecto a la reclamación por infracción.
            </p>
          </div>
          <p>
            3. Usted nos indemnizará por todas y cada una de las pérdidas o daños en los que incurramos o suframos como resultado de:
          </p>
          <div style="padding-left: 22px;">
            <p>
              (a) cualquier incumplimiento por su parte, o de cualquiera de sus empleados, agentes o contratistas, de sus garantías, compromisos y/u obligaciones bajo este acuerdo;
            </p>
            <p>
              (b) cualquier reclamo de un tercero como resultado de su uso del Servicio Failder relacionado con cualquiera de sus sitios web que infrinja las leyes aplicables; y/o
            </p>
            <p>
              (c) cualquier infracción por su parte, o cualquiera de sus empleados, agentes o contratistas, de cualquiera de nuestros derechos de propiedad intelectual, incluidos, entre otros, en el sitio web de Failder, el Servicio Failder y/o el Software Failder.
            </p>
          </div>
          <p>
            4. Siempre que una Parte (la "Parte indemnizadora") otorgue una indemnización en este Acuerdo a la otra (la "Parte indemnizada"), Dicha indemnización estará condicionada a que:
          </p>
          <div style="padding-left: 22px;">
            <p>
              (a) la Parte indemnizada notifique sin demora a la Parte indemnizadora por escrito de cualquier reclamo que esté sujeto a la indemnización;
            </p>
            <p>
              (b) la Parte Indemnizada asegurándose de que no se haga ninguna admisión de responsabilidad o cualquier arreglo o compromiso de tal reclamo sin el consentimiento previo por escrito de la Parte Indemnizadora;
            </p>
            <p>
              (c) la Parte indemnizadora tiene derecho, previa solicitud y a su costo y gasto, a asumir la conducción exclusiva de dicha reclamación (que incluirá el derecho a llevar a cabo cualquier procedimiento o acción en relación con, negociar el acuerdo y llevar a cabo todos discusiones y esfuerzos de resolución de disputas en relación con dicha reclamación, siempre que no se celebre ningún acuerdo de una reclamación que perjudique los derechos de la Parte Indemnizada sin el consentimiento por escrito de la Parte Indemnizada, que no se denegará o retrasará injustificadamente);
            </p>
            <p>
              (d) la Parte Indemnizada presta a la Parte Indemnizadora toda la asistencia razonable en la defensa de dicha reclamación y/o la negociación de cualquier acuerdo, a costa de la Parte Indemnizadora; y
            </p>
            <p>
              (e) la Parte Indemnizada haciendo esfuerzos comercialmente razonables para mitigar cualquier pérdida o daño.
            </p>
          </div>

        </div>

        <div>
          17. RESPONSABILIDAD DE LAS PARTES
        </div>
        <div>
          <p>
            1. Responsabilidad:
          </p>
          <div style="padding-left: 22px;">
            <p>
              (a) por muerte o lesiones personales causadas por negligencia;
            </p>
            <p>
              (b) por tergiversación fraudulenta o por cualquier otro acto u omisión fraudulentos; o
            </p>
            <p>
              (c) por cualquier otra responsabilidad que no pueda ser legalmente excluida o limitada; no está excluido ni limitado por este Acuerdo, incluso si cualquier otro término de este Acuerdo sugiere que este podría ser el caso.
            </p>
          </div>

          <p>
            2. Sujeto a la Cláusula 17.1 anterior, no seremos responsables (ya sea por incumplimiento de contrato, agravio (incluida negligencia), incumplimiento de deber legal o de otro tipo) por cualquier:
          </p>
          <div style="padding-left: 22px;">
            <p>
              (a) pérdida de ganancias;
            </p>
            <p>
              (b) pérdida de ventas, volumen de negocios, ingresos o negocio;
            </p>
            <p>
              (c) pérdida de clientes, contratos u oportunidades;
            </p>
            <p>
              (d) pérdida o daño a la reputación o la buena voluntad;
            </p>
            <p>
              (e) pérdida de ahorros previstos;
            </p>
            <p>
              (f) pérdida de cualquier software o datos;
            </p>
            <p>
              (g) pérdida de uso de hardware, software o datos;
            </p>
            <p>
              (h) pérdida o desperdicio de tiempo de gestión o de otro personal; o
            </p>
            <p>
              (i) pérdida indirecta, consecuente o especial; que surjan de o se relacionen con este Acuerdo.
            </p>
          </div>
          <p>
            3. Sujeto a las Cláusulas 17.1 y 17.2 anteriores, nuestra responsabilidad total que surja de o se relacione con este Acuerdo o su objeto y con cualquier cosa que haya hecho o no en relación con el mismo (ya sea por incumplimiento de contrato, agravio (incluyendo negligencia), incumplimiento de la obligación legal o de otro tipo) se limitará, en conjunto para todas las reclamaciones que surjan durante la vigencia de este Acuerdo, hasta una responsabilidad máxima, la cantidad pagada por usted en virtud de cualquier Plan de pago en los doce (12) meses anteriores.
          </p>
        </div>

        <div>
          18. VIGENCIA DEL ACUERDO
        </div>
        <div>
          <p>
            1. Este Acuerdo comenzará en la Fecha de entrada en vigor y, a menos que se rescinda antes de acuerdo con sus términos, continuará en vigor hasta que cualquiera de las Partes lo rescinda de conformidad con la Cláusula 19 a continuación.
          </p>
        </div>

        <div>
          19. TERMINACIÓN DEL ACUERDO
        </div>
        <div>
          <p>
            1. Cualquiera de las Partes podrá rescindir este Acuerdo notificando por escrito a la otra parte si la otra:
          </p>
          <div style="padding-left: 22px;">
            <p>
              (a) incumple sustancialmente cualquier término de este Acuerdo y no es posible remediar ese incumplimiento;
            </p>
            <p>
              (b) incumple sustancialmente cualquier término de este Acuerdo y es posible remediar ese incumplimiento, pero el otro no lo hace dentro de los treinta (30) días posteriores a la solicitud por escrito de hacerlo;
            </p>
            <p>
              (c) se declare insolvente, haga convenio con sus acreedores, tenga un síndico o administrador de su empresa o la totalidad o una parte sustancial de sus activos designado, o se dicte una orden, o se apruebe una resolución efectiva, para su administración, administración judicial, liquidación, liquidación u otro proceso similar, o tiene cualquier angustia, ejecución u otro proceso impuesto o aplicado contra la totalidad o una parte sustancial de sus activos.
            </p>
          </div>
          <p>
            2. A los efectos de esta Cláusula 19.2, para que sea posible remediar una infracción, debe ser posible tomar las medidas necesarias para poner a la otra Parte en la misma situación en la que (salvo la fecha) habría estado en si la infracción nunca se había producido. Las partes reconocen y acuerdan que cualquier incumplimiento por parte del Cliente de cualquiera de las disposiciones de las Cláusulas 9, 10 y 14 será un incumplimiento material que no podrá subsanarse.
          </p>
          <p>
            3. Tenemos derecho a rescindir este Acuerdo sin causa previa notificándole con veinticuatro (24) horas de anticipación; dicho aviso se enviará a la dirección de correo electrónico que nos proporcionó cuando se registró en el Servicio Failder. Cuando rescindamos sin causa, usted tendrá derecho a un reembolso prorrateado, cuando corresponda, desde la fecha de terminación hasta la fecha de fin de su lincencia ("Reembolso Prorrateado").
          </p>
          <p>
            4. Sin perjuicio de las otras disposiciones de esta Cláusula 19, podemos, además, rescindir este Acuerdo, o alternativamente, podemos rescindir o suspender el acceso y uso del Servicio Failder, notificándole por escrito si está en persistencia o incumplimiento reiterado de cualquiera de sus obligaciones en virtud de este Acuerdo (ya sea que se incumpla o no la misma obligación y se repare o no dichos incumplimientos) ("Cuenta de cliente suspendida"). Para evitar dudas, no tendrá derecho a ningún reembolso por ningún período de tiempo mientras tenga una Cuenta de cliente suspendida.
          </p>
          <p>
            5. Tras la rescisión en virtud de este Acuerdo por cualquier otro motivo:
          </p>
          <div style="padding-left: 22px;">
            <p>
              (a) dejará de acceder y descontinuará todo uso del sitio web de Failder, los Servicios de Failder y los Datos del cliente, junto con todos los demás Servicios de Failder que usted se han suscrito a;
            </p>
            <p>
              (b) cualquier ahorro con respecto a cualquier Reembolso prorrateado, cualquier suma de dinero que ya nos haya pagado se perderá.
            </p>
          </div>

          <p>
            6. La terminación de este Acuerdo por cualquier motivo no afectará:
          </p>
          <div style="padding-left: 22px;">
            <p>
              (a) los derechos o responsabilidades acumulados que cualquiera de las Partes pueda tener en el momento en que la terminación entre en vigor; o
            </p>
            <p>
              (b) la entrada en vigor o la continuación en vigor de cualquiera de sus disposiciones que, expresa o implícitamente, tengan la intención de entrar en vigor o continuar en vigor en o después de la terminación.
            </p>
          </div>
          <p>
            7. Sin perjuicio de lo anterior, las Cláusulas 3, 4, 11.3, 14-17 (inclusive) y 21 sobrevivirán a la terminación de este Acuerdo.
          </p>
        </div>

        <div>
          20. FUERZA MAYOR
        </div>
        <div>
          <p>
            1. Ninguna de las Partes será responsable ante la otra por cualquier incumplimiento de este Acuerdo (que no sea la falta de pago de los cargos) que surja debido a cualquier circunstancia que no se pueda esperar razonablemente que la parte incumplidora controle (que incluirá la interrupción o falla del Internet o de cualquier red, telecomunicaciones, suministro de energía o infraestructura, o cualquier proveedor de cualquiera de los anteriores pero, para evitar dudas, no se incluirá la escasez o falta de fondos disponibles), siempre que la parte incumplidora:
          </p>
          <div style="padding-left: 22px;">
            <p>
              (a) notifique el otro por escrito tan pronto como sea razonablemente posible sobre la naturaleza y el alcance de las circunstancias y el posible incumplimiento;
            </p>
            <p>
              (b) realiza esfuerzos razonables para mitigar los efectos de las circunstancias y el incumplimiento a fin de minimizar o evitar el incumplimiento;
            </p>
            <p>
              (c) realiza esfuerzos razonables para reanudar el desempeño tan pronto como sea razonablemente posible; y
            </p>
            <p>
              (d) no podría haber evitado la violación tomando las medidas que razonablemente debería haber tomado a la luz de los asuntos que conocía antes de que surgieran las circunstancias.
            </p>
          </div>
        </div>

        <div>
          21. DISPOSICIONES GENERALES
        </div>

        <div>
          <p>
            1. No se le permite sublicenciar o asignar, subcontratar o delegar alguno o todos sus derechos u obligaciones bajo este Acuerdo sin nuestro consentimiento previo por escrito.
          </p>
          <p>
            2. Todos los avisos y consentimientos relacionados con este Acuerdo (pero excluyendo cualquier procedimiento u otros documentos en cualquier acción legal) deben hacerse por escrito y pueden enviarse por correo electrónico.
          </p>
          <p>
            3. A menos que las partes acuerden expresamente lo contrario por escrito, si una de las partes:
          </p>
          <div style="padding-left: 22px;">
            <p>
              (a) no ejerce o retrasa el ejercicio o solo ejerce parcialmente cualquier derecho o recurso previsto en este Acuerdo o por ley; o
            </p>
            <p>
              (b) acuerda no ejercer o retrasar el ejercicio de cualquier derecho o recurso provisto bajo este Acuerdo o por ley; entonces no se considerará que esa parte ha renunciado y no se le impedirá o restringir el ejercicio adicional de ese o cualquier otro derecho o recurso.
            </p>
          </div>
          <p>
            4. Si alguna disposición de este Acuerdo se considera ineficaz o inaplicable por cualquier motivo, esto no afectará la validez o aplicabilidad de cualquier otra disposición de este Acuerdo o de este Acuerdo en su totalidad. Si se determina que alguna disposición de este Acuerdo es ineficaz o inaplicable, pero sería efectiva o ejecutable si se eliminara alguna parte de la disposición, la disposición en cuestión se aplicará con las modificaciones que sean necesarias para que sea efectiva y ejecutable.
          </p>
          <p>
            5. Todas las variaciones de este Acuerdo deben acordarse, establecerse por escrito y firmarse en nombre de ambas partes antes de que entren en vigor.
          </p>
          <p>
            6. Excepto en la medida en que este Acuerdo disponga expresamente lo contrario, nada en este Acuerdo deberá o tiene la intención de crear una asociación o empresa conjunta entre las Partes, constituir una parte como agente de la otra o otorgar autoridad a cualquiera de las partes para realizar o celebrar compromisos, asumir responsabilidades o pignorar créditos en nombre de la otra parte. Ninguna de las Partes puede actuar como si fuera, o representar (de manera expresa o implícita) que es un agente de la otra o que tiene tal autoridad.
          </p>
          <p>
            7. Una persona que no sea parte de este Acuerdo no tendrá ningún derecho en virtud o en conexión con él, ya sea en virtud de la Ley de Contratos (Derechos de Terceros) de 1999 o de otro modo. No se requerirá el consentimiento de ningún tercero para rescindir o modificar este Acuerdo.
          </p>
          <p>
            8. Este Acuerdo establece todos los términos que han sido acordados entre las partes en relación con los temas cubiertos por él, y reemplaza todos los acuerdos anteriores entre las Partes relacionados con dichos temas. Siempre que nada en esta Cláusula 21.8 operará para limitar o excluir cualquier responsabilidad por fraude o tergiversación fraudulenta, ninguna otra representación o término se aplicará o formará parte de este Acuerdo y cada Parte reconoce que no ha sido influenciada para celebrar este Acuerdo por, y no tendrá derechos o recursos (excepto por incumplimiento de contrato) con respecto a cualquier cosa que la otra Parte haya dicho o hecho o se haya comprometido a hacer, excepto lo expresamente registrado en este Acuerdo.
          </p>
          <p>
            9. Este Acuerdo se rige por la ley española. Ambas Partes se someten a la jurisdicción exclusiva de los tribunales españoles en relación con cualquier disputa que surja de o en conexión con este Acuerdo o su objeto, pero también tenemos derecho a solicitar a cualquier tribunal del mundo medidas cautelares u otros recursos para proteger o hacer valer sus derechos de propiedad intelectual.
          </p>
        </div>


        <div>
          Términos y condiciones - Última actualización el 14 de octubre de 2021
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import sha1 from 'js-sha1';
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import BtnPrimario from '../components/BtnPrimario'
import BtnAlternativoTransparente from '../components/BtnAlternativoTransparente'

export default {
  name: 'TerminosCondiciones',
  components: {
    BtnPrimario,
    BtnAlternativoTransparente
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar
    datos.imagen_fondo = '550113.jpg';
    datos.mail = '';
    datos.visiblepass = false;
    datos.visibleloading = false;
    datos.visiblemail = true;
    datos.pass = '';
    datos.salt_recibido = '';
    datos.check_recordar_pass = false;
    datos.view_div_error = false;
    datos.div_titulo_mensaje_error = '';
    datos.div_info_mensaje_error = '';

    onMounted(function (){
      //console.log('Variable global',datos);
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    cerrar(){

    },
    cargar_fondo_de_pantalla(){
      let array_fondos = ['550113.jpg', '555680.jpg', '555683.jpg','555684.jpg','555705.jpg','817972.jpg','828878.jpg','884555.jpeg','1047595.jpg','1074989.jpg','1080706.jpg'];
      //document.getElementById('fondo').style.backgroundImage=`url(../assets/${array_fondos[2]})`; // specify the image path here
      this.datos.imagen_fondo = array_fondos[2];
    },
    siguiente(){
      if (this.datos.visiblemail){
        this.check_mail();
      } else if (this.datos.visiblepass){
        this.check_pass();
      }
    },
    escribir_mensaje_error(titulo, mensaje){
      if (titulo == '' && mensaje == ''){
        this.datos.view_div_error = false;
      } else {
        this.datos.view_div_error = true;
      }
      this.datos.div_titulo_mensaje_error = titulo;
      this.datos.div_info_mensaje_error = mensaje;
    },
    validateMail: function(e){
      if (e.keyCode === 13) {
        this.check_mail();
      }
    },
    validatePass(e){
      if (e.keyCode === 13) {
        this.check_pass();
      }
    },
    async check_mail() {
      this.datos.visiblemail = false;
      this.datos.visibleloading = true;
      this.escribir_mensaje_error('','');
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      };
      await this.sleep(500);
      const response = await fetch(`${this.datos.url_host}/account/salt/${this.datos.mail.trim()}`, requestOptions);
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        this.escribir_mensaje_error(`Error código ${datos.code}`, datos.message);
        this.datos.visiblemail = true;
        this.datos.visibleloading = false;
      } else {
        this.datos.visiblemail = false;
        this.datos.visibleloading = false;
        this.datos.visiblepass = true;
        this.datos.salt_recibido = datos.data.salt;
        /*this.view_fragment_1 = !this.view_fragment_1;
        this.view_fragment_2 = !this.view_fragment_2;*/
        this.$nextTick(() => this.$refs.txt_pass.focus());
      }
    },
    async check_pass() {
      this.datos.visiblepass = false;
      this.datos.visibleloading = true;
      this.escribir_mensaje_error('', '');
      let pass_completo = sha1(this.datos.pass + this.datos.salt_recibido) + ':' + this.datos.salt_recibido;
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      };
      await this.sleep(500);
      let sesion = 0;
      if (this.datos.check_recordar_pass) sesion = 1
      const response = await fetch(`${this.datos.url_host}/account/token/${this.datos.mail.trim()}/${pass_completo}/${sesion}`, requestOptions);
      const datos = await response.json();

      if (datos.code != '00'){
        this.escribir_mensaje_error(`Error código ${datos.code}`, datos.message);
        console.log(response);
        console.log(datos);
        this.datos.visiblepass = false;
        this.datos.visibleloading = false;
        this.datos.visiblemail = true;
        this.datos.pass = '';
      } else {

        localStorage.token = datos.data.token;
        //window.location.href = "/";
        let thisStore = this.$store;
        thisStore.dispatch('goTo', `/`);
      }
    },
    check_event: function(e) {
      this.datos.check_recordar_pass = !this.datos.check_recordar_pass;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    comprobar_token(){
      let thisStore = this.$store;
      if (localStorage.token != undefined) {
        thisStore.dispatch('goTo', `/`);
        return false;
      }
    }
  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    //this.cargar_fondo_de_pantalla();
    //this.comprobar_token();
  },
}
</script>
<style lang="scss" scoped>
@import '../styles/variables.css';
.modificar{
  color: red;
}
.logo_descripcion img{
  width: 210px;
}
.barra_superior{
  display: none;
}
.copy{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 20px;
  padding-right: 16px;
  padding-left: 16px;
}
.copy .copy1{
  color: #FFFFFF;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
.copy .copy2{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  max-width: 430px;
  padding-right: 10px;
}
.copy .copy3{
  display: none;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 30px;

}
.copy4{
  display: none;
}
.div_info{
  text-align: left;
  width: 80%;
  max-width: 480px;
}
.div_info .titulo{
  font-size: 38px;
  font-weight: bold;
  color: #FFFFFF;
}
.div_info .info{
  /*color: var(--color-texto-principal-claro);*/
  font-weight: bold;
  color: #FFFFFF;
}
.div_login{
  margin-top: 66px;
  margin-bottom: 66px;
}
.div_botonera{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-between;
  align-items: center;
  color: var(--color-texto-principal-claro);
}
.div_login .titulo{
  font-size: 38px;
  font-weight: bold;
}
.div_login .info{
  color: var(--color-texto-principal-claro);
  font-weight: bold;
}
.contenedor{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.bloque1{
  width: 40%;
  background-color: #00000060;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.bloque2{
  width: 60%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  color: var(--color-texto-principal);
  text-align: left;
  padding: 16px;
  padding-top: 20px;
  padding-bottom: 40px;
}
.div_login{
  width: 80%;
  max-width: 400px;
}
.fondo{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  /* The image used */
  background-image: url("../assets/backgrounds/884555.jpeg");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.movil{
  display: none;
}
.escritorio{
  display: flex;
}
.div_error{
  background-color: var(--primary-color-5);
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  color: #FFF;
}
.div_title_error{
  font-weight: bold;
}
@media screen and (max-width: 991px){
  .movil{
    display: flex;
  }
  .escritorio{
    display: none;
  }
  .bloque2{
    width: 100%;
    padding-top: 80px;
  }
  .barra_superior{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: var(--fondo-menu-claro-theme);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
    position: fixed;
    width: 100%;
  }
  .barra_superior img{
    height: 46px;
  }
  .copy .copy1{
    color: inherit;
  }

  .copy .copy1{
    display: none;
  }
  .copy .copy3{
    display: none;
  }
  .copy{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 16px;
    padding-left: 16px;
    height: 66px;
  }
  .copy4{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 16px;
    padding-left: 16px;
    cursor: pointer;
  }
}
@media screen and (max-width: 480px){
  .copy .copy1{
    display: none;
  }
  .copy .copy3{
    display: none;
  }
  .copy{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 16px;
    padding-left: 16px;
    height: 66px;

  }
}
@media screen and (max-width: 660px){
  .barra_superior img{
    height: 30px;
  }
}
</style>
