<template>
  <div class="barra_superior">
    <div>
      <a href="https://failder.com">
        <div><img alt="Failder" src="../assets/LOGO_Blue-Black.png"> </div>
      </a>
    </div>
    <div>
      <a href="/">
        <div>Cerrar</div>
      </a>
    </div>
  </div>
  <div>
    <div class="fondo">
    </div>
    <div class="contenedor">
      <div class="escritorio bloque1">
        <div class="div_info">
          <div class="logo_descripcion">
            <a href="https://failder.com">
              <div><img alt="Failder" src="../assets/LOGO_White.png"> </div>
            </a>
          </div>
          <!--<div class="titulo">
            Failder
          </div>-->
          <div class="info">
            Política de privacidad
          </div>
          <div style="position: absolute; top: 20px; left: 20px;">
            <a href="/">
              <div>Cerrar</div>
            </a>
          </div>
        </div>
      </div>
      <div class="bloque2 form">

        <!--<div>
          https://www.statuscake.com/terms-and-conditions/
        </div>-->
        <!--<div>
          Failder.com (en lo sucesivo, "Failder", "nosotros", "nuestro") Términos y condiciones (los "Términos") nuestra [Declaración de cumplimiento de GDPR, Política de privacidad y cookies], y [El Acuerdo de procesamiento de datos de Failder], junto con el Formulario de [registro] (el "Formulario de registro") que completó cuando se registró para usar los productos y/o servicios de Failder (colectivamente el "Acuerdo"), contienen los términos, condiciones y entendimiento de la relación entre usted y Failder (cada uno es una "parte" juntas las "partes"), y su uso del sitio web, el producto y los Servicios de Failder (los "Servicios de Failder").
        </div>-->
        <!--<div class="modificar">-->
        <div>


        </div>

        <div>
          <p>
            Mediante la presente política de privacidad le informamos de que, en cumplimiento de lo previsto en el Reglamento Europeo de Protección de Datos 2016/679/UE, los datos solicitados en cuantos formularios y/o espacios en los que se requiera de su identificación dentro de la presente página web serán tratados por Francisco Cubel Martinez, con domicilio en Plaça Clara Campoamor, 10, Puçol (València), y NIF 44521794G, en adelante Failder.
          </p>
        </div>
        <div>
          Legitimación y finalidad del tratamiento
        </div>
        <div>
          <p>
            La finalidad del tratamiento consiste en:
          </p>
          <div style="padding-left: 22px;">
            <p>
              1. Atender las consultas y solicitudes de información por el usuario.
            </p>
            <p>
              2. Prestarle los servicios solicitados.
            </p>
            <p>
              3. Facilitarle información acerca de nuestra entidad, servicios, ofertas, promociones y noticias de interés relacionadas con los servicios prestados.
            </p>
            <p>
              4. Remisión de novedades y actuaciones de dinamismo en el portal remitiendo, únicamente por correo electrónico, la información relacionada.
            </p>
          </div>

          <p>
            La base jurídica del tratamiento que constituye la legitimación para el mismo se corresponde con:
          </p>
          <div style="padding-left: 22px;">
            <p>
              1. La contratación de los servicios ofertados.
            </p>
            <p>
              2. La solicitud del entorno de pruebas, mediante formulario de registro.
            </p>
            <p>
              3. Consentimiento expreso, cuando así se solicite, para finalidades de carácter comercial y/o promocional.
            </p>
          </div>

          <p>
            En cualquier caso, si el usuario quisiera revocar dicho consentimiento deberá comunicarlo por escrito, mediante correo electrónico, a <a href="mailto:info@failder.com">info@failder.com</a>.
          </p>
          <p>
            Los datos obligatorios de cada formulario serán identificados como tales. En caso de ausencia o incorrección de los mismos failder no podrá identificarle correctamente y, en consecuencia, se procederá a la supresión del registro en su caso.
          </p>
        </div>

        <div>
          Ejercicio de derechos
        </div>

        <div>
          <p>
            El usuario consiente el tratamiento de sus datos para las finalidades descritas, y podrá ejercer sus derechos de acceso, rectificación, cancelación, oposición, así como el resto de derechos recogidos en la normativa Europea, mediante correo electrónico a la dirección <a href="mailto:info@failder.com">info@failder.com</a> identificándose como usuario del espacio Web, especificando el ejercicio de sus derechos en su solicitud, o bien ejercitar sus derechos por comunicación postal, por correo ordinario a la dirección (domicilio social) anteriormente indicada.
          </p>
        </div>

        <div>
          Tutela de derechos ante la Autoridad de Control
        </div>
        <div>
          <p>
            En cualquier supuesto de vulneración del derecho a la protección de datos personales, el usuario puede dirigirse a la Autoridad de Control nacional correspondiente, la Agencia Española de Protección de Datos, www.agpd.es, para iniciar los trámites oportunos de reclamación y/o defensa de sus derechos.
          </p>
        </div>




        <div>
          Conservación de los datos
        </div>
        <div>
          <p>
            Sus datos se conservarán por el tiempo necesario para atender a la finalidad para la cual hubieran sido recogidos y a las obligaciones legales derivadas de la misma y, en cualquier caso, terminada la relación negocial, por un máximo de 5 años.
          </p>
        </div>

        <div>
          Responsabilidades del usuario
        </div>
        <div>
          <p>
            El usuario garantiza ser mayor de edad, disponiendo de la capacidad suficiente y el conocimiento necesario para utilizar el espacio web de Failder y su contenido, garantizando que la información que facilita en cada uno de los formularios en los que Failder solicita sus datos personales son veraces, adquiriendo la responsabilidad de informar a Failder de cualquier cambio y/o modificación de los mismos para su correcto tratamiento.
          </p>
        </div>


        <div>
          Medidas de Seguridad
        </div>
        <div>
          <p>
            Failder ha implantado las medidas de seguridad necesarias para garantizar un uso y tratamiento eficaz de los datos de carácter personal facilitados por el usuario, salvaguardando la intimidad, privacidad, confidencialidad e integridad de los mismos, cumpliendo con los requisitos normativos previstos, haciendo uso de los medios técnicos necesarios para evitar la alteración, pérdida, acceso no autorizado o tratamiento de sus datos, según el estado de la tecnología en cada momento, así como el alcance de control de Failder.
          </p>
          <p>
            Failder podrá modificar el contenido de su política de privacidad en cualquier momento según las modificaciones legislativas, jurisprudenciales o de interpretación de la Agencia Española de Protección de Datos que puedan producirse.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sha1 from 'js-sha1';
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import BtnPrimario from '../components/BtnPrimario'
import BtnAlternativoTransparente from '../components/BtnAlternativoTransparente'

export default {
  name: 'Privacidad',
  components: {
    BtnPrimario,
    BtnAlternativoTransparente
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar
    datos.imagen_fondo = '550113.jpg';
    datos.mail = '';
    datos.visiblepass = false;
    datos.visibleloading = false;
    datos.visiblemail = true;
    datos.pass = '';
    datos.salt_recibido = '';
    datos.check_recordar_pass = false;
    datos.view_div_error = false;
    datos.div_titulo_mensaje_error = '';
    datos.div_info_mensaje_error = '';

    onMounted(function (){
      //console.log('Variable global',datos);
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    cargar_fondo_de_pantalla(){
      let array_fondos = ['550113.jpg', '555680.jpg', '555683.jpg','555684.jpg','555705.jpg','817972.jpg','828878.jpg','884555.jpeg','1047595.jpg','1074989.jpg','1080706.jpg'];
      //document.getElementById('fondo').style.backgroundImage=`url(../assets/${array_fondos[2]})`; // specify the image path here
      this.datos.imagen_fondo = array_fondos[2];
    },
    siguiente(){
      if (this.datos.visiblemail){
        this.check_mail();
      } else if (this.datos.visiblepass){
        this.check_pass();
      }
    },
    escribir_mensaje_error(titulo, mensaje){
      if (titulo == '' && mensaje == ''){
        this.datos.view_div_error = false;
      } else {
        this.datos.view_div_error = true;
      }
      this.datos.div_titulo_mensaje_error = titulo;
      this.datos.div_info_mensaje_error = mensaje;
    },
    validateMail: function(e){
      if (e.keyCode === 13) {
        this.check_mail();
      }
    },
    validatePass(e){
      if (e.keyCode === 13) {
        this.check_pass();
      }
    },
    async check_mail() {
      this.datos.visiblemail = false;
      this.datos.visibleloading = true;
      this.escribir_mensaje_error('','');
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      };
      await this.sleep(500);
      const response = await fetch(`${this.datos.url_host}/account/salt/${this.datos.mail.trim()}`, requestOptions);
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        this.escribir_mensaje_error(`Error código ${datos.code}`, datos.message);
        this.datos.visiblemail = true;
        this.datos.visibleloading = false;
      } else {
        this.datos.visiblemail = false;
        this.datos.visibleloading = false;
        this.datos.visiblepass = true;
        this.datos.salt_recibido = datos.data.salt;
        /*this.view_fragment_1 = !this.view_fragment_1;
        this.view_fragment_2 = !this.view_fragment_2;*/
        this.$nextTick(() => this.$refs.txt_pass.focus());
      }
    },
    async check_pass() {
      this.datos.visiblepass = false;
      this.datos.visibleloading = true;
      this.escribir_mensaje_error('', '');
      let pass_completo = sha1(this.datos.pass + this.datos.salt_recibido) + ':' + this.datos.salt_recibido;
      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json"},
      };
      await this.sleep(500);
      let sesion = 0;
      if (this.datos.check_recordar_pass) sesion = 1
      const response = await fetch(`${this.datos.url_host}/account/token/${this.datos.mail.trim()}/${pass_completo}/${sesion}`, requestOptions);
      const datos = await response.json();

      if (datos.code != '00'){
        this.escribir_mensaje_error(`Error código ${datos.code}`, datos.message);
        console.log(response);
        console.log(datos);
        this.datos.visiblepass = false;
        this.datos.visibleloading = false;
        this.datos.visiblemail = true;
        this.datos.pass = '';
      } else {

        localStorage.token = datos.data.token;
        //window.location.href = "/";
        let thisStore = this.$store;
        thisStore.dispatch('goTo', `/`);
      }
    },
    check_event: function(e) {
      this.datos.check_recordar_pass = !this.datos.check_recordar_pass;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    comprobar_token(){
      let thisStore = this.$store;
      if (localStorage.token != undefined) {
        thisStore.dispatch('goTo', `/`);
        return false;
      }
    }
  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    //this.cargar_fondo_de_pantalla();
    //this.comprobar_token();
  },
}
</script>
<style lang="scss" scoped>
@import '../styles/variables.css';
.modificar{
  color: red;
}
.logo_descripcion img{
  width: 210px;
}
.barra_superior{
  display: none;
}
.copy{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 20px;
  padding-right: 16px;
  padding-left: 16px;
}
.copy .copy1{
  color: #FFFFFF;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
.copy .copy2{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  max-width: 430px;
  padding-right: 10px;
}
.copy .copy3{
  display: none;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 30px;

}
.copy4{
  display: none;
}
.div_info{
  text-align: left;
  width: 80%;
  max-width: 480px;
}
.div_info .titulo{
  font-size: 38px;
  font-weight: bold;
  color: #FFFFFF;
}
.div_info .info{
  /*color: var(--color-texto-principal-claro);*/
  font-weight: bold;
  color: #FFFFFF;
}
.div_botonera{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-between;
  align-items: center;
  color: var(--color-texto-principal-claro);
}
.div_login{
  margin-top: 66px;
  margin-bottom: 66px;
}
.div_login .titulo{
  font-size: 38px;
  font-weight: bold;
}
.div_login .info{
  color: var(--color-texto-principal-claro);
  font-weight: bold;
}
.contenedor{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.bloque1{
  width: 40%;
  background-color: #00000060;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
.bloque2{
  width: 60%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;
  color: var(--color-texto-principal);
  text-align: left;
  padding: 16px;
  padding-top: 20px;
  padding-bottom: 40px;
}
.div_login{
  width: 80%;
  max-width: 400px;
}
.fondo{
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  /* The image used */
  background-image: url("../assets/backgrounds/555683.jpg");

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.movil{
  display: none;
}
.escritorio{
  display: flex;
}
.div_error{
  background-color: var(--primary-color-5);
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  color: #FFF;
}
.div_title_error{
  font-weight: bold;
}
@media screen and (max-width: 991px){
  .movil{
    display: flex;
  }
  .escritorio{
    display: none;
  }
  .bloque2{
    width: 100%;
    padding-top: 80px;
  }
  .barra_superior{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    background-color: var(--fondo-menu-claro-theme);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    -webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.75);
    position: fixed;
    width: 100%;
  }
  .barra_superior img{
    height: 46px;
  }
  .copy .copy1{
    color: inherit;
  }

  .copy .copy1{
    display: none;
  }
  .copy .copy3{
    display: none;
  }
  .copy{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 16px;
    padding-left: 16px;
    height: 66px;
  }
  .copy4{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 16px;
    padding-left: 16px;
    cursor: pointer;
  }
}
@media screen and (max-width: 480px){
  .copy .copy1{
    display: none;
  }
  .copy .copy3{
    display: none;
  }
  .copy{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-right: 16px;
    padding-left: 16px;
    height: 66px;

  }
}
@media screen and (max-width: 660px){
  .barra_superior img{
    height: 30px;
  }
}
</style>
