import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login'
import Contacto1 from '../views/Contacto1'
import Legal from '../views/Legal.vue'
import Privacidad from '../views/Privacidad'
import Terminos from '../views/TerminosCondiciones'
import Registro from '../views/Registro'
import Dashboard from '../views/Dashboard'
import Proyectos from '../views/Proyectos'
import Contactos from '../views/Contactos'
import Configuracion from '../views/Configuracion'
import ConfiguracionPlan from "../views/ConfiguracionPlan";
import Error404 from '../views/Error404'
import ProyectoConfiguracion from '../views/ProyectoConfiguracion'
import ProyectoEstado from '../views/ProyectoEstado'
import ProyectoView from '../views/ProyectoView'
import Test from '../views/Test'
import CreatePlan from '../views/CreatePlan'
import EditarFacturacion from "@/views/EditarFacturacion";
import Pagos from "../views/Pagos"
import SelectAccount from "../views/SelectAccount"
import CP from "../views/CambioPass"

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: '/login',
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: Contacto1
  },
  {
    path: '/legal',
    name: 'Legal',
    component: Legal
  },
  {
    path: '/terminos-y-condiciones',
    name: 'Terminos',
    component: Terminos
  },
  {
    path: '/politica-de-privacidad',
    name: 'Privacidad',
    component: Privacidad
  },
  {
    path: '/registro',
    name: 'Registro',
    component: Registro
  },
  {
    path: '/cp/:token',
    name: 'CP',
    component: CP
  },
  {
    path: '/selectaccount',
    name: 'SelectAccount',
    component: SelectAccount
  },
  {
    path: '/createplan',
    name: 'CreatePlan',
    component: CreatePlan
  },
  {
    path: '/dashboard/:id',
    name: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/facturacion/:id',
    name: 'EditarFacturacion',
    component: EditarFacturacion
  },
  {
    path: '/pagos/:id/:plataforma/:valido/:pedido',
    name: 'Pagos',
    component: Pagos
  },
  {
    path: '/userconfig/:id',
    name: 'Configuracion',
    component: Configuracion
  },
  {
    path: '/planconfig/:id',
    name: 'ConfiguracionPlan',
    component: ConfiguracionPlan
  },
  {
    path: '/proyectos/:id',
    name: 'Proyectos',
    component: Proyectos
  },
  {
    path: '/contactos/:id',
    name: 'Contactos',
    component: Contactos
  },
  {
    path: '/test/:id',
    name: 'Test',
    component: Test
  },
  {
    path: '/proyecto/:id_proyecto/configuracion/:id',
    name: 'ProyectoConfiguracion',
    component: ProyectoConfiguracion
  },
  {
    path: '/proyecto/:id_proyecto/estado/:id',
    name: 'ProyectoEstado',
    component: ProyectoEstado
  },
  {
    path: '/proyecto/:id_proyecto/info/:id',
    name: 'ProyectoView',
    component: ProyectoView
  },
  {
    path: '/:catchAll(.*)',
    name: 'Error404',
    component: Error404
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
