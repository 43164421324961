<template>
  <h1 class="error404">Error 404</h1>
</template>

<script>
// @ is an alias to /src
//import LoginComponent from '@/components/LoginComponent.vue'
import {useRoute} from 'vue-router';

export default {
  name: 'Dinamica',
  components: {

  },
  setup(){
    const route = useRoute();
    console.log(route.params);
  }
}
</script>


<style lang="scss" scoped>
.error404{
  color: #ff0000;
}
body{
  height: inherit;
}
</style>
