<template>
  <div class="grupo" v-bind:class="{ grupo_oscuro: datos.modo_oscuro }" v-show="datos.contactos_en_proyecto.length > 0">
    <div class="titulo">Contactos en proyecto</div>
    <div class="row_titulo">
      <div class="item">Contacto</div>
      <div class="item">Email</div>
      <div class="item">Móvil</div>
      <div class="item item_acciones">Acciones</div>
    </div>
    <div v-for="item in datos.contactos_en_proyecto" v-bind:id="item.id"  v-bind:nombre_contacto="item.nombre_contacto" v-bind:mail="item.mail" v-bind:movil="item.movil" class="item_row">
      <div class="item">
        {{item.nombre_contacto}}
      </div>
      <div class="item">
        {{item.mail}}
      </div>
      <div class="item">
        {{item.movil}}
      </div>
      <div class="item item_acciones item_acciones_btn">
        <btn-primario-negativo texto="Quitar del proyecto" @click.native="quitar_contacto(item.id)"></btn-primario-negativo>
      </div>
    </div>
  </div>
  <div class="grupo" v-bind:class="{ grupo_oscuro: datos.modo_oscuro }" v-show="datos.contactos_all.length > 0">
  <div class="titulo">
    Contactos disponibles
  </div>
    <div class="row_titulo">
      <div class="item">Contacto</div>
      <div class="item">Email</div>
      <div class="item">Móvil</div>
      <div class="item item_acciones">Acciones</div>
    </div>
    <div v-for="item in datos.contactos_all" v-bind:id="item.id"  v-bind:nombre_contacto="item.nombre_contacto" v-bind:mail="item.mail" v-bind:movil="item.movil" class="item_row">
      <div class="item">
        {{item.nombre_contacto}}
      </div>
      <div class="item">
        {{item.mail}}
      </div>
      <div class="item">
        {{item.movil}}
      </div>
      <div class="item item_acciones item_acciones_btn">
        <btn-primario texto="Añadir al proyecto" @click.native="anyadir_contacto(item.id)"></btn-primario>
      </div>
    </div>
  </div>
</template>
<script>
import {useStore} from "vuex"
import {onMounted, computed} from "vue"
import { useRoute } from 'vue-router'
import BtnPrimario from '../components/BtnPrimario'
import BtnPrimarioNegativo from '../components/BtnPrimarioNegativo'

export default {
  name: 'ContactosDisponbilesEnProyecto',
  components: {
    BtnPrimario,
    BtnPrimarioNegativo
  },
  setup(){
    const store = useStore();
    const route = useRoute();

    //Para tener variables dinamicas que actualicen la vista
    const variables = computed(()=> store.state.variables); //variable que se usa en la vista
    let datos = {} //Variable que se vincula a la de la vista para actualizar
    datos = variables.value; //Vinculamos la variable
    store.dispatch("setVariables",datos); //Hacemos un comit para empezar

    datos.contactos_all = [];
    datos.contactos_en_proyecto = [];
    //datos.id_proyecto = route.params.id_proyectoe;

    onMounted(function (){
      //console.log('Variable global',datos);
    });
    return {
      variables: variables,
      datos: datos,
    }
  },
  methods:{
    //Funciones
    //para acceder a la variable datos this.datos
    async get_contactos_completo(){
      let contactos_totales = [];

      const requestOptions = {
        method: "GET",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
      };
      let response = await fetch(`${this.datos.url_host}/contactos/all/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      let datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        console.log('Contactos', datos.data);
        //this.datos.contactos_all = datos.data;
        contactos_totales = datos.data;
      }

      response = await fetch(`${this.datos.url_host}/contactos/proyecto/${this.datos.id_proyecto}/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        this.datos.contactos_all = contactos_totales
        this.datos.contactos_en_proyecto = [];
        return false;
      }
      datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
        this.datos.contactos_all = contactos_totales
        this.datos.contactos_en_proyecto = [];
      } else {
        console.log('Contactos', datos.data);
        this.datos.contactos_en_proyecto = datos.data;
        let cantidad = contactos_totales.length;
        this.datos.contactos_all = [];
        for (let a = 0; a < cantidad; a++){
          let item = contactos_totales[a];
          let cantidad_contactos_proyecto = this.datos.contactos_en_proyecto.length;
          let contacto_en_proyecto = false;
          for (let e = 0; e < cantidad_contactos_proyecto; e++){
            if (contacto_en_proyecto) continue;
            let miniitem = this.datos.contactos_en_proyecto[e];
            if (miniitem.id == item.id){
              contacto_en_proyecto = true;
            }
          }
          if (!contacto_en_proyecto){
            this.datos.contactos_all.push(item);
          }
        }

        console.log('Contactos', this.datos.contactos_all.length);
      }
    },
    async quitar_contacto(id_contacto){
      let datos_enviar = {
        id_contacto : id_contacto
      }
      const requestOptions = {
        method: "DELETE",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/contactos/proyecto/${this.datos.id_proyecto}/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        console.log('Contactos', datos.data);
        this.get_contactos_completo();
      }
    },
    async anyadir_contacto(id_contacto){
      let datos_enviar = {
        id_contacto : id_contacto
      }
      const requestOptions = {
        method: "POST",
        headers: {"Content-Type": "application/json", "Authorization": localStorage.token},
        body: JSON.stringify(datos_enviar)
      };
      const response = await fetch(`${this.datos.url_host}/contactos/proyecto/${this.datos.id_proyecto}/${this.datos.id_plan}`, requestOptions);
      if (response.status != 200){
        console.log('ERROR', response.status);
        console.log('data', response);
        return false;
      }
      const datos = await response.json();
      if (datos.code != '00'){
        console.log(response);
        console.log(datos);
      } else {
        console.log('Contactos', datos.data);
        this.get_contactos_completo();
      }
    }
  },
  mounted() {
    //Una vez cargada la vista podemos llamar a la funcion con this.metodoallamar()
    //this.check_token();
    console.log('Datos', this.datos);
    this.get_contactos_completo();
  }
}
</script>

<style scoped lang="scss">
@import '../styles/variables.css';
.item_row{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: space-around;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #f9fafb;
  padding-top: 6px;
  padding-bottom: 6px;
}
.item{
  width: 20%;
}
.item_acciones{
  width: 40%;
}
.item_acciones_btn{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.row_titulo{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: space-around;
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: var(--primary-color-2);
  background-color: var(--primary-color-1);
  color: #fff;
  padding-top: 4px;
  padding-bottom: 4px;

}
.grupo{
  margin: 10px;
  border-color: var(--primary-color-5);
  background-color: var(--fondo-menu-claro-theme);
  border-style: solid;
  border-width: 1px;
}
.grupo .titulo{
  font-size: 22px;
  background-color: var(--primary-color-5);
  color: #FFF;
  padding-top: 4px;
  padding-bottom: 10px;
}

.grupo_oscuro{
  border-color: var(--primary-color-1);
  background-color: var(--fondo-menu-dark-theme)!important;
}
.grupo_oscuro .titulo{
  background-color: var(--primary-color-1);
}
.grupo_oscuro .row_titulo{
  background-color: var(--primary-color-4);
  border-bottom-color: var(--primary-color-5);
}
@media screen and (max-width: 991px){
  .row_titulo{
    display: none!important;
  }
  .item{
    width: 100%;
  }
  .item_row{
    flex-wrap: wrap!important;
  }
}
</style>
